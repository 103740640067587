<template>
  <div class="content">
    <!-- 1 顶部导航 -->
    <div
      class="pcOnly"
      style="width:100%;height:4.375rem;background:black"
    ></div>
    <!-- 2  面包屑 -->
    <div class="bread" style="color:#ff6801">
      <div class="bread_content">
        <el-breadcrumb separator-class="null">
          <el-breadcrumb-item
            ><span @click="goToIndex">首页</span></el-breadcrumb-item
          >
          <div style="margin-right: 0.625rem">
            <i class="el-icon-arrow-right"></i>
          </div>
          <el-breadcrumb-item
            ><span @click="goToAbout">关于亿猫</span></el-breadcrumb-item
          >
          <div style="margin-right: 0.625rem">
            <i class="el-icon-arrow-right"></i>
          </div>
          <el-breadcrumb-item>新闻动态</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <!-- 3  新闻内容 -->
    <div class="box_fix">
      <!-- <div class="box_flex"></div> -->
      <div class="box_content">
        <!-- 标题 -->
        <div class="tip">
          <!-- 标题内容 -->
          <div class="content">
            36氪数智夜话直播 | 亿猫科技CEO：门店数智化机会在哪里？
          </div>
          <!-- 标题时间 -->
          <div class="time">2022-05-19</div>
          <!-- 标题分割线 -->
          <div class="line"></div>
        </div>
        <!-- 内容 -->
        <div class="content">
          <!-- 段落1 -->
          <!-- <div>
                        <img src="../assets/news01img01.png" alt="" style="width:31.875rem;">
                    </div> -->
          <!-- 段落2 -->
          <div class="tip">
            <p>
              当前在中国，线下零售渠道由大约630万家小店构成，其中大卖场有5000家，超市有3万家。
            </p>
          </div>
          <!-- 段落3 -->
          <!-- <div>
                        <img src="../assets/news01img02.png" alt="" style="width:22.875rem;">
                    </div> -->
          <!-- 段落4 -->
          <div class="tip">
            <p>
              目前快消行业的数字化更多集中在前端。实际上，“人货场”这三个零售维度中，“货”和“场”所在的实体门店、销售渠道及产品陈列等并没有被有效数字化，目前主要依靠人工收集销售数据的形式，很难真正掌控具体门店、渠道的表现。例如在门店的数据层面，由于距离消费者通路较长，品牌商在数据获取上处于劣势，信息传递周期长，难以及时获得产品动销和市场反馈信息，无法对市场变化做出快速反应，并且获取的数据经常是错误的。而对于商超来说，受消费电商冲击，在高额的租赁、人力及其他高成本下，亟需通过数字化转型，搭建起线上线下双运营模式，提高自身的区域竞争力。面对复杂且分散的线下渠道，品牌商、商超企业对于门店数字化的痛点问题迫切关注。
            </p>
          </div>
          <!-- 段落5 -->
          <!-- <div>
                        <img src="../assets/news01img03.png" alt="" style="width:25.625rem;">
                    </div> -->
          <div class="tip">
            <p>
              <span style="font-weight:800">2022年05月19日周四 20:00 36氪</span>
              举办「数智夜话」线上直播交流，并邀请到了社区冷卤品牌「麻爪爪」创始人兼CEO于学航、「亿猫科技」创始人兼CEO徐步兵和融资顾问机构「冲盈资本」合伙人杜思颖来到「数智夜话」未来消费系列第二期直播活动，分享他们关于线下零售数字化的观察与思考。
            </p>
          </div>
          <!-- 段落6 -->
          <div class="tip">
            <p style="font-weight:800">徐步兵 亿猫科技创始人&CEO：</p>
          </div>
          <!-- 段落7 -->
          <!-- <div>
                        <img src="../assets/news01img04.png" alt="" style="width:29.25rem;">
                    </div> -->
          <!-- 段落8 -->
          <div class="tip">
            <p>
              首先“人管店”我们把它理解为用以往的经验来进行店铺管理，但要过度到“数据管店”，首先得有“数据管店”的能力，这个能力我理解为有如下几个层次：第一，感知和采集数据的能力，门店里面需部署多种的物联网设备，多种的传感器，多种的比如说货架，以及商品的在线能力、数据化，能做到感知，这是一个基础，这是底层的，第二，还得具备理解和利用数据的能力。第三，业务流和数据流的在线化能力等等。
            </p>
          </div>
          <!-- 段落9 -->
          <!-- <div>
                        <img src="../assets/news01img05.png" alt="" style="width:33.625rem;">
                    </div> -->
          <div class="tip">
            <p>
              超市门店以及线下零售的门店，在2015、2016年甚至2017年的时候，基础设施的云化尚没有完成，传统门店的信息化设施和建设，都是围绕它自营ERP系统，不是为线上交易、数据化及互联网化来设计建设的。但经过这些年的发展，大型连锁超市、商场的这一块逐渐起来了，具备了“数据管店”的基础设施的能力。触点的数字化，以往用手机App、小程序来进行，实际上没办法系统化的管理，随着各种物联网设备应用多起来后，可以把采集到的数据融入到系统数据云，再跟业务深度融合，才可以实现数据管店的较理想状态。现在大部分还处在数据管店的早期阶段，超市没有智能购物车的时候，店内的消费者、品牌商、和超市无法直接交互，都是割裂状态。有了这样一套工具之后，可以把里边场内的人实时的数字化、在线化，但这需要一个过程，我的理解是这样。
            </p>
          </div>
          <div class="tip">
            <p>
              我们现在做的超市智能购物车系统，希望在超市购物的场景下，能成为消费者的一部智能手机+四个轮子，有了这个工具以后，可以实时全程与消费者互动。我们再回头看，零售两个核心能力，很多人说超市不行了，或者说超市流量的下降，我认为超市依然会存在，只不过是传统超市的商品力和服务力在下降。跟电商相比，线下门店采集数据、分析洞察消费者的能力跟不上了，我们希望通过这样的一些工具，实时把线下门店会员数字化，帮助超市建立感知用户的能力，超市就可以优化自身的供应链建设，做商品的差异化。同时我们也希望通过这一套系统帮助品牌商，在线下可以做到“货找人”。有了这样的能力以后，线下超市就可以个性化精准的服务消费者。
            </p>
          </div>
          <div class="tip">
            <p>
              便利店和卖场的数字化有一定的共通性，希望用户都能成为数字化会员，可以直接触达，但同时也有很大的区别，超市跟消费者互相交流的方式，每一次都是拉新，但又不可能将消费者的信息登记下来，所以没有长久互动的渠道。然而大卖场的数字化牵扯到物联网的设备，四千平米或一万五千平米以上的大卖场，需要颗粒度非常细地部署传感器，建立起数据采集及信息的感知力，才能提升整个大卖场的数字化能力。
            </p>
          </div>
          <div class="tip">
            <p>
              我觉得，目前线下的门店，无论是大中小，数字化是都是走向未来最关键的一个步骤。我们以前讲线下线下，基本上都把两者对立起来，但实际上不是这样，应该深度融合，线上也有线下，线下也有线上，今后应该是一种融合状态，目前线下最大的机会点也是数字化，数字化是他们未来必须要掌握的基本生存能力，所以说我们要把消费者数字化、在线化，把我们的业务流程在线化，可以满足消费者各种各样的需要，通过数字化的手段来提升服务力。
            </p>
          </div>
          <div class="tip">
            <p>
              数字化目前给我们线下的门店，也带来非常大的帮助，线下门店在经营过程中，有了数字化能力，就可以有会员数据的沉淀基础，这是其一。第二，门店的数字化还需要经历五个步骤，是需要在战略高度上规划和设计，目前基础设施云化、触点数字化等方面，尤其是大型商超做得非常不错，但是后续的业务在线化及数据资产、数据决策上，还需要搭建一个具备产品力、数字化能力的队伍，这是一个长远的规划。数字化任重道远，不是一朝一夕能够完成的。线下的物联网设备，除了帮助我们提升用户感知能力外，还要和体验、个性化结合起来，有了这些以后，线下门店会多一个强力武器，加之线上电商、线下反馈，我觉得对于门店数字化是非常大的进步，但同时也是非常大的挑战。
            </p>
          </div>
        </div>
      </div>
      <!-- <div class="box_flex" style="background:#000"></div>
            <div class="box_flex"></div>
            <div class="box_flex" style="background:#000"></div> -->
    </div>
    <!--  4   脚步 -->
    <footers></footers>
  </div>
</template>
<script>
import footers from "../views/footer";
export default {
  components: {
    footers
  },
  data() {
    return {};
  },
  methods: {
    goToAbout() {
      console.log("22");
      this.$router.push("/about");
    },
    goToIndex() {
      console.log("332");
      this.$router.push("/indextab");
    }
  }
};
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  background: #fff;
  color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  margin: 0;
}
// 新闻导航栏导航栏样式
.black {
  position: fixed;
  background: #000;
  width: 100%;
  height: 4.375rem;
  z-index: 999;
  left: 0;
  right: 0;
  top: 0px;

  .el-menu-demo {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 1.125rem;
    width: 100%;
  }
  ::v-deep.el-icon-arrow-down:before {
    content: "";
  }
  .el-menu-item {
    font-size: 1.125rem;
    font-family: Source Han Sans CN;
    font-weight: 500;
    height: 4.375rem !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ::v-deep.el-menu--horizontal > .el-submenu .el-submenu__title:hover {
    background-color: rgba(255, 255, 255, 0) !important;
    // color: #ff6801 !important;
    border-bottom-color: #ff6801 !important;
    // border-bottom-color: #fff !important;
  }
  ::v-deep.el-menu--horizontal
    > .el-submenu
    .el-submenu__title
    > .el-icon-arrow-down:before {
    // content: "";
  }
  ::v-deep.el-menu--horizontal
    > .el-submenu.el-submenu.is-active
    > .el-submenu__title {
    border-bottom-color: #ff6801 !important;
    background-color: rgba(255, 255, 255, 0) !important;
  }
  ::v-deep.el-menu--horizontal > .el-submenu.el-submenu.is-active.is-opened {
    // border-bottom-color: #ff6801 !important;
    background-color: rgba(255, 255, 255, 0) !important;
  }
  ::v-deep.el-menu--horizontal
    > .el-submenu.el-submenu.is-opened
    .el-submenu__title {
    background-color: rgba(255, 255, 255, 0) !important;
    // border-bottom-color: #ff6801 !important;
  }
  // ::v-deep.el-menu--horizontal>.el-submenu.el-submenu.is-active{
  //     background-color: rgba(255,255,255,0) !important;
  // }
  ::v-deep.el-menu--horizontal > .el-submenu .el-submenu__title {
    margin-left: 0.875rem !important;
    height: 4.375rem !important;
    display: flex;
    justify-content: center;
    align-items: center;
    // margin-right: -10px;
    padding-right: 0px !important;
    // border-bottom-color: rgba(255,255,255,0.3) !important;
  }
  .el-menu--horizontal > .el-menu-item {
    // width:6.25rem !important;
    margin-left: 0.75rem !important;
    border-bottom-color: rgba(255, 255, 255, 0) !important;
  }
  ::v-deep.el-menu--horizontal > .el-submenu .el-submenu__title {
    font-size: 1.125rem;
    background-color: rgba(255, 255, 255, 0) !important;
    font-family: Source Han Sans CN;
    font-weight: 500;
  }
  // ::v-deep.el-menu--horizontal>.el-submenu .el-submenu__title.is-active{
  //   background: #ff6801;
  // }
  // ::v-deep.el-menu--horizontal>.el-submenu.is-active.is-opened>.el-submenu__title>.el-menu--horizontal{
  //     top: 60px !important;

  // }
  .el-menu {
    background-color: rgba(255, 255, 255, 0) !important;
    border-color: rgba(255, 255, 255, 0) !important;
    border-bottom-color: rgba(255, 255, 255, 0) !important;
  }
  .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
  .el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
  .el-menu--horizontal > .el-submenu .el-submenu__title:hover {
    background-color: rgba(255, 255, 255, 0) !important;
    border-color: rgba(255, 255, 255, 0) !important;
    border-bottom-color: #ff6801 !important;
  }
  .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus {
    background-color: rgba(255, 255, 255, 0) !important;
    border-color: rgba(255, 255, 255, 0) !important;
    border-bottom-color: #ff6801 !important;
  }
  .el-menu-item.is-active {
    border-color: #ff6801 !important;
    border-bottom-color: #ff6801 !important;
  }
  .el-submenu.is-active .el-submenu__title {
    border-bottom-color: #ff6801 !important;
    background-color: rgba(255, 255, 255, 0) !important;
  }
  .el-menu.el-menu--horizontal {
    border-bottom: 0.125rem !important;
    // background-color: rgba(255,255,255,0) !important;
  }
}
.black .box {
  width: 100%;
  height: 4.375rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;
}
.black .box .logo {
  width: 23%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  //  background: chocolate;
}
.black .box .logo :first-child {
  width: 7.625rem;
  height: 2.5625rem;
}
.black .box .logo img {
  width: 100%;
  //  height: 100%;
}
.navigationBar_box {
  width: 57%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  overflow: hidden;
}
.black .box .phone {
  display: flex;
  justify-content: flex-start;
  height: 100%;
  width: 20%;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  padding-left: 2.1875rem;
}
.phone_box {
  width: 100%;
  height: 4.375rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.phone_boxmini001 {
  // position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.1875rem;
  height: 1.5rem;
  margin: 0 0 0 0.9375rem;

  img {
    width: 100%;
    //  height: 100%;
  }
}
.phone_boxmini002 {
  width: 80%;
  height: 100%;
  font-size: 1.125rem;
  font-family: Source Han Sans CN;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-left: 0.3125rem;
  font-weight: 500;
  line-height: 1.625rem;
  overflow: hidden;
}
// 面包屑
.bread {
  background: #f7f7f7;
  // position: fixed;
  color: #000;
  width: 100%;
  height: 3.125rem;
  //  z-index: 999;
  left: 0;
  right: 0;
  top: 4.375rem;
  display: flex;
  align-items: center;
  justify-content: center;

  .bread_content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 100%;
    width: 100%;
    // background: chartreuse;

    .el-breadcrumb {
      font-size: 1.125rem;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #2b2b2b;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-left: 4.375rem;
      width: 40%;
    }
    // .el-breadcrumb__item{
    //     display: flex;
    //     flex-direction: row;
    //     flex-wrap: nowrap;
    //     // width: 100px;
    // }
    .el-breadcrumb :first-child :hover {
      color: #ff6700 !important;
      opacity: 0.8;
    }
    .el-breadcrumb :nth-child(3) :hover {
      // color: #FF6700 !important;
      cursor: pointer;
      color: #ff6700 !important;
      opacity: 0.8;
    }
    ::v-deep.el-breadcrumb__inner.is-link {
      color: #ff6801;
      font-weight: 400;
    }
  }
}
// 新闻主体区域
.box_fix {
  //  height: 1500px;
  width: 100%;
  margin-bottom: 6.25rem;
  //  background: forestgreen;

  .box_flex {
    height: 7.5rem;
    width: 100%;
    //  background: gold;
  }
  .box_content {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    //  height: 300px;

    .tip {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: center;
      width: 50%;
      margin-top: 3.125rem;
      //  background: chartreuse;

      .content {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 100%;
        font-size: 1.5rem;
        font-family: Alibaba PuHuiTi;
        font-weight: 500;
        color: #343434;
        line-height: 3.625rem;
      }
      .time {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 100%;
        font-size: 0.9375rem;
        font-family: Alibaba PuHuiTi;
        font-weight: 500;
        color: #aeadad;
        line-height: 1.875rem;
      }
      .line {
        width: 100%;
        height: 0.125rem;
        background: #bfbfbf;
        margin-top: 1.25rem;
      }
    }
    .content {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: center;
      width: 50%;
      font-size: 1rem;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #1a1a1a;
      line-height: 2.1875rem;
      text-align: left;
      margin-top: 3.125rem;

      .tip {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        margin: 0px;
      }
      .tip p {
        text-indent: 2em;
      }
    }
  }
}
</style>
