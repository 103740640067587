<template>
  <div class="content">
    <!-- 1 顶部导航 -->
    <div
      class="pcOnly"
      style="width:100%;height:4.375rem;background:black"
    ></div>
    <!-- 2  面包屑 -->
    <div class="bread" style="color:#ff6801">
      <div class="bread_content">
        <el-breadcrumb separator-class="null">
          <el-breadcrumb-item
            ><span @click="goToIndex">首页</span></el-breadcrumb-item
          >
          <div style="margin-right: 0.625rem">
            <i class="el-icon-arrow-right"></i>
          </div>
          <el-breadcrumb-item
            ><span @click="goToAbout">关于亿猫</span></el-breadcrumb-item
          >
          <div style="margin-right: 0.625rem">
            <i class="el-icon-arrow-right"></i>
          </div>
          <el-breadcrumb-item>新闻动态</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <!-- 3  新闻内容 -->
    <div class="box_fix">
      <!-- <div class="box_flex"></div> -->
      <div class="box_content">
        <!-- 标题 -->
        <div class="tip">
          <!-- 标题内容 -->
          <div class="content">世纪联华杭州江晖店迎来智能购物车时代</div>
          <!-- 标题时间 -->
          <div class="time">2022-05-27</div>
          <!-- 标题分割线 -->
          <div class="line"></div>
        </div>
        <!-- 内容 -->
        <div class="content">
          <div>
            <img class="imgStyle" src="../assets/jh/jh-00.png" alt="" />
          </div>
          <div class="tip">
            <p>
              后疫情时代，AI等新技术与零售行业的融合进入高速增长的阶段，以人工智能、大数据为核心的技术创新，强势驱动传统零售行业产生变革与颠覆。在零售领域，与线上流量成本飙升、电商运营困局凸显对比，传递消费者购买体验的超市门店越来越重视数字化技术，提高数字化基础设施水平，优化门店运营效率，正在逐渐走出困境。其中，智能购物车作为标志性的超市门店数字化工具出现，为超市门店带来卓越改变，为消费品品牌带来了巨大想象空间，助推零售行业整体价值发展。
            </p>
          </div>
          <div>
            <img class="imgStyle" src="../assets/jh/jh-01.jpg" alt="" />
          </div>
          <!-- 段落3 -->
          <div class="tip">
            <p>
              5月23日，杭州市滨江区占地18000平方米的浙江省连锁业星级门店——“世纪联华杭州滨江江晖路店”经过改造升级，成为了国内第一家全店使用智能购物车的大型商超。
            </p>
          </div>
          <div>
            <img class="imgStyle" src="../assets/jh/jh-02.jpg" alt="" />
          </div>
          <div class="tip">
            <p>
              江晖路店使用的亿猫iCart4.0智能购物车是国内一款知名的超市运营+营销数字化工具，它采用软硬件一体化设计，集装载、营销、结算、数据分析能力为一身，将AI专项技术与智能传感算法技术及摄像头系统、大数据平台无缝连接，利用先进的室内定位技术对传统卖场进行数字化改造升级。顾客可通过刷脸/扫码/手输等多种方式推车入店，领取卖场/品牌商优惠券，实时同步会员信息，通过边选边扫条形码的自助方式购物，智能防损，自助在购物车结算，通过智能闸机离场，无需人工核验。
            </p>
            <h2 style="text-align: center; width: 100%;">一台 “新工具”</h2>
            <p>
              亿猫iCart4.0智能购物车拥有90升容量的超大车身，代表国内最新的智能购物车科研水平，配备多种国内高精尖硬件设备、强力“鹰隼AI反欺诈引擎”、无感刷脸支付，完美替代了收银员的“监督”、“收银”两大职能；拥有顾客免密登录、快速领券、自助购物、刷脸支付等多项黑科技功能。
            </p>
            <p>
              为适应疫情的背景，江晖路店使用的亿猫iCart4.0购物车更具防控功效。亿猫iCart4.0购物车的抗菌涂层不仅安全卫生，而且它通过自身的智能防损、自助结算等功能，保证顾客购物全流程自助无人干扰、全场移动支付、全场景零人工接触，增强商超卖场防控的科学性和有效性。
            </p>
          </div>
          <div>
            <img class="imgStyle" src="../assets/jh/jh-03.jpg" alt="" />
          </div>
          <div class="tip">
            <h2 style="text-align: center; width: 100%;">一种 “新生活”</h2>
            <p>
              亿猫iCart4.0智能购物车不仅满足卖场需求，也更懂顾客的需求，其代表着新数字化零售，让卖场购物生活焕然一新。亿猫iCart4.0智能购物车如同“私人管家”般更懂顾客的需求，以数据为驱动，与顾客的实时高效交互，丰富顾客感知，让顾客沉浸式购物，创意科技赋能零售环境，创造顾客的快乐新体验。
            </p>
          </div>
          <div>
            <img class="imgStyle" src="../assets/jh/jh-04.jpg" alt="" />
          </div>
          <div class="tip">
            <p>
              亿猫iCart4.0智能购物车上线还自带“粮草”
              ！新鲜奉上消费品优惠券，不仅有强生、阿华田等知名大品牌，也紧跟潮流，同步执行品牌活动和商超卖场活动，保证优惠好用的服务宗旨。相比传统的纸质促销与人工促销，因时间地点等信息差，顾客容易错过促销信息，浪费成本与商机；又因繁琐的优惠步骤，令顾客感觉像是在做“数学题”，大大降低购物体验。而如今的亿猫iCart4.0智能购物车会在顾客登录、扫码购物时贴心推荐相关优惠券，还有精确的蓝牙定位功能，会跟随顾客当前所处区域，智能推送相应的专区优惠券；并在结算时提供最优方案，自动扣除最大优惠券，显示优惠价格，让顾客的消费明明白白、清清楚楚。
            </p>
          </div>
          <div>
            <img class="imgStyle" src="../assets/jh/jh-05.jpg" alt="" />
          </div>
          <div class="tip">
            <h2 style="text-align: center; width: 100%;">一项 “新纪录”</h2>
            <p>
              刚刚过完520情人节，这家曾经服务并保障过杭州G20峰会的先进集体、浙江省连锁业星级门店的知名大店载誉前行，再创殊荣，成为杭州城乃至全国第一个全店使用智能购物车的大型商超，一项新的国内纪录诞生！
            </p>
            <p>
              此项智能购物车全覆盖营运的创举也标志着杭州世纪联华的线下门店数字化转型改造成功，中国实体零售迎来真正的数字化时代！
            </p>
          </div>
          <div>
            <img class="imgStyle" src="../assets/jh/jh-06.jpg" alt="" />
          </div>
          <div class="tip">
            <p>
              亿猫科技已与多家大型连锁超市企业开展合作，加速线下门店部署的同时，也在深度融合与更多消费品牌的营销合作，帮助消费品牌全面数字化营销升级。
            </p>
            <p
              style="color: #c12b2b; text-align: center; font-weight: bold; font-size: 16px; line-height: 2; margin: 1em 0; width: 100%; text-indent: 0;"
            >
              亿猫科技<br />坚持用品质引领行业 <br />坚持技术创新理念
              <br />打造卖场购物新体验<br />
              构建卖场营销新模式 <br />为实体零售行业带来新的生机<br />
              成为行业最有竞争力的民族品牌 <br />创造行业神话
            </p>
          </div>
          <div>
            <img class="imgStyle" src="../assets/jh/jh-07.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
    <footers></footers>
  </div>
</template>
<script>
import footers from "../views/footer";
export default {
  components: {
    footers
  },
  data() {
    return {};
  },
  methods: {
    goToAbout() {
      console.log("22");
      this.$router.push("/about");
    },
    goToIndex() {
      console.log("332");
      this.$router.push("/indextab");
    }
  }
};
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  background: #fff;
  color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  margin: 0;
}
// 新闻导航栏导航栏样式
.black {
  position: fixed;
  background: #000;
  width: 100%;
  height: 4.375rem;
  z-index: 999;
  left: 0;
  right: 0;
  top: 0px;

  .el-menu-demo {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 1.125rem;
    width: 100%;
  }
  ::v-deep.el-icon-arrow-down:before {
    content: "";
  }
  ::v-deep.el-menu-item {
    font-size: 1.125rem;
    font-family: Source Han Sans CN;
    font-weight: 500;
    height: 4.375rem !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ::v-deep.el-menu--horizontal > .el-submenu .el-submenu__title:hover {
    background-color: rgba(255, 255, 255, 0) !important;
    // color: #ff6801 !important;
    border-bottom-color: #ff6801 !important;
    // border-bottom-color: #fff !important;
  }
  ::v-deep.el-menu--horizontal
    > .el-submenu
    .el-submenu__title
    > .el-icon-arrow-down:before {
    // content: "";
  }
  ::v-deep.el-menu--horizontal
    > .el-submenu.el-submenu.is-active
    > .el-submenu__title {
    border-bottom-color: #ff6801 !important;
    background-color: rgba(255, 255, 255, 0) !important;
  }
  ::v-deep.el-menu--horizontal > .el-submenu.el-submenu.is-active.is-opened {
    // border-bottom-color: #ff6801 !important;
    background-color: rgba(255, 255, 255, 0) !important;
  }
  ::v-deep.el-menu--horizontal
    > .el-submenu.el-submenu.is-opened
    .el-submenu__title {
    background-color: rgba(255, 255, 255, 0) !important;
    // border-bottom-color: #ff6801 !important;
  }
  // ::v-deep.el-menu--horizontal>.el-submenu.el-submenu.is-active{
  //     background-color: rgba(255,255,255,0) !important;
  // }
  ::v-deep.el-menu--horizontal > .el-submenu .el-submenu__title {
    margin-left: 0.875rem !important;
    height: 4.375rem !important;
    display: flex;
    justify-content: center;
    align-items: center;
    // margin-right: -10px;
    padding-right: 0px !important;
    // border-bottom-color: rgba(255,255,255,0.3) !important;
  }
  .el-menu--horizontal > .el-menu-item {
    // width:6.25rem !important;
    margin-left: 0.75rem !important;
    border-bottom-color: rgba(255, 255, 255, 0) !important;
  }
  ::v-deep.el-menu--horizontal > .el-submenu .el-submenu__title {
    font-size: 1.125rem;
    background-color: rgba(255, 255, 255, 0) !important;
    font-family: Source Han Sans CN;
    font-weight: 500;
  }
  // ::v-deep.el-menu--horizontal>.el-submenu .el-submenu__title.is-active{
  //   background: #ff6801;
  // }
  // ::v-deep.el-menu--horizontal>.el-submenu.is-active.is-opened>.el-submenu__title>.el-menu--horizontal{
  //     top: 60px !important;

  // }
  .el-menu {
    background-color: rgba(255, 255, 255, 0) !important;
    border-color: rgba(255, 255, 255, 0) !important;
    border-bottom-color: rgba(255, 255, 255, 0) !important;
  }
  .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
  .el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
  .el-menu--horizontal > .el-submenu .el-submenu__title:hover {
    background-color: rgba(255, 255, 255, 0) !important;
    border-color: rgba(255, 255, 255, 0) !important;
    border-bottom-color: #ff6801 !important;
  }
  .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus {
    background-color: rgba(255, 255, 255, 0) !important;
    border-color: rgba(255, 255, 255, 0) !important;
    border-bottom-color: #ff6801 !important;
  }
  .el-menu-item.is-active {
    border-color: #ff6801 !important;
    border-bottom-color: #ff6801 !important;
  }
  .el-submenu.is-active .el-submenu__title {
    border-bottom-color: #ff6801 !important;
    background-color: rgba(255, 255, 255, 0) !important;
  }
  .el-menu.el-menu--horizontal {
    border-bottom: 0.125rem !important;
    // background-color: rgba(255,255,255,0) !important;
  }
}
.black .box {
  width: 100%;
  height: 4.375rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;
}
.black .box .logo {
  width: 23%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  //  background: chocolate;
}
.black .box .logo :first-child {
  width: 7.625rem;
  height: 2.5625rem;
}
.black .box .logo img {
  width: 100%;
  //  height: 100%;
}
.navigationBar_box {
  width: 57%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  overflow: hidden;
}
.black .box .phone {
  display: flex;
  justify-content: flex-start;
  height: 100%;
  width: 20%;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  padding-left: 2.1875rem;
}
.phone_box {
  width: 100%;
  height: 4.375rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.phone_boxmini001 {
  // position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.1875rem;
  height: 1.5rem;
  margin: 0 0 0 0.9375rem;

  img {
    width: 100%;
    //  height: 100%;
  }
}
.phone_boxmini002 {
  width: 80%;
  height: 100%;
  font-size: 1.125rem;
  font-family: Source Han Sans CN;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-left: 0.3125rem;
  font-weight: 500;
  line-height: 1.625rem;
  overflow: hidden;
}
// 面包屑
.bread {
  background: #f7f7f7;
  // position: fixed;
  color: #000;
  width: 100%;
  height: 3.125rem;
  //  z-index: 999;
  left: 0;
  right: 0;
  top: 4.375rem;
  display: flex;
  align-items: center;
  justify-content: center;

  .bread_content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 100%;
    width: 100%;
    // background: chartreuse;

    .el-breadcrumb {
      font-size: 1.125rem;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #2b2b2b;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-left: 4.375rem;
      width: 40%;
    }
    // .el-breadcrumb__item{
    //     display: flex;
    //     flex-direction: row;
    //     flex-wrap: nowrap;
    //     // width: 100px;
    // }
    .el-breadcrumb :first-child :hover {
      color: #ff6700 !important;
      opacity: 0.8;
    }
    .el-breadcrumb :nth-child(3) :hover {
      // color: #FF6700 !important;
      cursor: pointer;
      color: #ff6700 !important;
      opacity: 0.8;
    }
    ::v-deep.el-breadcrumb__inner.is-link {
      color: #ff6801;
      font-weight: 400;
    }
  }
}
// 新闻主体区域
.box_fix {
  //  height: 1500px;
  width: 100%;
  margin-bottom: 6.25rem;
  //  background: forestgreen;

  .box_flex {
    height: 7.5rem;
    width: 100%;
    //  background: gold;
  }
  .box_content {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    //  height: 300px;

    .tip {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: center;
      width: 50%;
      margin-top: 3.125rem;
      //  background: chartreuse;

      .content {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 100%;
        font-size: 1.5rem;
        font-family: Alibaba PuHuiTi;
        font-weight: 500;
        color: #343434;
        line-height: 3.625rem;
      }
      .time {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 100%;
        font-size: 0.9375rem;
        font-family: Alibaba PuHuiTi;
        font-weight: 500;
        color: #aeadad;
        line-height: 1.875rem;
      }
      .line {
        width: 100%;
        height: 0.125rem;
        background: #bfbfbf;
        margin-top: 1.25rem;
      }
    }
    .content {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: center;
      width: 50%;
      font-size: 1rem;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #1a1a1a;
      line-height: 2.1875rem;
      text-align: left;
      margin-top: 3.125rem;

      .tip {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        margin: 0px;
      }
      .tip p {
        text-indent: 2em;
      }
    }
  }

  .imgStyle {
    max-width: 100%;
    height: auto;
  }
}
</style>
