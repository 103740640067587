import { render, staticRenderFns } from "./NewsPage2.vue?vue&type=template&id=7ad3526b&scoped=true&"
import script from "./NewsPage2.vue?vue&type=script&lang=js&"
export * from "./NewsPage2.vue?vue&type=script&lang=js&"
import style0 from "./NewsPage2.vue?vue&type=style&index=0&id=7ad3526b&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ad3526b",
  null
  
)

export default component.exports