<template>
  <div class="content">
    <!-- 1 顶部导航 -->
    <div
      class="pcOnly"
      style="width:100%;height:4.375rem;background:black"
    ></div>
    <!-- 2  面包屑 -->
    <div class="bread" style="color:#ff6801">
      <div class="bread_content">
        <el-breadcrumb separator-class="null">
          <el-breadcrumb-item
            ><span @click="goToIndex">首页</span></el-breadcrumb-item
          >
          <div style="margin-right: 0.625rem">
            <i class="el-icon-arrow-right"></i>
          </div>
          <el-breadcrumb-item
            ><span @click="goToAbout">关于亿猫</span></el-breadcrumb-item
          >
          <div style="margin-right: 0.625rem">
            <i class="el-icon-arrow-right"></i>
          </div>
          <el-breadcrumb-item>新闻动态</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <!-- 3  新闻内容 -->
    <div class="box_fix">
      <!-- <div class="box_flex"></div> -->
      <div class="box_content">
        <!-- 标题 -->
        <div class="tip">
          <!-- 标题内容 -->
          <div class="content">
            亿猫科技荣获上海国资国企数字化转型创新大赛「数字创新优胜奖」
          </div>
          <!-- 标题时间 -->
          <div class="time">2021-07-22</div>
          <!-- 标题分割线 -->
          <div class="line"></div>
        </div>
        <!-- 内容 -->
        <div class="content">
          <!-- 段落1 -->
          <div>
            <img
              src="../assets/news01img01.png"
              alt=""
              style="width:31.875rem;"
            />
          </div>
          <!-- 段落2 -->
          <div class="tip">
            <p>
              “2021上海国资国企数字化转型创新大赛”由上海市国资委联合上海市经信委、上海市科委共同主办，旨在以数字化转型作为推动国资国企高质量发展的新动能。大赛共设置数字底座、数字金融、数字智造、数字城市和数字商贸5个分赛道，由临港集团联合上咨集团、上海信投、上海银行、上海电气、上海仪电和百联集团等7家企业集团联合承办。
            </p>
            <p>
              自5月20日网络申报启动以来，数字商贸赛道吸引了业界的广泛关注，共收到500多家创客团队和科技企业咨询，342家创新方案报名参赛，大批优质项目不断涌现。参赛企业覆盖大型央企、“BAT”、行业“独角兽”、赛道领军科技企业等。
            </p>
            <p></p>
            <p>
              7月20日至7月21日，共创数字未来—2021上海国资国企数字化转型创新大赛（数字商贸赛道）决赛暨零售创新论坛在上海市光复路1号四行仓库（百联科创中心）拉开帷幕。大赛吸引了近两百名观众到场观赛，共有30家项目杀入决赛，进行路演。
            </p>
            <p></p>
            <p>
              经过两日决赛的激烈角逐，亿猫科技凭借超市智能购物车在商超数字化转型领域的创新应用，荣获
              2021上海国资国企数字化转型创新大赛“数字商贸”赛道「数字创新优胜奖」
            </p>
          </div>
          <!-- 段落3 -->
          <div>
            <img
              src="../assets/news01img02.png"
              alt=""
              style="width:22.875rem;"
            />
          </div>
          <!-- 段落4 -->
          <div class="tip">
            <p>
              近年来，传统商超受到互联网经济的冲击，经营困难、业绩下滑严重，以家乐福、麦德龙为代表的众多外资商超巨头败走中国，而本土零售业巨头也已大多拥抱互联网，走向线上线下融合的新零售业态。快速向数字化、新零售转型看齐已经成为众多线下商超继续发展的必经之路。
            </p>
          </div>
          <!-- 段落5 -->
          <div>
            <img
              src="../assets/news01img03.png"
              alt=""
              style="width:25.625rem;"
            />
          </div>
          <!-- 段落6 -->
          <div class="tip">
            <p>
              智能购物车集合智慧屏、重量感知、图像识别等多种传感器于一体，可以依靠蓝牙实现网络互联，还能实现智能导航、智能导购、自助结算、人脸识别、精准推送优惠券等多项数字化购物体验。
            </p>
          </div>
          <!-- 段落7 -->
          <div>
            <img
              src="../assets/news01img04.png"
              alt=""
              style="width:29.25rem;"
            />
          </div>
          <!-- 段落8 -->
          <div class="tip">
            <p>
              智能购物车集合智慧屏、重量感知、图像识别等多种传感器于一体，可以依靠蓝牙实现网络互联，还能实现智能导航、智能导购、自助结算、人脸识别、精准推送优惠券等多项数字化购物体验。
            </p>
          </div>
          <!-- 段落9 -->
          <div>
            <img
              src="../assets/news01img05.png"
              alt=""
              style="width:33.625rem;"
            />
          </div>
        </div>
      </div>
      <!-- <div class="box_flex" style="background:#000"></div>
            <div class="box_flex"></div>
            <div class="box_flex" style="background:#000"></div> -->
    </div>
    <!--  4   脚步 -->
    <footers></footers>
  </div>
</template>
<script>
import footers from "../views/footer";
export default {
  components: {
    footers
  },
  data() {
    return {};
  },
  methods: {
    goToAbout() {
      console.log("22");
      this.$router.push("/about");
    },
    goToIndex() {
      console.log("332");
      this.$router.push("/indextab");
    }
  }
};
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  background: #fff;
  color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  margin: 0;
}
// 新闻导航栏导航栏样式
.black {
  position: fixed;
  background: #000;
  width: 100%;
  height: 4.375rem;
  z-index: 999;
  left: 0;
  right: 0;
  top: 0px;

  .el-menu-demo {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 1.125rem;
    width: 100%;
  }
  ::v-deep.el-icon-arrow-down:before {
    content: "";
  }
  .el-menu-item {
    font-size: 1.125rem;
    font-family: Source Han Sans CN;
    font-weight: 500;
    height: 4.375rem !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ::v-deep.el-menu--horizontal > .el-submenu .el-submenu__title:hover {
    background-color: rgba(255, 255, 255, 0) !important;
    // color: #ff6801 !important;
    border-bottom-color: #ff6801 !important;
    // border-bottom-color: #fff !important;
  }
  ::v-deep.el-menu--horizontal
    > .el-submenu
    .el-submenu__title
    > .el-icon-arrow-down:before {
    // content: "";
  }
  ::v-deep.el-menu--horizontal
    > .el-submenu.el-submenu.is-active
    > .el-submenu__title {
    border-bottom-color: #ff6801 !important;
    background-color: rgba(255, 255, 255, 0) !important;
  }
  ::v-deep.el-menu--horizontal > .el-submenu.el-submenu.is-active.is-opened {
    // border-bottom-color: #ff6801 !important;
    background-color: rgba(255, 255, 255, 0) !important;
  }
  ::v-deep.el-menu--horizontal
    > .el-submenu.el-submenu.is-opened
    .el-submenu__title {
    background-color: rgba(255, 255, 255, 0) !important;
    // border-bottom-color: #ff6801 !important;
  }
  // ::v-deep.el-menu--horizontal>.el-submenu.el-submenu.is-active{
  //     background-color: rgba(255,255,255,0) !important;
  // }
  ::v-deep.el-menu--horizontal > .el-submenu .el-submenu__title {
    margin-left: 0.875rem !important;
    height: 4.375rem !important;
    display: flex;
    justify-content: center;
    align-items: center;
    // margin-right: -10px;
    padding-right: 0px !important;
    // border-bottom-color: rgba(255,255,255,0.3) !important;
  }
  .el-menu--horizontal > .el-menu-item {
    // width:6.25rem !important;
    margin-left: 0.75rem !important;
    border-bottom-color: rgba(255, 255, 255, 0) !important;
  }
  ::v-deep.el-menu--horizontal > .el-submenu .el-submenu__title {
    font-size: 1.125rem;
    background-color: rgba(255, 255, 255, 0) !important;
    font-family: Source Han Sans CN;
    font-weight: 500;
  }
  // ::v-deep.el-menu--horizontal>.el-submenu .el-submenu__title.is-active{
  //   background: #ff6801;
  // }
  // ::v-deep.el-menu--horizontal>.el-submenu.is-active.is-opened>.el-submenu__title>.el-menu--horizontal{
  //     top: 60px !important;

  // }
  .el-menu {
    background-color: rgba(255, 255, 255, 0) !important;
    border-color: rgba(255, 255, 255, 0) !important;
    border-bottom-color: rgba(255, 255, 255, 0) !important;
  }
  .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
  .el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
  .el-menu--horizontal > .el-submenu .el-submenu__title:hover {
    background-color: rgba(255, 255, 255, 0) !important;
    border-color: rgba(255, 255, 255, 0) !important;
    border-bottom-color: #ff6801 !important;
  }
  .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus {
    background-color: rgba(255, 255, 255, 0) !important;
    border-color: rgba(255, 255, 255, 0) !important;
    border-bottom-color: #ff6801 !important;
  }
  .el-menu-item.is-active {
    border-color: #ff6801 !important;
    border-bottom-color: #ff6801 !important;
  }
  .el-submenu.is-active .el-submenu__title {
    border-bottom-color: #ff6801 !important;
    background-color: rgba(255, 255, 255, 0) !important;
  }
  .el-menu.el-menu--horizontal {
    border-bottom: 0.125rem !important;
    // background-color: rgba(255,255,255,0) !important;
  }
}
.black .box {
  width: 100%;
  height: 4.375rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;
}
.black .box .logo {
  width: 23%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  //  background: chocolate;
}
.black .box .logo :first-child {
  width: 7.625rem;
  height: 2.5625rem;
}
.black .box .logo img {
  width: 100%;
  //  height: 100%;
}
.navigationBar_box {
  width: 57%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  overflow: hidden;
}
.black .box .phone {
  display: flex;
  justify-content: flex-start;
  height: 100%;
  width: 20%;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  padding-left: 2.1875rem;
}
.phone_box {
  width: 100%;
  height: 4.375rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.phone_boxmini001 {
  // position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.1875rem;
  height: 1.5rem;
  margin: 0 0 0 0.9375rem;

  img {
    width: 100%;
    //  height: 100%;
  }
}
.phone_boxmini002 {
  width: 80%;
  height: 100%;
  font-size: 1.125rem;
  font-family: Source Han Sans CN;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-left: 0.3125rem;
  font-weight: 500;
  line-height: 1.625rem;
  overflow: hidden;
}
// 面包屑
.bread {
  background: #f7f7f7;
  // position: fixed;
  color: #000;
  width: 100%;
  height: 3.125rem;
  //  z-index: 999;
  left: 0;
  right: 0;
  top: 4.375rem;
  display: flex;
  align-items: center;
  justify-content: center;

  .bread_content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 100%;
    width: 100%;
    // background: chartreuse;

    .el-breadcrumb {
      font-size: 1.125rem;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #2b2b2b;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-left: 4.375rem;
      width: 40%;
    }
    // .el-breadcrumb__item{
    //     display: flex;
    //     flex-direction: row;
    //     flex-wrap: nowrap;
    //     // width: 100px;
    // }
    .el-breadcrumb :first-child :hover {
      color: #ff6700 !important;
      opacity: 0.8;
    }
    .el-breadcrumb :nth-child(3) :hover {
      // color: #FF6700 !important;
      cursor: pointer;
      color: #ff6700 !important;
      opacity: 0.8;
    }
    ::v-deep.el-breadcrumb__inner.is-link {
      color: #ff6801;
      font-weight: 400;
    }
  }
}
// 新闻主体区域
.box_fix {
  //  height: 1500px;
  width: 100%;
  margin-bottom: 6.25rem;
  //  background: forestgreen;

  .box_flex {
    height: 7.5rem;
    width: 100%;
    //  background: gold;
  }
  .box_content {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    //  height: 300px;

    .tip {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: center;
      width: 50%;
      margin-top: 3.125rem;
      //  background: chartreuse;

      .content {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 100%;
        font-size: 1.5rem;
        font-family: Alibaba PuHuiTi;
        font-weight: 500;
        color: #343434;
        line-height: 3.625rem;
      }
      .time {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 100%;
        font-size: 0.9375rem;
        font-family: Alibaba PuHuiTi;
        font-weight: 500;
        color: #aeadad;
        line-height: 1.875rem;
      }
      .line {
        width: 100%;
        height: 0.125rem;
        background: #bfbfbf;
        margin-top: 1.25rem;
      }
    }
    .content {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: center;
      width: 50%;
      font-size: 1rem;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #1a1a1a;
      line-height: 2.1875rem;
      text-align: left;
      margin-top: 3.125rem;

      .tip {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        margin: 0px;
      }
    }
  }
}
</style>
