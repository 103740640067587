<template>
  <div class="ieemooAbout">
    <!-- 1 顶部banner -->
    <div class="banner">
      <img src="../assets/downloadbanner.png" alt="" style="width:100%" />
    </div>
    <div class="ieemooAboutBox">
      <!-- 采用Container 布局容器 -->
      <el-container>
        <!-- 左侧关于亿猫一级导航 -->
        <el-aside class="pcOnly" width="21%">
          <div class="asideBox">
            <!-- <div class="asideBox_title">关于亿猫</div> -->
            <div class="asideBox_nav">
              <ul>
                <!-- <li style="background:#FF6700;color:#fff" @click="aboutSelect('id1')"> -->
                <li @click="aboutSelect('id1')">
                  <!-- <div  class="asideBox_navstyle" >公司介绍</div> -->
                  <div
                    :class="{ asideBox_navstyle_isactive: curr == 'id1' }"
                    class="asideBox_navstyle"
                  >
                    公司介绍
                  </div>
                </li>
                <li @click="aboutSelect('id2')">
                  <div
                    :class="{ asideBox_navstyle_isactive: curr == 'id2' }"
                    class="asideBox_navstyle"
                  >
                    企业荣誉
                  </div>
                </li>
                <li @click="aboutSelect('id3')">
                  <div
                    :class="{ asideBox_navstyle_isactive: curr == 'id3' }"
                    class="asideBox_navstyle"
                  >
                    重要资质
                  </div>
                </li>
                <li @click="aboutSelect('id4')">
                  <div
                    :class="{ asideBox_navstyle_isactive: curr == 'id4' }"
                    class="asideBox_navstyle"
                  >
                    媒体报道
                  </div>
                </li>
                <li @click="aboutSelect('id5')">
                  <div
                    :class="{ asideBox_navstyle_isactive: curr == 'id5' }"
                    class="asideBox_navstyle"
                  >
                    联系我们
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </el-aside>
        <!-- 右侧关于亿猫内容推送 -->
        <el-main>
          <div class="ieemooContentBox">
            <!-- 内容标题显示 -->
            <!-- <div class="ieemooContentBox_tip">
                <div></div>
                <div>{{ showText }}</div>
            </div> -->
            <!-- 内容呈现 -->
            <div class="ieemooContentBox_content">
              <!-- 第一个内容公司介绍 -->
              <div class="funny01" v-show="curr == 'id1'">
                <div style="width:100%;margin-left: 3.75rem;">
                  <h3 class="mob-title mobOnly">公司介绍</h3>
                  <p>
                    南京亿猫信息技术有限公司于2015年6月在南京成立，是国内智能购物车解决方案的行业引领者，是国内领先的智能购物车研发运营商，江苏省民营科技企业，南京市瞪羚企业。亿猫科技始终致力于为客户打造现代零售数字化新模式，助力商超大幅降低人工成本、提升效率，对传统零售业进行互联网颠覆式的升级改造，并为零售生态链各端用户提供丰富的商业智能和信息服务。
                  </p>
                  <p>
                    亿猫科技在杭州、上海设有分支机构，已经与国内多家连锁超市集团建立了战略合作，遍布华东、华南、西南地区，跨越商超、广告、营销、品牌主等零售行业多个分支市场。
                  </p>
                </div>
                <div class="aboutpicBox">
                  <img src="../assets/beauty.png" />
                </div>
              </div>
              <!-- 第二个企业荣誉内容 -->
              <div class="funny02" v-show="curr == 'id2'">
                <div class="funny02_box">
                  <h3 class="mob-title mobOnly">企业荣誉</h3>
                  <div class="box_float">
                    <img src="../assets/jinyibang.jpg" />
                  </div>
                  <div class="box_float">
                    <img src="../assets/rengongzhinengxiinrui.jpg" />
                  </div>
                  <div class="box_float">
                    <img src="../assets/jishuhangye.jpg" />
                  </div>
                  <div class="box_float">
                    <img src="../assets/shuzichuangxinyouxiujiang.jpg" />
                  </div>
                  <div class="box_float">
                    <img src="../assets/denglingqiye.jpg" />
                  </div>
                  <div class="box_float">
                    <img src="../assets/xinruiqiyechuangxinjiang.jpg" />
                  </div>
                </div>
              </div>
              <!-- 第三个重要资质 -->
              <div class="funny03" v-show="curr == 'id3'">
                <div class="funny03_box">
                  <h3 class="mob-title mobOnly">重要资质</h3>
                  <div
                    style="width:36%; margin-right:1.25rem"
                    class="box_float"
                  >
                    <img src="../assets/important01.jpg" />
                  </div>
                  <div style="width:36%;margin-right:1.25rem" class="box_float">
                    <img src="../assets/important02.jpg" />
                  </div>
                  <div style="width:36%;" class="box_float">
                    <img src="../assets/important03.png" />
                  </div>
                  <div style="width:36%;margin-right:1.25rem" class="box_float">
                    <img src="../assets/important04.png" />
                  </div>
                </div>
              </div>
              <!-- 第四个媒体报告 -->
              <keep-alive>
                <div class="funny04" v-show="curr == 'id4'">
                  <h3 class="mob-title mobOnly">新闻动态</h3>
                  <h4 class="mob-title-small mobOnly">
                    <span>NEWS CENTER</span>
                  </h4>
                  <ul>
                    <li @click="goNews8">
                      <div>
                        钛媒体2022年创新场景50 |
                        实体门店转型难，数字化如何重构人、货、场关系
                      </div>
                      <div>2022-07-26</div>
                    </li>
                    <li @click="goNews7">
                      <div>
                        36氪数智夜话直播 | 亿猫科技CEO：门店数智化机会在哪里？
                      </div>
                      <div>2022-05-19</div>
                    </li>
                    <li @click="goNews6">
                      <div>亿猫科技入选2021年度南京市创新型领军企业培育库</div>
                      <div>2021-12-10</div>
                    </li>
                    <li @click="goNews5">
                      <div>“亿猫智能购物车”荣登2021CHINASHOP金翼榜</div>
                      <div>2021-11-19</div>
                    </li>
                    <li @click="goNews4">
                      <div>
                        亿猫智能购物车助力『世纪联华鲸选宇宙空间站』首店开启
                      </div>
                      <div>2021-10-22</div>
                    </li>
                    <li @click="goNews1">
                      <div>
                        亿猫科技荣获上海国资国企数字化转型创新大赛[数字创新优胜奖]
                      </div>
                      <div>2021-07-22</div>
                    </li>
                    <li @click="goNews2">
                      <div>这款黑科技会让你爱上逛超市，你信不信？</div>
                      <div>2021-06-11</div>
                    </li>
                    <li @click="goNews3">
                      <div>
                        科技创新CEO特训营在京开营——亿猫科技创始人徐步兵先生入选
                      </div>
                      <div>2021-04-21</div>
                    </li>
                  </ul>
                  <div class="fyPagination">
                    <el-pagination
                      background
                      layout="prev, pager, next"
                      :total="10"
                    >
                    </el-pagination>
                  </div>
                </div>
              </keep-alive>

              <!-- 第五个联系我们 -->
              <div class="funny05" v-show="curr == 'id5'">
                <h3 class="mob-title mobOnly">联系我们</h3>
                <ul>
                  <li>
                    <div>公司名称：</div>
                    <div>南京亿猫信息技术有限公司</div>
                  </li>
                  <li>
                    <div>公司电话：</div>
                    <div>025-66044128-278</div>
                  </li>
                  <li>
                    <div>公司邮箱：</div>
                    <div>service@ieemoo.com</div>
                  </li>
                  <li>
                    <div>公司地址：</div>
                    <div>
                      中国·南京市建邺区奥体大街68号国际研发总部园5A栋5层
                    </div>
                  </li>
                </ul>
                <!-- 地图 -->
                <div class="mapbox_max">
                  <div class="mapbox">
                    <baidu-map
                      id="allmap"
                      @ready="mapReady"
                      :scroll-wheel-zoom="true"
                    >
                      <!-- bm-marker 就是标注点 定位在point的经纬度上 跳动的动画 -->
                      <bm-marker
                        :position="point"
                        animation="BMAP_ANIMATION_BOUNCE"
                      >
                      </bm-marker>
                    </baidu-map>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-main>
      </el-container>
    </div>
    <!-- 4  脚步 -->
    <footers></footers>
  </div>
</template>
<script>
import footers from "./footer";
export default {
  name: "HelloWorld",
  props: {
    msg: String
  },
  components: {
    footers
  },
  data() {
    return {
      selectNav: false,
      point: "",
      navstyle: {
        background: "#FF6700",
        color: "#fff"
      },
      curr: "id1",
      content: {
        id1: "公司介绍",
        id2: "企业荣誉",
        id3: "重要资质",
        id4: "媒体报道",
        id5: "联系我们"
      }
    };
  },
  computed: {
    showText() {
      return this.content[this.curr];
    }
  },
  methods: {
    goNews1() {
      this.$router.push("/newspage1");
    },
    goNews2() {
      this.$router.push("/newspage2");
    },
    goNews3() {
      this.$router.push("/newspage3");
    },
    goNews4() {
      this.$router.push("/newspage4");
    },
    goNews5() {
      this.$router.push("/newspage5");
    },
    goNews6() {
      this.$router.push("/newspage6");
    },
    goNews7() {
      this.$router.push("/newspage7");
    },
    goNews8() {
      this.$router.push("/newspage8");
    },
    aboutSelect(key) {
      this.curr = key;
      this.selectNav = true;
    },
    // 地图
    mapReady({ BMap, map }) {
      this.point = new BMap.Point(118.748531, 32.0006);
      //  第二个参数是地图缩放级别，最大为19，最小为0。
      map.centerAndZoom(this.point, 12);
    }
  }
};
</script>

<style lang="less" scoped>
.navstyle {
  background: #ff6700;
  color: #fff;
}
.ieemooAbout {
  width: 100%;
  background: #fff;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  margin: 0;
}
.banner {
  width: 100%;
  // height: 48.125rem;
  // height: calc(100vh - 18vh);
  // height: calc(100vh - 20vh);
  // height: calc(880 / 1080 * 100vh);
  // max-height: calc(100vh - 18vh);
  display: block;
  margin: 0;
  padding: 0;
  // background-image: url(../assets/aboutbanner2.png);
  // background-image: url(../assets/downloadbanner.png);
  // background-size: 100% 100%;
  // // background-size: cover;
  // background-size: 100% ;
  // background-repeat: no-repeat;
  margin-bottom: 5rem;
}
.banner img {
  width: 100%;
  // height: 100%;
}
.breadbox {
  width: 100%;
  height: 3.9375rem;
  background: #e5e5e5;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5rem;
}
.breadtxt {
  font-size: 1.125rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #2b2b2b;
  margin-left: 1.25rem;
  margin-right: 0.625rem;
}
.el-breadcrumb {
  font-size: 1.125rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #2b2b2b;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.el-breadcrumb :first-child :hover {
  color: #ff6700 !important;
}
.el-breadcrumb :nth-child(3) :hover {
  color: #ff6700 !important;
}
.ieemooAboutBox {
  width: 65%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12.5rem;

  // border: 1px solid rgba(0, 0, 0, 0.07);
  // // box-shadow: 0px 2px 10px 1px rgba(74, 126, 161, 0.23);
  // border-radius: 5px;
  // // background:cornflowerblue;
}
.el-container {
  width: 100%;
  // background: #f6f9fb;
  // border-radius: 10px 0 0 10px;
  border-top: 0.0625rem solid rgba(0, 0, 0, 0.07);
}
.el-aside {
  background: #f6f9fb;
  border-radius: 10px 0 0 10px;
  height: 44.375rem;
}
.asideBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.asideBox_title {
  font-size: 1.25rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #6b6b6b;
  line-height: 2.75rem;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 1.25rem;
}
.asideBox_nav {
  width: 100%;
}
.asideBox_nav ul {
  list-style-type: none;
  padding: 0px;
  width: 100%;
  margin: 0;
}
.asideBox_nav ul li {
  width: 100%;
  font-size: 2.1875rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #242424;
  height: 7.1875rem;
  // line-height: 115px;
  display: flex;
  align-items: center;
  justify-content: center;
  // margin-bottom: 10px;
}
.asideBox_nav ul li :hover {
  cursor: pointer;
}
.asideBox_navstyle {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%;
  // padding-left: 20px;
}
.asideBox_navstyle_isactive {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  border-radius: 0.5rem;
  width: 100%;
  // padding-left: 20px;
  background: #fff;
  color: #ff6801;
  border: 0.0625rem solid rgba(0, 0, 0, 0.07);
  box-shadow: 0px 0.125rem 1.0625rem 0.0625rem rgba(74, 126, 161, 0.23);
  border-radius: 0.3125rem;
}
.el-main {
  padding: 0;
  margin: 0;
  width: 79%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: visible !important;
}
.ieemooContentBox {
  width: 95%;
  background: #fff;
}
.ieemooContentBox_tip {
  width: 100%;
  height: 2.5rem;
  border-bottom: 0.125rem solid #d2d2d2;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0.625rem;
  font-size: 1.25rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #6b6b6b;
  margin-bottom: 1.25rem;
}
.ieemooContentBox_tip :first-child {
  width: 0.375rem;
  height: 1.125rem;
  background: #ff6700;
  margin: 0 0.625rem 0 0.625rem;
}
.ieemooContentBox_content {
  width: 100%;
  // background:cyan;
  // height: 900px;
}
//  1  企业介绍样式
.funny01 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 1.25rem;
}

.funny01 p {
  width: 90%;
  font-size: 1.375rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #6b6b6b;
  line-height: 3.125rem;
  text-indent: 2em;
  text-align: justify;
  padding: 0;
  margin: 0;
  // background: chocolate;
}
.aboutpicBox {
  width: 80%;
  // height: 30.3125rem;
  margin: 0.3125rem 0 1.25rem 0;
  // background: chartreuse;
}
.aboutpicBox img {
  width: 100%;
  // height: 100%;
}
//  2  企业荣誉样式
.funny02 {
  width: 95%;
  display: flex;
  justify-content: center;
  align-items: center;
  //background: crimson;
}
.funny02_box {
  margin: 1.25rem 0.5rem 12.5rem 3.125rem;
  display: flex;
  // height: 430px;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  align-content: inherit;
  // overflow: scroll;

  .box_float {
    width: 48%;
    margin-bottom: 1.25rem;
  }
}
.funny02 img {
  width: 100%;
  // height: 100%;
}
.box_float :hover {
  transform: translateY(-0.125rem);
  box-shadow: 0.3125rem 0.3125rem 0.3125rem #888888;
}
// 第三个重要资质
.funny03 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  // background: crimson;
}
.funny03_box {
  margin: 1.25rem 0px 18.75rem 3.125rem;
  display: flex;
  // height: 15.625rem;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  // align-items: center;
  align-content: center;
}
.funny03 img {
  width: 100%;
  // height: 100%;
}
//第四个媒体报告
.funny04 {
  width: 100%;
  //background: crimson;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.funny04 ul {
  list-style-type: none;
  padding: 0px;
  width: 90%;
  cursor: pointer;
}
.funny04 ul li {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 0.0625rem solid rgba(0, 0, 0, 0.07);
  font-size: 1.25rem;
  font-family: Source Han Sans CN;
  font-weight: 300;
  color: #000000;
  line-height: 3.75rem;
}
.funny04 ul li :hover {
  color: #2277da;
}
.funny04 ul li :nth-child(1) {
  // margin-left: 1.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 60%;
  white-space: nowrap;
  text-align: left;
  // display: flex;
  // justify-content: flex-start;
  // background: chartreuse;
}
.funny04 ul li :nth-child(2) {
  font-size: 0.9375rem;
  width: 40%;
  font-family: Source Han Sans CN;
  display: flex;
  justify-content: flex-end;
  font-weight: 300;
  color: #000000;
  line-height: 2.875rem;
  // margin-right: 1.25rem;
}
.fyPagination {
  margin-top: 12.5rem;
}
::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #ff6700;
  color: #fff;
}
::v-deep.el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: black;
}
.funny05 {
  width: 100%;
  // background: crimson;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.funny05 ul {
  list-style-type: none;
  padding: 0px;
  width: 100%;
  margin-left: 3.125rem;
}
.funny05 ul li {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  font-size: 1rem;
  font-family: SourceHanSansCN-Medium;
  color: #3b3b3b;
  line-height: 4.1875rem;
}
.funny05 ul li :nth-child(1) {
  font-size: 1.25rem;
  font-weight: 600;
}
.funny05 ul li :nth-child(2) {
  font-size: 1.125rem;
}
.mapbox_max {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-top: 0.625rem;
  margin-left: 3.125rem;
}
.mapbox {
  width: 80%;
  height: 25rem;
  // background: chartreuse;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
// 地图
#allmap {
  width: 38.4375rem;
  height: 25rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .el-main {
    width: 100%;
    .banner {
      margin-bottom: 2rem;
    }
    .ieemooAboutBox {
      width: 100%;
      margin-bottom: 0;
    }
    .el-container {
      border-top: none;
    }
    .mob-title {
      font-size: 2.5rem;
      margin: 1rem 0 2rem;
      color: #000;
      text-align: center;
    }
    .mob-title-small {
      text-align: center;
      span {
        color: #000;
        border: 1px solid #000;
        padding: 0 10px;
        display: inline-block;
        font-size: 1rem;
      }
    }
    .ieemooContentBox {
      width: 100%;
    }
    .ieemooContentBox_content > div {
      width: 100% !important;
      overflow: hidden;
      > div {
        display: block;
        margin: 0 5% 40px !important;
        width: 90% !important;
        &.aboutpicBox {
          margin: 0 auto 40px !important;
        }
        &.fyPagination {
          margin-top: 2rem !important;
        }
      }
      display: block !important;
    }
    .funny01 p {
      width: 100%;
      font-size: 1.6rem;
    }
    .funny02_box .box_float {
      width: 100%;
    }
    .funny03_box .box_float {
      width: 100% !important;
    }
    .funny04 ul {
      width: 90%;
      margin: 0 5%;
      li {
        font-size: 1.75rem;
        padding: 0 15px;
        box-sizing: border-box;
        display: block;
        font-weight: 400;
        color: rgb(41, 41, 41);
        > div:nth-child(1) {
          width: 100%;
        }
        > div:nth-child(2) {
          justify-content: left;
          font-weight: 400;
          margin-top: -1rem;
          color: #666;
          font-size: 1.4rem;
        }
      }
    }
    .funny05 {
      ul {
        width: 90%;
        margin: 0 5%;
        li {
          display: block;
          padding-left: 90px;
          position: relative;
          margin-bottom: 1rem;
          > div {
            text-align: left;
            font-size: 1.6rem;
            &:nth-child(1) {
              position: absolute;
              font-weight: bold;
              left: 0;
              top: 0;
              line-height: 2;
            }
            &:nth-child(2) {
              max-width: calc(100% - 80px);
              line-height: 2;
            }
          }
        }
      }
      .mapbox {
        width: 100%;
      }
    }
  }
}
</style>
