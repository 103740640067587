<template>
    <div class="solvebox">
<!-- 分三块区域  -->
<!--  1  顶部banner -->
        <div class="banner">
            <img src="../assets/solvebanner.png" alt="" style="width:100%">
        <!-- <el-image style="width:100%" :src="require('../assets/solvebanner.png')" fit="scale-down"></el-image> -->
        </div>
<!--  2  面包屑 -->
        <!-- <div class="breadbox">
            <div style="width:22px;height:31px;margin-left:100px"><img src="../assets/addressicon.png" style="width:100%;height:100%"></div>
            <div class="breadtxt">当前所在位置:</div> -->
            <!-- 面包 -->
            <!-- <div>
                <el-breadcrumb separator-class="null">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <div style="margin-right: 10px"><i class="el-icon-d-arrow-right"></i></div>
                <el-breadcrumb-item :to="{ path: '/solve'}">解决方案</el-breadcrumb-item>
                <div style="margin-right: 10px"><i class="el-icon-d-arrow-right"></i></div>
                <el-breadcrumb-item>营销数字化解决方案</el-breadcrumb-item>
                </el-breadcrumb>
           </div>
       </div> -->
<!--  3 营销数字化解决方案内容 -->
      <!-- 包裹盒子 -->
        <div class="solvecontent">
            <!-- 分三块区域 -->
            <!-- 第一块按钮选择区域 -->
            <!-- 第二块内容区域会根据第一块切换内容 -->
            <!-- 第三块不变的区域 -->

            <!-- 第一块  按钮选择  区域 -->
            <div class="solvecontent_tip">
                <!-- 两个按钮 -->
                <!-- 第一个按钮 -->
                <div class="solvecontent_tip_box" @click="solveicon('id01')">
                    <div>
                        <img v-show="this.stateL == 1" src="../assets/solveiconchoose1black.png" style="width:3.9375rem;height:3.3125rem">
                        <img v-show="this.stateL == 0"  src="../assets/solveiconchoose1gray.png" style="width:3.9375rem;height:3.3125rem">
                    </div>
                    <div :class="{ txt : this.stateL == 0}">硬件系统</div>
                </div>
                <!-- 第二个按钮 -->
                <div class="solvecontent_tip_box"  @click="solveicon('id02')">
                    <div >
                        <img v-show="this.stateR == 1" src="../assets/solveiconchoose2black.png" style="width:3.1875rem;height:3.5625rem">
                        <img v-show="this.stateR == 0" src="../assets/solveiconchoose2gray.png" style="width:3.1875rem;height:3.5625rem">
                    </div>
                    <div :class="{ txt : this.stateR == 0}">软件系统</div>
                </div>
            </div>
            <!-- 第二块  内容区域  会根据第一块切换内容 -->
            <!-- 1硬件区域 -->
            <div class="solvecontent_boxmax" v-show="this.stateL == 1">
                <!-- 三个区块 -->
                <!-- 第一个区块s60购物车里面我给了5个盒子 -->
                <div class="solvecontent_boxmax_one">
                    <!-- 小标题 -->
                    <!-- 第一个盒子 -->
                    <div class="solvecontent_boxmax_one_tip">
                        <!-- 这个用于占位居中 -->
                        <div class="a"></div>
                        <!-- 这个是标题主体部分 -->
                        <div class="b">
                            <div>S60购物车</div>
                            <!-- 底部横线 -->
                            <div></div>
                        </div>
                        <!-- 这个是标题后缀 -->
                        <div class="c">(购物车容量60升)</div>
                    </div>
                    <!-- 小标题内容框子里面有4个 -->
                    <!-- 111111 -->
                    <div class="solvecontent_boxmax_one_content">
                        <!-- 左侧图片 -->
                        <div class="left">
                            <div style="width: 29.375rem; height: 32.75rem;">
                              <img src="../assets/s6001.png" style="width:100%;height:100%">
                            </div>
                        </div>
                        <!-- 右侧文字 -->
                        <div class="right">
                            <div style="margin-top:5rem">
                                <!-- 文字标题 -->
                            <div class="tip">反欺诈/识别</div>
                            <!-- 文字列表 -->
                            <ul>
                                <li>
                                    <div></div>
                                    <div>0.1秒识别购买行为，有效识别率达99.5% 以上</div>
                                </li>
                                <li>
                                    <div></div>
                                    <div>机器视觉+多传感器融合识别算法</div>
                                </li>
                            </ul>
                            </div>
                        </div>
                    </div>
                    <!-- 2222222222 -->
                    <div class="solvecontent_boxmax_one_content">
                        <!-- 左侧图片 -->
                        <div class="left">
                            <div style="width: 29.3125rem; height: 33.875rem;">
                              <img src="../assets/s6002.png" style="width:100%;">
                            </div>
                        </div>
                        <!-- 右侧文字 -->
                        <div class="right">
                            <div style="margin-top:5rem">
                                <!-- 文字标题 -->
                            <div class="tip">传感器融合</div>
                            <!-- 文字列表 -->
                            <ul>
                                <li>
                                    <div></div>
                                    <div>单一技术维度无法精准识别欺诈行为</div>
                                </li>
                                <li>
                                    <div></div>
                                    <div>搭载摄像头、陀螺仪、重力感应等多款传感器</div>
                                </li>
                            </ul>
                            </div>
                        </div>
                    </div>
                    <!-- 33333333333333333 -->
                    <div class="solvecontent_boxmax_one_content">
                        <!-- 左侧图片 -->
                        <div class="left" style="flex-direction: column;justify-content: flex-end;">
                            <div style="width: 22.5rem; height: 30.8125rem;">
                              <img src="../assets/s6003.png" style="width:100%;">
                            </div>
                        </div>
                        <!-- 右侧文字 -->
                        <div class="right">
                            <div style="margin-top:5rem">
                                <!-- 文字标题 -->
                            <div class="tip">深度学习</div>
                            <!-- 文字列表 -->
                            <ul>
                                <li>
                                    <div></div>
                                    <div>随时采集商品多维度数据</div>
                                </li>
                                <li>
                                    <div></div>
                                    <div>强大的机器学习能力，深度学习，自动训练商品特征</div>
                                </li>
                            </ul>
                            </div>
                        </div>
                    </div>
                    <!-- 44444444444444444 -->
                    <div class="solvecontent_boxmax_one_content">
                        <!-- 左侧图片 -->
                        <div class="left" style="flex-direction: column;justify-content: flex-end;">
                            <div style="width: 23.5625rem; height: 33.875rem;">
                              <img src="../assets/s6004.png" style="width:100%;">
                            </div>
                        </div>
                        <!-- 右侧文字 -->
                        <div class="right">
                            <div style="margin-top:5rem">
                                <!-- 文字标题 -->
                            <div class="tip">知识产权</div>
                            <!-- 文字列表 -->
                            <ul>
                                <li>
                                    <div></div>
                                    <div>申报28项发明及实用新型专利</div>
                                </li>
                                <li>
                                    <div></div>
                                    <div>申报5项软件著作权</div>
                                </li>
                            </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 第二个区块s90购物车 -->
                <div class="solvecontent_boxmax_two">
                    <!-- 第二区块标题 -->
                    <div class="solvecontent_boxmax_one_tip">
                        <!-- 这个用于占位居中 -->
                        <div class="a"></div>
                        <!-- 这个是标题主体部分 -->
                        <div class="b">
                            <div>S90购物车</div>
                            <!-- 底部横线 -->
                            <div></div>
                        </div>
                        <!-- 这个是标题后缀 -->
                        <div class="c">(购物车容量90升)</div>
                    </div>
                    <!-- 先包裹一个大盒子 -->
                    <div class="solves90">
                        <!-- 左右俩盒子 -->
                        <div class="solves90_left">
                            <div class="left">
                                <!-- <el-image style="width:100%" :src="require('../assets/s9001.png')" fit="scale-down"></el-image> -->
                                <img src="../assets/s9001.png" style="width:100%">
                            </div>
                            <div class="explain_ab" style="top:4.0625rem;right:15rem" >
                                <div class="explain_fl">
                                    <div class="txt">10.1寸全高清防水防尘终端</div>
                                    <div class="line"></div>
                                    <div class="point"></div>
                                </div>
                            </div>
                            <div class="explain_ab" style="top:18.75rem;right:12.3rem">
                                <div class="explain_fl">
                                    <div class="txt">双框设计，最高承重：100KG</div>
                                    <div class="line" style="width:12rem"></div>
                                    <div class="point"></div>
                                </div>
                            </div>
                            <div class="explain_ab" style="top:24.6875rem;right:12.3rem">
                                <div class="explain_fl">
                                    <div class="txt">磁吸式充电，即插即充，无限续航</div>
                                    <div class="line" style="width:10rem"></div>
                                    <div class="point"></div>
                                </div>
                            </div>
                        </div>
                        <div class="solves90_right">
                            <div class="right">
                                <!-- <el-image style="width:100%" :src="require('../assets/s9002.png')" fit="scale-down"></el-image> -->
                                <img src="../assets/s9002.png" style="width:100%">
                            </div>
                            <div class="explain_ab" style="top:4.0625rem;left:14.0625rem">
                                <div class="explain_fl">
                                    <div class="point"></div>
                                    <div class="line" style="width:13rem"></div>
                                    <div class="txt">后置全高清/大广角双摄像模组</div>
                                </div>
                            </div>
                            <div class="explain_ab" style="top:18.25rem;left:22.5rem">
                                <div class="explain_fl">
                                    <div class="point"></div>
                                    <div class="line" style="width:12rem"></div>
                                    <div class="txt">购物车容量：90L</div>
                                </div>
                            </div>
                            <div class="explain_ab" style="top:23.5625rem;left:23.75rem">
                                <div class="explain_fl">
                                    <div class="point"></div>
                                    <div class="line" style="width:4rem"></div>
                                    <div class="txt">多车套插设计，节省放置空间</div>
                                </div>
                            </div>
                            <div class="explain_ab" style="top:37.5rem;left:29.5rem">
                                <div class="explain_fl">
                                    <div class="point"></div>
                                    <div class="line" style="width:2rem"></div>
                                    <div class="txt">3片式高性能万向电梯轮</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 第三个区块智能闸机&吐袋机 -->
                <div class="solvecontent_boxmax_three">
                    <div class="solvecontent_boxmax_one_tip">
                        <!-- 这个用于占位居中 -->
                        <div class="a"></div>
                        <!-- 这个是标题主体部分 -->
                        <div class="bb">
                            <div>智能闸机&吐袋机</div>
                            <!-- 底部横线 -->
                            <div></div>
                        </div>
                        <!-- 这个是标题后缀 -->
                        <div class="c"></div>
                    </div>
                    <!-- 先包裹一个大盒子 -->
                    <div class="solveintell">
                        <!-- 左右俩盒子 -->
                        <div class="solveintell_left">
                            <div class="left">
                                <!-- <el-image style="width:100%" :src="require('../assets/Gate.png')" fit="scale-down"></el-image> -->
                                <img src="../assets/Gate.png" style="width:100%">
                            </div>
                            <div class="explain_ab" style="top:8.9375rem;right:15.9375rem">
                                <div class="explain_fl">
                                    <div class="txt">高清摄像头</div>
                                    <div class="line" style="width:14.375rem"></div>
                                    <div class="point"></div>
                                </div>
                            </div>
                            <div class="explain_ab" style="top:13.125rem;right:15.9375rem">
                                <div class="explain_fl">
                                    <div class="txt">电子显示屏</div>
                                    <div class="line" style="width:14.375rem"></div>
                                    <div class="point"></div>
                                </div>
                            </div>
                            <div class="explain_ab" style="top:20.1875rem;right:15.625rem">
                                <div class="explain_fl">
                                    <div class="txt">超市购物卡置入处</div>
                                    <div class="line" style="width:13.75rem"></div>
                                    <div class="point"></div>
                                </div>
                            </div>
                        </div>
                        <div class="solveintell_right">
                            <div class="right">
                                <!-- <el-image style="width:100%" :src="require('../assets/Bag.png')" fit="scale-down"></el-image> -->
                                <img src="../assets/Bag.png" style="width:100%">
                            </div>
                            <div class="explain_ab" style="top:19.375rem;left:18.875rem">
                                <div class="explain_fl">
                                    <div class="point"></div>
                                    <div class="line" style="width:14.375rem"></div>
                                    <div class="txt">吐袋机出口处</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 2软件区域 -->
            <div class="solvecontent_boxmax2" v-show="this.stateR == 1">
                <!-- 两个区域 -->
                <!-- 第一个区域  卖场营销系统-->
                <div class="solvecontent_boxmax2_one">
                    <!-- 小标题 -->
                    <div class="solvecontent_boxmax_one_tip">
                        <!-- 这个用于占位居中 -->
                        <div class="a"></div>
                        <!-- 这个是标题主体部分 -->
                        <div class="bb">
                            <div>卖场营销系统</div>
                            <!-- 底部横线 -->
                            <div></div>
                        </div>
                        <!-- 这个是标题后缀 -->
                        <div class="c"></div>
                    </div>
                    <!-- 内容区域 -->
                    <div class="solvecontent_boxmax2_one_content">
                        <!-- 上下三个区域 -->
                        <!-- 第一块区域 有五个大盒子 -->
                        <div class="box">
                            <!-- 盒子model -->
                            <div class="model">
                                <!-- 图片盒子 -->
                                <div class="model_bigger" >
                                    <div class="model_img01">
                                    <img src="../assets/solvesoft01.png">
                                    </div>
                                </div>
                                <!-- 文字盒子 -->
                                <div class="model_txt">商品搜索</div>
                                <div class="point"></div>
                            </div>
                            <div class="model">
                                <!-- 图片盒子 -->
                                <div class="model_bigger">
                                    <div class="model_img02">
                                    <img src="../assets/solvesoft02.png">
                                </div>
                                </div>
                                <!-- 文字盒子 -->
                                <div class="model_txt">边逛边买</div>
                                <div class="point"></div>
                            </div>
                            <div class="model">
                                <!-- 图片盒子 -->
                                <div class="model_bigger">
                                    <div class="model_img03">
                                    <img src="../assets/solvesoft03.png">
                                </div>
                                </div>

                                <!-- 文字盒子 -->
                                <div class="model_txt">地图指引</div>
                                <div class="point"></div>
                            </div>
                            <div class="model">
                                <!-- 图片盒子 -->
                                <div class="model_bigger">
                                    <div class="model_img04">
                                    <img src="../assets/solvesoft04.png">
                                </div></div>
                                <!-- 文字盒子 -->
                                <div class="model_txt">偏好推荐</div>
                                <div class="point"></div>
                            </div>
                            <div class="model">
                                <!-- 图片盒子 -->
                                <div class="model_bigger">
                                    <div class="model_img05">
                                    <img src="../assets/solvesoft05.png">
                                </div>
                                </div>
                                <!-- 文字盒子 -->
                                <div class="model_txt">精准发券</div>
                                <div class="point"></div>
                            </div>
                        </div>
                        <!-- 第二块区域是曲线图  ( 有待解决 也有可能是和上个盒子合并 ！！！) -->
                        <div class="curve">
                            <div></div>
                        </div>
                        <!-- 第三块区域是底部标识 -->
                        <div class="bottomidentification">
                            在智能购物车上完成全方位营销
                        </div>
                    </div>
                </div>
                <!-- 第二块区域  亿猫超量精准数字营销引擎 -->
                <div class="solvecontent_boxmax2_two">
                    <!-- 小标题 -->
                    <div class="solvecontent_boxmax_one_tip">
                        <!-- 这个用于占位居中 -->
                        <!-- <div class="a"></div> -->
                        <!-- 这个是标题主体部分 -->
                        <div class="bbb">
                            <div>亿猫超量精准数字营销引擎</div>
                            <!-- 底部横线 -->
                            <div></div>
                        </div>
                        <!-- 这个是标题后缀 -->
                        <!-- <div class="c"></div> -->
                    </div>
                    <!-- 内容 -->
                    <div class="solvecontent_boxmax2_two_content">
                        <!-- 1 -->
                            <div class="advertisement">
                                <!-- 顶部标题区域 -->
                                <div>待机广告</div>
                                <!-- 中间图片 -->
                                <div>
                                    <div style="width:14.375rem;height:10.5625rem;position: relative;">
                                        <!-- <div class="advposi01">广告区域</div> -->
                                        <img src="https://ieemoo-storage.obs.cn-east-3.myhuaweicloud.com/portal/rectangle01.png" >
                                    </div>
                                </div>
                                <!-- 底部介绍 -->
                                <div style="width:100%">
                                    <span>媒体特点：</span>位置醒目，视觉冲击力强；展示时间长，触达率高；图片与视频两种形式，生动直达。
                                </div>
                            </div>
                            <!-- 2 -->
                            <div class="advertisement">
                                <!-- 顶部标题区域 -->
                                <div>登录广告</div>
                                <!-- 中间图片 -->
                                <div>
                                    <div style="width:14.375rem;height:10.5625rem;position: relative;">
                                        <!-- <div class="advposi02">广告区域</div> -->
                                        <img src="https://ieemoo-storage.obs.cn-east-3.myhuaweicloud.com/portal/rectangle02.png" >
                                    </div>
                                </div>
                                <!-- 底部介绍 -->
                                <div style="width:100%">
                                    <span>媒体特点：</span>位置醒目，视觉冲击力强；展示时间长，触达率高；图片与视频两种形式，生动直达。
                                </div>
                            </div>
                            <!-- 3 -->
                            <div class="advertisement">
                                <!-- 顶部标题区域 -->
                                <div>支付广告</div>
                                <!-- 中间图片 -->
                                <div>
                                    <div style="width:14.375rem;height:10.5625rem;position: relative;">
                                        <!-- <div class="advposi03">广告区域</div> -->
                                        <img src="https://ieemoo-storage.obs.cn-east-3.myhuaweicloud.com/portal/rectangle03.png" >
                                    </div>
                                </div>
                                <!-- 底部介绍 -->
                                <div style="width:100%">
                                    <span>媒体特点：</span>可轮播多幅广告，广告成本低；支付成功后必弹跳广告，可添加商家二维码，线下引流，关注度高。
                                </div>
                            </div>
                            <!-- 4 -->
                            <div class="advertisement">
                                <!-- 顶部标题区域 -->
                                <div>商品推荐广告</div>
                                <!-- 中间图片 -->
                                <div>
                                    <div style="width:14.375rem;height:10.5625rem;position: relative;">
                                        <!-- <div class="advposi04">广告区域</div> -->
                                        <img src="https://ieemoo-storage.obs.cn-east-3.myhuaweicloud.com/portal/rectangle04.png" >
                                    </div>
                                </div>
                                <!-- 底部介绍 -->
                                <div style="width:100%">
                                    <span>媒体特点：</span>后台数据分析，根据购买偏好智能推荐商品；基于货区位置推荐商品；品牌与用户1对1近距离沟通。
                                </div>
                            </div>
                            <!-- 5 -->
                            <div class="advertisement">
                                <!-- 顶部标题区域 -->
                                <div>弹窗广告</div>
                                <!-- 中间图片 -->
                                <div>
                                    <div style="width:14.375rem;height:10.5625rem;position: relative;">
                                        <!-- <div class="advposi05">广告区域</div> -->
                                        <img src="https://ieemoo-storage.obs.cn-east-3.myhuaweicloud.com/portal/rectangle05.png" >
                                    </div>
                                </div>
                                <!-- 底部介绍 -->
                                <div style="width:100%">
                                    <span>媒体特点：</span>点击引导页后弹出广告，观看率100%，高触达率用户可自行点击关闭，灵活性。
                                </div>
                            </div>
                    </div>
                </div>
            </div>
            <!-- 第三块不变的区域 -->
            <div class="solvevalue" ref="noChangeRef">
                <!-- 标题 -->
                <div class="solvevalue_tip">方案价值</div>
                <!-- 内容 -->
                <div class="solvevalue_content">
                    <!-- 三个小盒子 -->
                    <!-- 第一个 -->
                    <div class="solvevalue_contentbox">
                        <div class="B"></div>
                        <div :class="{pic:true,picAfter:tipShowChange}">
                            <img src="../assets/value01.png" >
                        </div>
                        <div class="txt_absolute">
                               <div :class="{txt_flex:true,txtAfter:tipShowChange}">
                                   <div>一站式精准投放</div>
                                   <div>基于登录、LBS、支付后等场景，实时推送，为品牌构建了从曝光到转化的直达通路</div>
                               </div>
                        </div>
                    </div>
                    <!-- 第二个 -->
                        <div class="solvevalue_contentbox">
                        <div class="B"></div>
                        <div :class="{pic02:true,picAfter:tipShowChange}">
                            <img src="../assets/value02.png" >
                        </div>
                        <div class="txt_absolute">
                               <div :class="{txt_flex:true,txtAfter:tipShowChange}">
                                   <div>多触点数字化营销</div>
                                   <div>聚集优惠券、满减、组合营销、LBS营销、关联推荐等丰富的内容产品，手机端、购物车的多端展现，实时促销、组合营销</div>
                               </div>
                        </div>
                    </div>

                    <!-- 第三个 -->
                        <div class="solvevalue_contentbox">
                        <div class="B"></div>
                        <div :class="{pic03:true,picAfter:tipShowChange}">
                            <img src="../assets/value03.png" >
                        </div>
                        <div class="txt_absolute">
                               <div :class="{txt_flex:true,txtAfter:tipShowChange}">
                                   <div>活跃会员管理</div>
                                   <div>洞察会员消费数据，分析会员偏好，创建多维度的会员画像，提高会员身份认知</div>
                               </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
<!-- 4  脚步 -->
    <footers></footers>
    </div>
</template>
<script>
import footers from './footer'
export default {
  data () {
    return {
      hardSoft: 'id01',
      stateL: 1,
      stateR: 0,
      tipShowChange: false,
    }
  },
  components: {
    footers
  },
  mounted () {
    window.addEventListener("scroll", this.showIcon)
  },
  beforeDestroy () {
    window.removeEventListener("scroll", this.showIcon);
  },
  methods: {
    showIcon () {
      this.currentScroll = window.pageYOffset; // 表示当前滚动的位置
      //   console.log(this.currentScroll);
      //   console.log(this.$refs.noChangeRef.offsetTop);
      if (this.currentScroll >= this.$refs.noChangeRef.offsetTop - 550) { // 当前滚动位置到达testref的时候，显示div（100作为调整用）
        this.tipShowChange = true;
      }
    },
    solveicon (key) {
      this.hardSoft = key;
      if (this.hardSoft === 'id01') {
        this.stateL = 1;
        this.stateR = 0;
      } else {
        this.stateL = 0;
        this.stateR = 1;
      }
    }
  }
}
</script>
<style lang="less" scoped>
.solvebox{
    width: 100%;
    background: #fff;
    color: #000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
    margin: 0;
}
.banner{
    width: 100%;
    // height: 48.125rem;
    // height: calc(100vh - 20vh);
    // height: calc(880 / 1080 * 100vh);
    // display: block;
    margin: 0;
    padding: 0;
    // background-image: url(../assets/solvebanner.png);
    // background-size: 100% ;
    // background-size: cover;
    // background-repeat: no-repeat;
    margin-bottom: 3.125rem;
}
.banner img{
    // width: 100%;
    // height: 100%;
}
.breadbox{
    width: 100%;
    height:3.9375rem;
    background: #E5E5E5;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 2.5rem;
}
.breadtxt{
    font-size: 1.125rem;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #2B2B2B;
    margin-left: 1.25rem;
    margin-right: 0.625rem;
}
.el-breadcrumb {
    font-size: 1.125rem;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #2B2B2B;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.el-breadcrumb :first-child :hover {
    color: #FF6700 !important;
}
.el-breadcrumb :nth-child(3) :hover {
    color: #FF6700 !important;
}
.solvecontent {
    width: 100%;
    // height: 8000px;
    // background: chartreuse;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.solvecontent_tip{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 2.25rem;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #242424;
    line-height: 1.625rem;
    height: 9.375rem;
    background: #fff;
    width: 100%;
    margin-bottom: 2.5rem;
}
.solvecontent_tip_box{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    cursor: pointer;
    margin: 0 3.75rem 0 3.75rem;

}
.solvecontent_tip_box  :first-child{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4rem;
    height: 4rem;
}
.solvecontent_tip_box .txt{
    color:#BABABA
}
.solvecontent_boxmax ,.solvecontent_boxmax_one{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}
.solvecontent_boxmax_one_tip{
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 12.5rem;
    background: #fff;
}
//a b c bb 适用于标题居中 结合父盒子justify-content: center使用
.a, .c{
    width: 18.75rem;
    height: 4.375rem;
    display: flex;
    justify-content: flex-start;
    line-height: 3.75rem;
    font-size: 1.5rem;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #000000;
    opacity: 0.8;
}
.b{
    width: 11.25rem;
    font-size: 2.25rem;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #000000;
    height: 4.375rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.b :nth-child(2){
    width: 3.1875rem;
    height: 0.4375rem;
    background: #FF6700;
}
.bb{
    width: 25rem;
    font-size: 2.25rem;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #000000;
    height: 4.375rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.bb :nth-child(2){
    width: 3.1875rem;
    height: 0.4375rem;
    background: #FF6700;
}
//bbb
.bbb{
    width: 31.25rem;
    font-size: 2.25rem;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #000000;
    height: 4.375rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.bbb :nth-child(2){
    width: 3.1875rem;
    height: 0.4375rem;
    background: #FF6700;
}
.solvecontent_boxmax_one_content{
    width: 72.625rem;
    height: 35.25rem;
    background: #f5f5f5;
    //opacity: 0.45;
    border-radius: 3.125rem;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 6.25rem;
}
.solvecontent_boxmax_one_content .left{
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.solvecontent_boxmax_one_content .right{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.solvecontent_boxmax_one_content .right .tip{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 2.25rem;
    font-family: Source Han Sans CN;
    font-weight: 500;
    width: 80%;
    color: #020202;
    margin-left: 1.25rem;
    margin-bottom: 3.125rem;
}
.solvecontent_boxmax_one_content .right ul{
    width: 80%;
    list-style-type: none;
    padding: 0;
}
.solvecontent_boxmax_one_content .right ul li{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 1.3125rem;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #030303;
    line-height: 2.3125rem;
    opacity: 0.85;
    text-align:left;
    position: relative;
    padding-left: 1.25rem;
     margin-bottom: 1.25rem;
     width:27.375rem;
}
.solvecontent_boxmax_one_content .right ul li :first-child{
    width: 0.3125rem;
    height: 0.8125rem;
    background: #FF6700;
    position: absolute;
    left: 0;
    top:0.75rem
}
.solvecontent_boxmax_two{
    width: 100%;
}
.solves90{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 43.75rem;
    // background: chocolate;
    margin-bottom: 5rem;
}
.solves90_left{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    // background:chartreuse;
    position: relative;

    .explain_ab{
        position: absolute;
         z-index:100;
        //  width:100%;
        //  background:red;
    }

    .explain_fl{
        display:flex;
        flex-direction: row;
        align-items: center;
        width:100%;
        // background:#fff;

        .txt{
            font-size: 1.25rem;
            // font-size: 20px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #000000;
        }

        .line{
            background: linear-gradient(135deg, rgba(255, 241, 84, 0) ,#ff6801 );
            width: 10.5rem;
            height: 0.125rem;
        }

        .point{
            width: 0.5rem;
            height: 0.5rem;
            border-radius: 50%;
            background: #FF6700;
            box-shadow: 0px 0px 0.0625rem 0.0625rem #fff,0px 0px 0.25rem 0.25rem #FF6700;
            animation: myAnimation 1s infinite;
            -webkit-animation: myAnimation 1s infinite;
        }
        @keyframes myAnimation {
            0% {
                opacity: 0.1;
            }
            30% {
                opacity: 0.2;
            }
            50% {
                opacity: 0.5;
            }
            70% {
                opacity: 0.7;
            }
            100% {
                opacity: 0.8
            }
            }
            @-webkit-keyframes myAnimation {
            0% {
                opacity: 0.1;
            }
            30% {
                opacity: 0.2;
            }
            50% {
                opacity: 0.5;
            }
            70% {
                opacity: 0.7;
            }
            100% {
                opacity: 0.8
            }
            }
    }
}
.solves90_left .left{
    width: 22rem;
    // height: 39.375rem;
    margin-right: 1.375rem;
    // background: cornflowerblue;
    z-index: 100;
}
.solves90_left .left img{
    width: 100%;
    // height: 100%;
}
.solves90_right{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;

    .explain_ab{
        position: absolute;
         z-index:100;
        //  width:100%;
        //  background:red;
    }

    .explain_fl{
        display:flex;
        flex-direction: row;
        align-items: center;
        width:100%;
        // background:#fff;

        .txt{
            font-size: 1.25rem;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #000000;
        }

        .line{
            background: linear-gradient(135deg, #ff6801,rgba(255, 241, 84, 0)  );
            width: 8.6875rem;
            height: 2px;
        }

        .point{
            width: 0.5rem;
            height: 0.5rem;
            border-radius: 50%;
            background: #FF6700;
            box-shadow: 0px 0px 0.0625rem 0.0625rem #fff,0px 0px 0.25rem 0.25rem #FF6700;
            animation: myAnimation 1s infinite;
            -webkit-animation: myAnimation 1s infinite;
        }
        @keyframes myAnimation {
            0% {
                opacity: 0.1;
            }
            30% {
                opacity: 0.2;
            }
            50% {
                opacity: 0.5;
            }
            70% {
                opacity: 0.7;
            }
            100% {
                opacity: 0.8
            }
            }
            @-webkit-keyframes myAnimation {
            0% {
                opacity: 0.1;
            }
            30% {
                opacity: 0.2;
            }
            50% {
                opacity: 0.5;
            }
            70% {
                opacity: 0.7;
            }
            100% {
                opacity: 0.8
            }
            }
    }
}
.solves90_right .right{
    width: 30.6875rem;
    // height: 39.9375rem;
    margin-left: 1.375rem;
    z-index: 100;

}
.solves90_right .right img{
    width: 100%;
    // height: 100%;
}
// 一样
.solvecontent_boxmax_three{
    width: 100%;
}
.solveintell{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 50rem;
    // background: chocolate;
}
.solveintell_left{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    position: relative;

    .explain_ab{
        position: absolute;
         z-index:100;
        //  width:100%;
        //  background:red;
    }

    .explain_fl{
        display:flex;
        flex-direction: row;
        align-items: center;
        width:100%;
        // background:#fff;

        .txt{
            font-size: 1.25rem;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #000000;
        }

        .line{
            background: linear-gradient(135deg, rgba(255, 241, 84, 0),#ff6801  );
            width: 10.5rem;
            height: 0.125rem;
        }

        .point{
            width: 0.5rem;
            height: 0.5rem;
            border-radius: 50%;
            background: #FF6700;
            box-shadow: 0px 0px 0.0625rem 0.0625rem #fff,0px 0px 0.25rem 0.25rem #FF6700;
            animation: myAnimation 1s infinite;
            -webkit-animation: myAnimation 1s infinite;
        }
        @keyframes myAnimation {
            0% {
                opacity: 0.1;
            }
            30% {
                opacity: 0.2;
            }
            50% {
                opacity: 0.5;
            }
            70% {
                opacity: 0.7;
            }
            100% {
                opacity: 0.8
            }
            }
            @-webkit-keyframes myAnimation {
            0% {
                opacity: 0.1;
            }
            30% {
                opacity: 0.2;
            }
            50% {
                opacity: 0.5;
            }
            70% {
                opacity: 0.7;
            }
            100% {
                opacity: 0.8
            }
            }
    }
}
.solveintell_left .left{
    width: 17.4375rem;
    // height: 38.6875rem;
    margin-right: 2.25rem;
}
.solveintell_left .left img{
    width: 100%;
    height: 100%;
}
.solveintell_right{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;

    .explain_ab{
        position: absolute;
         z-index:10;
        //  width:100%;
        //  background:red;
    }

    .explain_fl{
        display:flex;
        flex-direction: row;
        align-items: center;
        width:100%;
        // background:#fff;

        .txt{
            font-size: 1.25rem;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #000000;
        }

        .line{
            background: linear-gradient(135deg, #ff6801,rgba(255, 241, 84, 0)  );
            width: 10.5rem;
            height: 0.125rem;
        }

        .point{
            width: 0.5rem;
            height: 0.5rem;
            border-radius: 50%;
            background: #FF6700;
            box-shadow: 0px 0px 0.0625rem 0.0625rem #fff,0px 0px 0.25rem 0.25rem #FF6700;
            animation: myAnimation 1s infinite;
            -webkit-animation: myAnimation 1s infinite;
        }
        @keyframes myAnimation {
            0% {
                opacity: 0.1;
            }
            30% {
                opacity: 0.2;
            }
            50% {
                opacity: 0.5;
            }
            70% {
                opacity: 0.7;
            }
            100% {
                opacity: 0.8
            }
            }
            @-webkit-keyframes myAnimation {
            0% {
                opacity: 0.1;
            }
            30% {
                opacity: 0.2;
            }
            50% {
                opacity: 0.5;
            }
            70% {
                opacity: 0.7;
            }
            100% {
                opacity: 0.8
            }
            }
    }
}
.solveintell_right .right{
    width: 24.125rem;
    // height: 39.75rem;
    margin-left: 2.25rem;
    margin-top: 3.125rem;
}
.solveintell_right .right img{
    width: 100%;
    // height: 100%;
}
// 不变区域
.solvevalue{
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    // height: 50rem;
    background: #fff;
}
.solvevalue_tip{
    width: 100%;
    height: 12.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.5rem;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #000000;
    line-height: 1.625rem;
    // margin-bottom: 50px;
    // background:chocolate;
}
.solvevalue_content{
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    align-items: center;
    width: 79%;
    margin: 1.25rem auto 12.5rem auto;
}
.solvevalue_contentbox{
    width:30%;
    height: 28.3125rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.solvevalue_contentbox .B{
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.6) ;
    position:absolute;
    left: 0;
    top: 0;
    z-index: 10;
    // transform: translateY(300px);  //滚动前的位置
    // opacity: 0;   //滚动前设置透明隐藏
    }
    .solvevalue_contentbox .pic{
    width: 11.4375rem;
    height: 16.3125rem;
    overflow: hidden;
    transform: translateY(18.75rem);  //滚动前的位置
    opacity: 0;   //滚动前设置透明隐藏
    // z-index: 10;
    }
    .solvevalue_contentbox .picAfter{
    transform: translateY(0%)!important;  //滚动后的位置
    opacity: 1!important;  //滚动后显现
    transition: all .6s ease-out;
}
    .solvevalue_contentbox .pic img{
        width: 100%;
        // height: 100%;
    }
    .solvevalue_contentbox .pic02{
    width: 16.4375rem;
    height: 13rem;
    overflow: hidden;
    transform: translateY(18.75rem);  //滚动前的位置
    opacity: 0;   //滚动前设置透明隐藏
    // z-index: 10;
    }
    .solvevalue_contentbox .pic02 img{
        width: 100%;
        // height: 100%;
    }
    .solvevalue_contentbox .pic03{
    width: 13.625rem;
    height: 16.875rem;
    overflow: hidden;
    transform: translateY(18.75rem);  //滚动前的位置
    opacity: 0;
    }
    .solvevalue_contentbox .pic03 img{
        width: 100%;
        // height: 100%;
    }
    .txt_absolute{
        position: absolute;
        z-index: 10;
        color: #fff;
        width: 100%;
        height: 100%;
        top:0;
        left:0;

        .txtAfter{
            transform: translateY(0%)!important;  //滚动后的位置
            opacity: 1!important;  //滚动后显现
            transition: all .8s ease-out;
        }
    }
    .txt_flex{
        width: 80%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        transform: translateY(-18.75rem);  //滚动前的位置
        opacity: 0;
    }
    .txt_flex :first-child{
        // height: 1.875rem;
        font-size: 2rem;
        font-family: Source Han Sans CN;
        font-weight: 400;
        height: 50%;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        align-items: center;
        color: #FEFEFE;
        line-height: 2.3125rem;
        // margin-bottom: 1.875rem;
    }
    .txt_flex :nth-child(2){
        font-size: 1.125rem;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 50%;
        line-height: 1.75rem;
        margin-top: 2.5rem;
    }
    // 可变第二区域
    .solvecontent_boxmax2{
        width: 100%;
        // background: chartreuse;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
    .solvecontent_boxmax2_one_content{
        width: 80.25rem;
        height: 24.9375rem;
        background: #FFFFFF;
        box-shadow: 0px 0.1875rem 0.9375rem 0.0625rem rgba(136, 136, 136, 0.25);
        border-radius: 1.25rem;
        margin-bottom: 0.9375rem;
        margin-bottom: 5rem;
    }
    .solvecontent_boxmax2_two_content{
        width: 80.25rem;
        height: 30.625rem;
        background: #FFFFFF;
        box-shadow: 0px 0.1875rem 0.9375rem 0.0625rem rgba(136, 136, 136, 0.25);
        border-radius: 1.25rem;
        margin-bottom: 3.125rem;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-around;
        align-items: center;
    }
    .solvecontent_boxmax2_one_content .box {
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: row;
        flex-wrap: nowrap;
        width: 100%;
    }
    .solvecontent_boxmax2_one_content .box .model{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin-top: 1.875rem;

        .point{
            width: 0.5rem;
            height: 0.5rem;
            border-radius: 50%;
            background: #FF6700;
            margin-top: 0.9375rem;
            box-shadow: 0px 0px 0.25rem 0.25rem #fff,0px 0px 0.25rem 0.25rem #FF6700;
            animation: myAnimation 1.2s infinite;
            -webkit-animation: myAnimation 1.5s infinite;
        }
        @keyframes myAnimation {
            0% {
                opacity: 0.1;
            }
            30% {
                opacity: 0.2;
            }
            50% {
                opacity: 0.5;
            }
            70% {
                opacity: 0.7;
            }
            100% {
                opacity: 0.8
            }
            }
            @-webkit-keyframes myAnimation {
            0% {
                opacity: 0.1;
            }
            30% {
                opacity: 0.2;
            }
            50% {
                opacity: 0.5;
            }
            70% {
                opacity: 0.7;
            }
            100% {
                opacity: 0.8
            }
            }
    }
    .model_bigger{
        width:8.625rem;
        height:9.5rem;
        display:flex;
        flex-direction:column;
        align-items: center;
        justify-content:center
    }
    .model_img01{
        width: 8rem;
        height: 8.125rem;
    }
    .model_img01 img{
        width: 100%;
        // height: 100%;
    }
    .model_img02{
        width: 7.375rem;
        height: 9.1875rem;
    }
    .model_img02 img{
        width: 100%;
        // height: 100%;
    }
    // 3
    .model_img03{
        width: 8.625rem;
        height: 9.125rem;
    }
    .model_img03 img{
        width: 100%;
        // height: 100%;
    }
    // 4
    .model_img04{
        width: 5.875rem;
        height: 9.875rem;
    }
    .model_img04 img{
        width: 100%;
        // height: 100%;
    }
    // 5
    .model_img05{
        width: 7.375rem;
        height: 9.5rem;
    }
    .model_img05 img{
        width: 100%;
        height: 100%;
    }
    // 变大
    .model_img01 :hover{
        width: 120%;
        height: 120%;
    }
    .model_img02 :hover{
        width: 120%;
        height: 120%;
    }
    .model_img03 :hover{
        width: 120%;
        height: 120%;
    }
    .model_img04 :hover{
        width: 110%;
        height: 110%;
    }
    .model_img05 :hover{
        width: 120%;
        height: 120%;
    }
    .model_txt{
        font-size: 1.5rem;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #000000;
        line-height: 1.625rem;
        margin-top: 2.9375rem;
    }
    // 曲线盒子
    .curve{
        width: 100%;
        height: 2.625rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background: chocolate;
        opacity: 0.3;
        // margin-top: 20px;
        background: radial-gradient(circle at 50% -46.1875rem, transparent 48.75rem,black 48.8125rem,transparent 48.875rem) no-repeat,
                    radial-gradient(circle at 50% -197.5rem, transparent 200rem,black 200.0625rem,transparent 200.125rem) no-repeat;
                    // radial-gradient(ellipse, transparent 0px,black 1px,transparent 2px) no-repeat;

                    :first-child{
                        width: 0.0625rem;
                        background: black;
                        height: 100%;
                    }

    }
    // 曲线
    .bottomidentification{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.25rem;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #FF6700;
        margin-top: 1.25rem;
    }
    .advertisement{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        // background: chartreuse;
        width: 14.375rem;
        height: 80%;
        margin-top: -1.25rem;
    }
    .advertisement img{
        width: 100%;
        // height: 100%;
    }
    .advertisement :first-child{
        font-size: 1.5rem;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #000;
        line-height: 1.625rem;
    }
    .advertisement :nth-child(3){
        font-size: 1rem;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #7C7C7C;
        line-height: 1.5rem;
        text-align: left;
        height: 5.8125rem;
    }
    .advertisement :nth-child(3) span{
        font-size: 1.25rem;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #050505;
        line-height: 1.5rem;
    }
    .advposi01 , .advposi02 ,.advposi03 ,.advposi04, .advposi05{
        position: absolute;
        font-size: 1.625rem;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #FEAE78 !important;
        z-index: 10;
        line-height: 1.5rem;
        letter-spacing: 0.1875rem;
    }
    .advposi01{
        top: 3.5625rem;
        left: 3.875rem;
    }
    .advposi02{
        top: 4.625rem;
        left: 1.4375rem;
    }
    .advposi03{
        top: 3.1875rem;
        left: 4rem;
    }
    .advposi04{
        top: 1.625rem;
        left: 5rem;
    }
    .advposi05{
        top: 3.1875rem;
        left: 4rem;
    }
</style>
