<template>
  <div class="content">
    <!-- 1 顶部导航 -->
    <div
      class="pcOnly"
      style="width:100%;height:4.375rem;background:black"
    ></div>
    <!-- 2  面包屑 -->
    <div class="bread" style="color:#ff6801">
      <div class="bread_content">
        <el-breadcrumb separator-class="null">
          <el-breadcrumb-item
            ><span @click="goToIndex">首页</span></el-breadcrumb-item
          >
          <div style="margin-right: 0.625rem">
            <i class="el-icon-arrow-right"></i>
          </div>
          <el-breadcrumb-item
            ><span @click="goToAbout">关于亿猫</span></el-breadcrumb-item
          >
          <div style="margin-right: 0.625rem">
            <i class="el-icon-arrow-right"></i>
          </div>
          <el-breadcrumb-item>新闻动态</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <!-- 3  新闻内容 -->
    <div class="box_fix">
      <!-- <div class="box_flex"></div> -->
      <div class="box_content">
        <!-- 标题 -->
        <div class="tip">
          <!-- 标题内容 -->
          <div class="content">“亿猫智能购物车”荣登2021CHINASHOP金翼榜</div>
          <!-- 标题时间 -->
          <div class="time">2021-11-19</div>
          <!-- 标题分割线 -->
          <div class="line"></div>
        </div>
        <!-- 内容 -->
        <div class="content">
          <!-- 段落1 -->
          <div>
            <img src="../assets/news05img01.png" alt="" />
          </div>
          <!-- 段落2 -->
          <div class="tip">
            <p>
              “亿猫智能购物车”荣登由第二十三届中国零售业博览会CHINASHOP主办的2021金翼榜优选-最佳营销工具榜！
            </p>
          </div>
          <!-- 段落3 -->
          <div>
            <img src="../assets/news05img02.png" alt="" />
          </div>
          <!-- 段落4 -->
          <div class="tip">
            <p>
              2021年金翼榜征集活动从8月开始，历经2个多月时间共计收集130余个来自零售业各领域的优秀案例。同时，今年金翼榜的评委阵容也再次升级，邀请到了来自中国零售连锁百强企业、国内外零售技术巨头、头部网络电商零售平台、知名营销设计企业以及中国连锁经营协会成员共计26位评委倾情参与。
            </p>
          </div>
          <!-- 段落5 -->
          <div>
            <img src="../assets/news05img03.png" alt="" />
          </div>
          <!-- 段落6 -->
          <div class="tip">
            <p>
              2021年金翼榜的口号是“金翼求金”。经过初筛、讨论、评分、终审等多个环节，评委们本着公正、公平的评审态度，共同评选出今年中国零售行业内最具实用效果的优秀实践案例，打造集权威性与综合性为一体的零售服务商领域，已成为最具影响力和实用参考价值的零售行业综合性榜单，助力行业焕新升级。
            </p>
          </div>
          <!-- 段落7 -->
          <div>
            <img src="../assets/news05img04.png" alt="" style="width:40rem;" />
          </div>
          <!-- 段落8 -->
          <div class="tip">
            <p>
              亿猫科技获此殊荣，将继续秉承以推动中国零售数字化为己任，不断创新迭代，用品质引领行业，为零售生态提供更加丰富的商业价值！
            </p>
          </div>
        </div>
      </div>
      <!-- <div class="box_flex" style="background:#000"></div>
            <div class="box_flex"></div>
            <div class="box_flex" style="background:#000"></div> -->
    </div>
    <!--  4   脚步 -->
    <footers></footers>
  </div>
</template>
<script>
import footers from "../views/footer";
export default {
  components: {
    footers
  },
  data() {
    return {};
  },
  methods: {
    goToAbout() {
      console.log("22");
      this.$router.push("/about");
    },
    goToIndex() {
      console.log("332");
      this.$router.push("/indextab");
    }
  }
};
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  background: #fff;
  color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  margin: 0;
}
// 新闻导航栏导航栏样式
.black {
  position: fixed;
  background: #000;
  width: 100%;
  height: 4.375rem;
  z-index: 999;
  left: 0;
  right: 0;
  top: 0px;

  .el-menu-demo {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 1.125rem;
    width: 100%;
  }
  ::v-deep.el-icon-arrow-down:before {
    content: "";
  }
  ::v-deep.el-menu-item {
    font-size: 1.125rem;
    font-family: Source Han Sans CN;
    font-weight: 500;
    height: 4.375rem !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ::v-deep.el-menu--horizontal > .el-submenu .el-submenu__title:hover {
    background-color: rgba(255, 255, 255, 0) !important;
    // color: #ff6801 !important;
    border-bottom-color: #ff6801 !important;
    // border-bottom-color: #fff !important;
  }
  ::v-deep.el-menu--horizontal
    > .el-submenu
    .el-submenu__title
    > .el-icon-arrow-down:before {
    // content: "";
  }
  ::v-deep.el-menu--horizontal
    > .el-submenu.el-submenu.is-active
    > .el-submenu__title {
    border-bottom-color: #ff6801 !important;
    background-color: rgba(255, 255, 255, 0) !important;
  }
  ::v-deep.el-menu--horizontal > .el-submenu.el-submenu.is-active.is-opened {
    // border-bottom-color: #ff6801 !important;
    background-color: rgba(255, 255, 255, 0) !important;
  }
  ::v-deep.el-menu--horizontal
    > .el-submenu.el-submenu.is-opened
    .el-submenu__title {
    background-color: rgba(255, 255, 255, 0) !important;
    // border-bottom-color: #ff6801 !important;
  }
  // ::v-deep.el-menu--horizontal>.el-submenu.el-submenu.is-active{
  //     background-color: rgba(255,255,255,0) !important;
  // }
  ::v-deep.el-menu--horizontal > .el-submenu .el-submenu__title {
    margin-left: 0.875rem !important;
    height: 4.375rem !important;
    display: flex;
    justify-content: center;
    align-items: center;
    // margin-right: -10px;
    padding-right: 0px !important;
    // border-bottom-color: rgba(255,255,255,0.3) !important;
  }
  .el-menu--horizontal > .el-menu-item {
    // width:6.25rem !important;
    margin-left: 0.75rem !important;
    border-bottom-color: rgba(255, 255, 255, 0) !important;
  }
  ::v-deep.el-menu--horizontal > .el-submenu .el-submenu__title {
    font-size: 1.125rem;
    background-color: rgba(255, 255, 255, 0) !important;
    font-family: Source Han Sans CN;
    font-weight: 500;
  }
  // ::v-deep.el-menu--horizontal>.el-submenu .el-submenu__title.is-active{
  //   background: #ff6801;
  // }
  // ::v-deep.el-menu--horizontal>.el-submenu.is-active.is-opened>.el-submenu__title>.el-menu--horizontal{
  //     top: 60px !important;

  // }
  .el-menu {
    background-color: rgba(255, 255, 255, 0) !important;
    border-color: rgba(255, 255, 255, 0) !important;
    border-bottom-color: rgba(255, 255, 255, 0) !important;
  }
  .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
  .el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
  .el-menu--horizontal > .el-submenu .el-submenu__title:hover {
    background-color: rgba(255, 255, 255, 0) !important;
    border-color: rgba(255, 255, 255, 0) !important;
    border-bottom-color: #ff6801 !important;
  }
  .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus {
    background-color: rgba(255, 255, 255, 0) !important;
    border-color: rgba(255, 255, 255, 0) !important;
    border-bottom-color: #ff6801 !important;
  }
  .el-menu-item.is-active {
    border-color: #ff6801 !important;
    border-bottom-color: #ff6801 !important;
  }
  .el-submenu.is-active .el-submenu__title {
    border-bottom-color: #ff6801 !important;
    background-color: rgba(255, 255, 255, 0) !important;
  }
  .el-menu.el-menu--horizontal {
    border-bottom: 0.125rem !important;
    // background-color: rgba(255,255,255,0) !important;
  }
}
.black .box {
  width: 100%;
  height: 4.375rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;
}
.black .box .logo {
  width: 23%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  //  background: chocolate;
}
.black .box .logo :first-child {
  width: 7.625rem;
  height: 2.5625rem;
}
.black .box .logo img {
  width: 100%;
  //  height: 100%;
}
.navigationBar_box {
  width: 57%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  overflow: hidden;
}
.black .box .phone {
  display: flex;
  justify-content: flex-start;
  height: 100%;
  width: 20%;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  padding-left: 2.1875rem;
}
.phone_box {
  width: 100%;
  height: 4.375rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.phone_boxmini001 {
  // position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.1875rem;
  height: 1.5rem;
  margin: 0 0 0 0.9375rem;

  img {
    width: 100%;
    //  height: 100%;
  }
}
.phone_boxmini002 {
  width: 80%;
  height: 100%;
  font-size: 1.125rem;
  font-family: Source Han Sans CN;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-left: 0.3125rem;
  font-weight: 500;
  line-height: 1.625rem;
  overflow: hidden;
}
// 面包屑
.bread {
  background: #f7f7f7;
  // position: fixed;
  color: #000;
  width: 100%;
  height: 3.125rem;
  //  z-index: 999;
  left: 0;
  right: 0;
  top: 4.375rem;
  display: flex;
  align-items: center;
  justify-content: center;

  .bread_content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 100%;
    width: 100%;
    // background: chartreuse;

    .el-breadcrumb {
      font-size: 1.125rem;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #2b2b2b;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-left: 4.375rem;
      width: 40%;
    }
    // .el-breadcrumb__item{
    //     display: flex;
    //     flex-direction: row;
    //     flex-wrap: nowrap;
    //     // width: 100px;
    // }
    .el-breadcrumb :first-child :hover {
      color: #ff6700 !important;
      opacity: 0.8;
    }
    .el-breadcrumb :nth-child(3) :hover {
      // color: #FF6700 !important;
      cursor: pointer;
      color: #ff6700 !important;
      opacity: 0.8;
    }
    ::v-deep.el-breadcrumb__inner.is-link {
      color: #ff6801;
      font-weight: 400;
    }
  }
}
// 新闻主体区域
.box_fix {
  //  height: 1500px;
  width: 100%;
  margin-bottom: 6.25rem;
  //  background: forestgreen;

  .box_flex {
    height: 7.5rem;
    width: 100%;
    //  background: gold;
  }
  .box_content {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    //  height: 300px;

    .tip {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: center;
      width: 50%;
      margin-top: 3.125rem;
      //  background: chartreuse;

      .content {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 100%;
        font-size: 1.5rem;
        font-family: Alibaba PuHuiTi;
        font-weight: 500;
        color: #343434;
        line-height: 3.625rem;
      }
      .time {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 100%;
        font-size: 0.9375rem;
        font-family: Alibaba PuHuiTi;
        font-weight: 500;
        color: #aeadad;
        line-height: 1.875rem;
      }
      .line {
        width: 100%;
        height: 0.125rem;
        background: #bfbfbf;
        margin-top: 1.25rem;
      }
    }
    .content {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: center;
      width: 50%;
      font-size: 1rem;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #1a1a1a;
      line-height: 2.1875rem;
      text-align: left;
      margin-top: 3.125rem;

      .tip {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        margin: 0px;
      }
    }
  }
}
</style>
