import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../components/Index.vue'
import Indextab from '../components/Index_tab.vue'
import Solve from '../views/Solve.vue'
import Service from '../views/Service_support.vue'
import About from '../views/About.vue'
import Paysolve from '../views/PaySolve.vue'
import Downloadpage from '../views/DownloadPage.vue'
import NewsPage1 from '../news/NewsPage1.vue'
import NewsPage2 from '../news/NewsPage2.vue'
import NewsPage3 from '../news/NewsPage3.vue'
import NewsPage4 from '../news/NewsPage4.vue'
import NewsPage5 from '../news/NewsPage5.vue'
import NewsPage6 from '../news/NewsPage6.vue'
import NewsPage7 from '../news/NewsPage7.vue'
import NewsPage8 from '../news/NewsPage8.vue'
import NewsPage9 from '../news/NewsPage9.vue'
import NewsPage10 from '../news/NewsPage10.vue'
import NewsPage11 from '../news/NewsPage11.vue'

Vue.use(VueRouter)

// const routes = [
//   {
//     path: '/',
//     name: 'index',
//     component: Index
//   },
// ]
// 解决面包屑路由导航重复问题
// 获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
// 修改原型对象中的push方法
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  routes: [
    { path: '/', redirect: '/index' },
    {
      path: '/index',
      component: Index,
      redirect: '/indextab',
      children: [{ path: '/indextab', component: Indextab },
        { path: '/solve', component: Solve },
        { path: '/service', component: Service },
        { path: '/about', component: About },
        { path: '/paysolve', component: Paysolve },
        { path: '/downloadpage', component: Downloadpage },
        { path: '/newspage1', component: NewsPage1 },
        { path: '/newspage2', component: NewsPage2 },
        { path: '/newspage3', component: NewsPage3 },
        { path: '/newspage4', component: NewsPage4 },
        { path: '/newspage5', component: NewsPage5 },
        { path: '/newspage6', component: NewsPage6 },
        { path: '/newspage7', component: NewsPage7 },
        { path: '/newspage8', component: NewsPage8 },
        { path: '/newspage9', component: NewsPage9 },
        { path: '/newspage10', component: NewsPage10 },
        { path: '/newspage11', component: NewsPage11 },
      ]
    }
  ]
})

export default router
