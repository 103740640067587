<template>
    <div class="downloadbox">
        <!--  1  顶部banner -->
        <div class="banner">
          <div class="backColor" style="width:100%;height:4.375rem;background:black"></div>
          <img src="../assets/bannertest.png" alt="" style="width:100%;">
          <!-- <el-image style="width:100%;" :src="require('../assets/bannertest.png')" fit="scale-down"></el-image> -->
        </div>
        <!--  2  下载tip -->
        <!-- <div class="tip">
            <div>下载专区</div>
            <div class="pic"><img src="../assets/downloadiconbottom.png" alt=""></div>
        </div> -->
        <!--  3  下载content -->
        <div class="content">
            <div class="box">
                <!-- 第一行 -->
                <div class="tip">
                    <div class="one">文件名称</div>
                    <div class="two">文件大小</div>
                    <div class="three">下载次数</div>
                    <div class="four">更新时间</div>
                    <div class="five">下载</div>
                </div>
                <!-- 第二行 -->
                <div class="line">
                  <div class="one">亿猫科技产品视频.mp4</div>
                  <div class="two">33.1MB</div>
                  <!-- <div class="three">3987</div> -->
                  <div class="three">{{num01}}</div>
                  <div class="four">2021-04-23</div>
                  <div class="five" >
                    <div class="left">
                      <div class="pic">
                        <!-- <el-image style="width:100%" :src="require('../assets/FILEicon.png')" fit="scale-down"></el-image> -->
                        <img src="../assets/FILEicon.png" style="width:100%" alt="">
                      </div>
                    </div>
                    <div class="right">
                      <div class="pic">
                        <!-- <el-image style="width:100%" :src="require('../assets/cloud.png')" fit="scale-down"></el-image> -->
                        <img src="../assets/cloud.png" alt="" style="width:100%">
                      </div>
                      <div>
                        <a href="https://ieemoo-storage.obs.cn-east-3.myhuaweicloud.com/portal/ieemooVideo.mp4" download="ieemooVideo.mp4" style="color:#ff6801" @click="addNum01">下载</a>
                        </div>
                    </div>
                  </div>
                </div>
                <!-- 第三行 -->
                <div class="line">
                  <div class="one">亿猫科技产品手册.pdf</div>
                  <div class="two">215MB</div>
                  <div class="three">{{num02}}</div>
                  <div class="four">2021-04-23</div>
                  <div class="five">
                    <div class="left">
                      <div class="pic">
                        <!-- <el-image style="width:100%" :src="require('../assets/PDFicon.png')" fit="scale-down"></el-image> -->
                        <img src="../assets/PDFicon.png" alt="" style="width:100%">
                      </div>
                    </div>
                    <div class="right">
                      <div class="pic">
                        <!-- <el-image style="width:100%" :src="require('../assets/cloud.png')" fit="scale-down"></el-image> -->
                        <img src="../assets/cloud.png" alt="" style="width:100%">
                      </div>
                      <div>
                        <a href="https://ieemoo-storage.obs.cn-east-3.myhuaweicloud.com/portal/Brochure1026.pdf" download="Brochure1026.pdf" style="color:#ff6801" @click="addNum02">下载</a>
                        </div>
                    </div>
                  </div>
                </div>
            </div>
        </div>
        <!--  4   脚步 -->
    <footers></footers>
    </div>
</template>
<script>
import footers from './footer'
export default {
  data () {
    return {
      num01: 3456,
      num02: 2678
    }
  },
  components: {
    footers
  },
  methods: {
    addNum01 () {
      this.num01 = this.num01 + 1
    },
    addNum02 () {
      this.num02 = this.num02 + 1
    },
    // frontDownload () {
    //   var a = document.createElement("a"); // 创建一个<a></a>标签
    //   a.href = "#/assets/value01.png"; // 给a标签的href属性值加上地址，注意，这里是绝对路径，不用加 点.
    //   a.download = "下载啦.png"; // 设置下载文件文件名，这里加上.xlsx指定文件类型，pdf文件就指定.fpd即可
    //   a.style.display = "none"; // 障眼法藏起来a标签
    //   document.body.appendChild(a); // 将a标签追加到文档对象中
    //   a.click(); // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
    //   a.remove(); //  一次性的，用完就删除a标签
    // }
  }
}
</script>
<style lang="less" scoped>
.downloadbox{
    width: 100%;
    background: #fff;
    color: #000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
    margin: 0;
}
.banner{
    width: 100%;
    // height: 48.125rem;
    // height: calc(100vh - 20vh);
    // max-height: calc(100vh - 18vh);
    // height: calc(880 / 1080 * 100vh);
    // display: block;
    margin: 0;
    padding: 0;
    // background-image: url(../assets/downloadbanner.png);
    // background-image: url(../assets/aboutbanner3.png);
    // background-image: url(../assets/bannertest.jpg);
    // background-size: 100% ;
    // background-size: cover;
    // background-repeat: no-repeat;
}
.tip{
    width: 100%;
    height: 6.5rem;
    background: #fff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #000000;
    // line-height: 67px;
    letter-spacing: 0.25rem;
    margin-top:5rem;

     .pic{
         margin-left: 0.1875rem;
         width: 1.875rem;
        //  height: 2rem;

         img{
             width: 100%;
            //  height: 100%;
         }
     }
}
.content{
    width: 100%;
    // height: 600px;
    // background:chocolate;
    display: flex;
    justify-content: center;
    align-items: center;

    .box{
        width: 70.5625rem;
        // height: 300px;
        margin: 0px 0 12.5rem 0;
        background: #fff;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        .tip{
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-direction: row;
            flex-wrap: nowrap;
            border-bottom: 0.0625rem solid rgba(0, 0, 0, 0.07);
            font-size: 1rem;
            font-family: Source Han Sans CN;
            font-weight: 700;
            color: #000000;
            line-height: 4.1875rem;
            margin-top: 10rem;
            height: 3.125rem;

            .one{
              width: 25%;
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              flex-wrap: wrap;
              padding-left: 0.625rem;
              align-items: center;
            }
            .two{width: 15%;
            display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;
            }
            .three{
              width: 20%;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
            }
            .four{
              width: 20%;
              // display: flex;
              // flex-direction: row;
              // justify-content: flex-start;
              // align-items: center;
            }
            .five{
              // height: 100%;
              width: 25%;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
            }
          }
          .line{
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-direction: row;
            flex-wrap: nowrap;
            font-size: 1rem;
            font-family: Source Han Sans CN;
            border-bottom: 0.0625rem solid rgba(0, 0, 0, 0.07);
            font-weight: 400;
            color: #000000;
            height: 7.5rem;
            // line-height: 80px;
            // margin-top: 30px;

            .one{
              width: 25%;
              display: flex;
              padding-left: 0.625rem;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;
            }
            .two{
              width: 13%;
              display: flex;
              flex-wrap: wrap;
              line-height: 1rem;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;
            }
            .three{
              width: 18%;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
            }
            .four{
              width: 20%;
              // display: flex;
              // flex-direction: row;
              // justify-content: flex-start;
              // align-items: center;
            }
            .five{
              // height: 100%;
              width: 25%;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              // background: chocolate;

              .left{
                width: 40%;
                display: flex;
                flex-direction: row;
                // background: chartreuse;
                justify-content: center;
                align-items: center;
                height: 100%;

                .pic{
                  width: 3.625rem;
                  // height: 4.125rem;
                  margin-left: 3.125rem;

                  img{
                    width: 100%;
                    // height: 100%;
                  }
                }
              }
              .right{
                width: 60%;
                height: 100%;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                // background: chartreuse;

                .pic{
                  width: 1.25rem;
                  // height: 0.9375rem;
                  // background: chocolate;
                  margin: 0 0.9375rem 0 1.875rem;

                  img{
                    width: 100%;
                    // height: 100%;
                  }
                }
              }
            }
          }
                }
            }

</style>
