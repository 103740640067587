<template>
<div class="suportContent">
    <!-- 1 顶部banner -->
    <div class="banner">
        <img src="../assets/serverbanner.jpg" alt="" style="width:100%">
        <!-- <el-image style="width:100%" :src="require('../assets/serverbanner.png')" fit="scale-down"></el-image> -->
    </div>
    <!-- 2 面包屑 -->
    <!-- <div class="breadbox">
        <div style="width:22px;height:31px;margin-left:100px"><img src="../assets/addressicon.png" style="width:100%;height:100%"></div>
        <div class="breadtxt">当前所在位置:</div> -->
        <!-- 面包 -->
        <!-- <div>
            <el-breadcrumb separator-class="null">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <div style="margin-right: 10px"><i class="el-icon-d-arrow-right"></i></div>
                <el-breadcrumb-item>服务支持</el-breadcrumb-item>
                </el-breadcrumb>
        </div>
    </div> -->
    <!-- 3 内容主体区域 -->
    <div class="suportContentreall">
        <div class="suportContentreall_title">服务支持</div>
        <div class="smallTip">以服务赢得客户，用技术驱动创新</div>
        <!-- 第一个 -->
        <div class="suportContentreall_content">
            <!-- 左边 -->
            <div class="suportContentreall_content_pic">
                <div style="width:37.0625rem;">
                    <!-- <el-image style="width:100%" :src="require('../assets/servebig01.png')" fit="scale-down"></el-image> -->
                    <img src="../assets/servebig01.png" style="width:100%;">
                </div>
            </div>
            <!-- 右边 -->
            <div class="suportContentreall_content_icon">
                <!-- <div class="icon1absolute">
                    <img src="../assets/num01.png" style="width:100%;height:100%">
                </div> -->
                <div class="textabsolute">
                    <div class="textflex">
                        <div class="textflex_title">门店环境部署</div>
                        <div class="textflex_title_small">Store environment deployment</div>
                        <div  class="textflex_content">
                            <ul>
                                <li>
                                   <!-- <div class="square"></div> -->
                                   <div>室内定位系统安装</div>
                                </li>
                                <li>
                                    <!-- <div class="square"></div> -->
                                    <div>WIFI或4G通讯安装</div>
                                </li>
                                <li>
                                    <!-- <div class="square"></div> -->
                                    <div>货架地图绘制</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <!-- 第二个 -->
         <div class="suportContentreall_content">
            <!-- 左边 -->
            <div class="suportContentreall_content_pic">
                <div style="width:32.4375rem; ">
                    <!-- <el-image style="width:100%" :src="require('../assets/servebig02.png')" fit="scale-down"></el-image> -->
                    <img src="../assets/servebig02.png" style="width:100%;">
                </div>
            </div>
            <!-- 右边 -->
            <div class="suportContentreall_content_icon">
                <!-- <div class="icon1absolute" style="width:129px;height:102px">
                    <img src="../assets/num02.png" style="width:100%;height:100%">
                </div> -->
                <div class="textabsolute">
                    <div class="textflex">
                        <div class="textflex_title">智能闸机部署</div>
                        <div class="textflex_title_small">Intelligent gate deployment</div>
                        <div  class="textflex_content">
                            <ul>
                                <li>
                                   <!-- <div class="square"></div> -->
                                   <div>智能闸机安装施工</div>
                                </li>
                                <li>
                                    <!-- <div class="square"></div> -->
                                    <div>系统对接联调</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <!-- 第三个 -->
        <div class="suportContentreall_content">
            <!-- 左边 -->
            <div class="suportContentreall_content_pic">
                <div style="width:36.1875rem; ">
                    <!-- <el-image style="width:100%" :src="require('../assets/servebig03.png')" fit="scale-down"></el-image> -->
                    <img src="../assets/servebig03.png" style="width:100%;">
                </div>
            </div>
            <!-- 右边 -->
            <div class="suportContentreall_content_icon">
                <!-- <div class="icon1absolute" style="width:128px;height:102px">
                    <img src="../assets/num03.png" style="width:100%;height:100%">
                </div> -->
                <div class="textabsolute">
                    <div class="textflex">
                        <div class="textflex_title">超市系统对接</div>
                        <div class="textflex_title_small">Supermarket system docking</div>
                        <div  class="textflex_content">
                            <ul>
                                <li>
                                   <!-- <div class="square"></div> -->
                                   <div>商品档案信息对接</div>
                                </li>
                                <li>
                                    <!-- <div class="square"></div> -->
                                    <div>促销、优惠券、会员信息对接</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <!-- 第四个 -->
        <div class="suportContentreall_content">
            <!-- 左边 -->
            <div class="suportContentreall_content_pic">
                <div style="width:41.4375rem;">
                    <!-- <el-image style="width:100%" :src="require('../assets/servebig04.png')" fit="scale-down"></el-image> -->
                    <img src="../assets/servebig04.png" style="width:100%;">
                </div>
            </div>
            <!-- 右边 -->
            <div class="suportContentreall_content_icon">
                <!-- <div class="icon1absolute" style="width:124px;height:98px">
                    <img src="../assets/num04.png" style="width:100%;height:100%">
                </div> -->
                <div class="textabsolute">
                    <div class="textflex">
                        <div class="textflex_title">智能购物车部署</div>
                        <div class="textflex_title_small">Smart shopping cart deployment</div>
                        <div  class="textflex_content">
                            <ul>
                                <li>
                                   <!-- <div class="square"></div> -->
                                   <div>自动化充电系统安装</div>
                                </li>
                                <li>
                                    <!-- <div class="square"></div> -->
                                    <div>车辆自动化监控与维护</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <!--  4 脚步 -->
    <footers></footers>
</div>
</template>

<script>
import footers from './footer'
export default {
  components: {
    footers
  },
}
</script>
<style lang="less" scoped>
.suportContent{
    width: 100%;
    height: 100%;
    background: #FFFFFF;
    color: #fff;
     display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    margin: 0;
}
.banner{
    width: 100%;
    // height: 48.125rem;
    // height: calc(100vh - 20vh);
    // height: calc(880 / 1080 * 100vh );
    display: block;
    margin: 0;
    padding: 0;
    // background-image: url(../assets/serverbanner.jpg);
    // background-size: 100%;
    // // background-size: cover;
    // background-repeat: no-repeat;
}
.banner img{
    // width: 100%;
    // height: 100%;
}
.breadbox{
    width: 100%;
    height:3.9375rem;
    background: #E5E5E5;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.breadtxt{
    font-size: 1.125rem;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #2B2B2B;
    margin-left: 1.25rem;
    margin-right: 0.625rem;
}
.el-breadcrumb {
    font-size: 1.125rem;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #2B2B2B;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.el-breadcrumb :first-child :hover {
    color: #FF6700 !important;
}
.suportContentreall{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}
.suportContentreall_title{
    width: 100%;
    margin-top: 3.75rem;
    height: 6.25rem;
    // background: chartreuse;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.25rem;
    font-weight: 700;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #000000;
}
.smallTip{
    font-size: 1.8125rem;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #969696;
    margin-bottom: 6.25rem;
}
.suportContentreall_content{
    width: 70%;
    height: 19.75rem;
    // background: #F5F9FF;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 12.5rem;
}
.suportContentreall_content_pic{
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.suportContentreall_content_icon{
    width: 50%;
    height: 100%;
    position: relative;
    margin-left: 3.125rem;
    background: linear-gradient(150deg,#ecf9fc , rgba(255, 241, 84, 0));
}
.icon1absolute{
    position: absolute;
    width: 3.625rem;
    height: 6.4375rem;
    top: 4.875rem;
    left: 5rem;
    z-index: 100;
}
.textabsolute{
    position: absolute;
    width: 100%;
    // background: chartreuse;
    height: 18.75rem;
    top: 3.125rem;
    left: 4.375rem;
    z-index: 100;
}
.textflex{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    color: #000000;
}
.textflex_title{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    font-size: 1.875rem;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #000000;
    line-height: 1.875rem;
}
.textflex_title_small{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    font-size: 1rem;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #B8B4B4;
    line-height: 2.25rem;
}
.textflex_content{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-top: 0.9375rem;
}
.textflex_content ul{
    list-style-type:none;
    padding: 0px;
}
.square{
    width: 0.625rem;
    height: 0.625rem;
    background: #3866EA ;
    margin-right: 1.0625rem;
}
.textflex_content ul li{
    font-size: 1.25rem;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #464646;
    line-height: 2.8125rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
</style>
