<template>
  <div class="content">
    <!-- 1 顶部导航 -->
    <div
      class="pcOnly"
      style="width:100%;height:4.375rem;background:black"
    ></div>
    <!-- 2  面包屑 -->
    <div class="bread" style="color:#ff6801">
      <div class="bread_content">
        <el-breadcrumb separator-class="null">
          <el-breadcrumb-item
            ><span @click="goToIndex">首页</span></el-breadcrumb-item
          >
          <div style="margin-right: 0.625rem">
            <i class="el-icon-arrow-right"></i>
          </div>
          <el-breadcrumb-item
            ><span @click="goToAbout">关于亿猫</span></el-breadcrumb-item
          >
          <div style="margin-right: 0.625rem">
            <i class="el-icon-arrow-right"></i>
          </div>
          <el-breadcrumb-item>新闻动态</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <!-- 3  新闻内容 -->
    <div class="box_fix">
      <!-- <div class="box_flex"></div> -->
      <div class="box_content">
        <!-- 标题 -->
        <div class="tip">
          <!-- 标题内容 -->
          <div class="content">
            钛媒体2022年创新场景50 |
            实体门店转型难，数字化如何重构人、货、场关系
          </div>
          <!-- 标题时间 -->
          <div class="time">2022-07-26</div>
          <!-- 标题分割线 -->
          <div class="line"></div>
        </div>
        <!-- 内容 -->
        <div class="content">
          <div class="tip">
            <p>
              数字化就是创新场景的叠加，钛媒体将推出「创新场景50」系列经典实例，遴选全行业优先应用案例，用通俗易懂的语言针对数字化创新实例进行解读汇总。后续将积累内容产品化，建立可检索的企业数字化应用案例库，抹平企业服务供需信息差。
            </p>
          </div>
          <!-- 段落3 -->
          <div class="tip">
            <p>
              经过广泛征集和对比筛选，【钛媒体-创新场景50】将一些优秀实体门店数字化转型案例进行整理并作分享，以期其他实体门店经营者能从中汲取数字化转型发展新思路，重塑新业务与经营模式，在瞬息万变的市场竞争中，屹立不倒。
            </p>
          </div>
          <div class="tip">
            <p>本篇为【创新场景50】第二期案例合集，优秀场景展示--亿猫科技：</p>
          </div>
          <div class="tip">
            <p>
              场景：亿猫智能购物车，定义沉浸式购物场景
              助力联华华商实现高效精准数字化营销＋运营
            </p>
          </div>
          <div class="tip">
            <p>
              实体店数字化转型，线下商超是重点变革阵地。联华华商集团是浙江省连锁超市的龙头企业，旗下大小网点500多家，遍布浙江多个城区。作为一家知名的传统商超企业，联华华商很清楚数字化转型对于传统商超经营发展的意义。与国内众多连锁超市面临的困境一样，华联华商对自身经营管理和运营痛点也十分清晰。尤其面对日益增长的租金、人工与营销成本，实体店本身弊病二次触达困难与服务模式老旧及品牌营销粗放且效果差等问题，联华华商已经意识到数字化转型迫在眉睫。
            </p>
          </div>
          <div class="tip">
            <p>
              基于自身以上经营痛点，联华华商找到了国内智能购物车行业引领者——亿猫科技，亿猫科技专注技术创新，用领先行业的反欺诈识别AI技术，打造了软硬一体的智能购物车＋自助支付＋精准营销整体解决方案。亿猫智能购物车能够通过分析客户特征、购物、路线等流程数据，实现身份识别、智能称重、AI防损、定位导购、精准营销、自助结算六大功能，以顾客为中心、以数据为驱动，经过后台数据对接处理，描绘精准的顾客画像，构建多种营销模型，重构“人、货、场”关系，打造新一代的沉浸式购物场景。
            </p>
          </div>
          <div>
            <img src="../assets/pic01.jpg" alt="" />
          </div>
          <div class="tip">
            <p>
              在2020-2025年的信息系统规划中，联华华商把亿猫智能购物车选为集中重点突破板块--云门店的核心部分。亿猫智能购物车参与云门店的卖场物理到数字化转型建设、卖场收银结算无人化、营销实时化、营运管理数字化四个部分革新，实现在商业操作系统基础上，通过人工智能达到关键需求领域预测智能化和决策智能化，营销管理数智化的转型。
            </p>
          </div>
          <div class="tip">
            <p>
              双方积极探索、灵活合作。联华华商在一些数字化升级完成良好的门店，大量部署亿猫智能购物车。消费者可通过刷脸、手机号或扫码等多种方式取车购物，为消费者带来数字化购物新体验；亿猫智能购物车汇聚了大量品牌的活动资源，消费者可通过屏幕了解专属优惠，领取优惠券、赠品等，实现品牌多端触达，精准营销。最后在智能结算方面，亿猫智能购物车做到了智能扫描与智能支付，消费者在卖场内随时随地结算，无人工干扰，直接走专属闸机通道离场。
            </p>
          </div>
          <div>
            <img src="../assets/pic02.png" alt="" />
          </div>
          <div>
            <img src="../assets/pic03.png" alt="" />
          </div>
          <!-- 段落4 -->
          <div class="tip">
            <p>
              亿猫智能购物车不仅刷新了消费者购物体验，培养了用户习惯，在提升门店形象的同时，也起到了科学防疫，避免人员聚集的作用。还能通过后台购物数据分析实现精准营销、智能结算，为超市门店节省了大量的人力和收银成本，降低了运营成本，提升了坪效人效。
            </p>
          </div>
        </div>
      </div>
      <!-- <div class="box_flex" style="background:#000"></div>
      <div class="box_flex"></div>
      <div class="box_flex" style="background:#000"></div> -->
    </div>
    <!--  4   脚步 -->
    <footers></footers>
  </div>
</template>
<script>
import footers from "../views/footer";
export default {
  components: {
    footers
  },
  data() {
    return {};
  },
  methods: {
    goToAbout() {
      console.log("22");
      this.$router.push("/about");
    },
    goToIndex() {
      console.log("332");
      this.$router.push("/indextab");
    }
  }
};
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  background: #fff;
  color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  margin: 0;
}
// 新闻导航栏导航栏样式
.black {
  position: fixed;
  background: #000;
  width: 100%;
  height: 4.375rem;
  z-index: 999;
  left: 0;
  right: 0;
  top: 0px;

  .el-menu-demo {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 1.125rem;
    width: 100%;
  }
  ::v-deep.el-icon-arrow-down:before {
    content: "";
  }
  ::v-deep.el-menu-item {
    font-size: 1.125rem;
    font-family: Source Han Sans CN;
    font-weight: 500;
    height: 4.375rem !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ::v-deep.el-menu--horizontal > .el-submenu .el-submenu__title:hover {
    background-color: rgba(255, 255, 255, 0) !important;
    // color: #ff6801 !important;
    border-bottom-color: #ff6801 !important;
    // border-bottom-color: #fff !important;
  }
  ::v-deep.el-menu--horizontal
    > .el-submenu
    .el-submenu__title
    > .el-icon-arrow-down:before {
    // content: "";
  }
  ::v-deep.el-menu--horizontal
    > .el-submenu.el-submenu.is-active
    > .el-submenu__title {
    border-bottom-color: #ff6801 !important;
    background-color: rgba(255, 255, 255, 0) !important;
  }
  ::v-deep.el-menu--horizontal > .el-submenu.el-submenu.is-active.is-opened {
    // border-bottom-color: #ff6801 !important;
    background-color: rgba(255, 255, 255, 0) !important;
  }
  ::v-deep.el-menu--horizontal
    > .el-submenu.el-submenu.is-opened
    .el-submenu__title {
    background-color: rgba(255, 255, 255, 0) !important;
    // border-bottom-color: #ff6801 !important;
  }
  // ::v-deep.el-menu--horizontal>.el-submenu.el-submenu.is-active{
  //     background-color: rgba(255,255,255,0) !important;
  // }
  ::v-deep.el-menu--horizontal > .el-submenu .el-submenu__title {
    margin-left: 0.875rem !important;
    height: 4.375rem !important;
    display: flex;
    justify-content: center;
    align-items: center;
    // margin-right: -10px;
    padding-right: 0px !important;
    // border-bottom-color: rgba(255,255,255,0.3) !important;
  }
  .el-menu--horizontal > .el-menu-item {
    // width:6.25rem !important;
    margin-left: 0.75rem !important;
    border-bottom-color: rgba(255, 255, 255, 0) !important;
  }
  ::v-deep.el-menu--horizontal > .el-submenu .el-submenu__title {
    font-size: 1.125rem;
    background-color: rgba(255, 255, 255, 0) !important;
    font-family: Source Han Sans CN;
    font-weight: 500;
  }
  // ::v-deep.el-menu--horizontal>.el-submenu .el-submenu__title.is-active{
  //   background: #ff6801;
  // }
  // ::v-deep.el-menu--horizontal>.el-submenu.is-active.is-opened>.el-submenu__title>.el-menu--horizontal{
  //     top: 60px !important;

  // }
  .el-menu {
    background-color: rgba(255, 255, 255, 0) !important;
    border-color: rgba(255, 255, 255, 0) !important;
    border-bottom-color: rgba(255, 255, 255, 0) !important;
  }
  .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
  .el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
  .el-menu--horizontal > .el-submenu .el-submenu__title:hover {
    background-color: rgba(255, 255, 255, 0) !important;
    border-color: rgba(255, 255, 255, 0) !important;
    border-bottom-color: #ff6801 !important;
  }
  .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus {
    background-color: rgba(255, 255, 255, 0) !important;
    border-color: rgba(255, 255, 255, 0) !important;
    border-bottom-color: #ff6801 !important;
  }
  .el-menu-item.is-active {
    border-color: #ff6801 !important;
    border-bottom-color: #ff6801 !important;
  }
  .el-submenu.is-active .el-submenu__title {
    border-bottom-color: #ff6801 !important;
    background-color: rgba(255, 255, 255, 0) !important;
  }
  .el-menu.el-menu--horizontal {
    border-bottom: 0.125rem !important;
    // background-color: rgba(255,255,255,0) !important;
  }
}
.black .box {
  width: 100%;
  height: 4.375rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;
}
.black .box .logo {
  width: 23%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  //  background: chocolate;
}
.black .box .logo :first-child {
  width: 7.625rem;
  height: 2.5625rem;
}
.black .box .logo img {
  width: 100%;
  //  height: 100%;
}
.navigationBar_box {
  width: 57%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  overflow: hidden;
}
.black .box .phone {
  display: flex;
  justify-content: flex-start;
  height: 100%;
  width: 20%;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  padding-left: 2.1875rem;
}
.phone_box {
  width: 100%;
  height: 4.375rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.phone_boxmini001 {
  // position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.1875rem;
  height: 1.5rem;
  margin: 0 0 0 0.9375rem;

  img {
    width: 100%;
    //  height: 100%;
  }
}
.phone_boxmini002 {
  width: 80%;
  height: 100%;
  font-size: 1.125rem;
  font-family: Source Han Sans CN;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-left: 0.3125rem;
  font-weight: 500;
  line-height: 1.625rem;
  overflow: hidden;
}
// 面包屑
.bread {
  background: #f7f7f7;
  // position: fixed;
  color: #000;
  width: 100%;
  height: 3.125rem;
  //  z-index: 999;
  left: 0;
  right: 0;
  top: 4.375rem;
  display: flex;
  align-items: center;
  justify-content: center;

  .bread_content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 100%;
    width: 100%;
    // background: chartreuse;

    .el-breadcrumb {
      font-size: 1.125rem;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #2b2b2b;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-left: 4.375rem;
      width: 40%;
    }
    // .el-breadcrumb__item{
    //     display: flex;
    //     flex-direction: row;
    //     flex-wrap: nowrap;
    //     // width: 100px;
    // }
    .el-breadcrumb :first-child :hover {
      color: #ff6700 !important;
      opacity: 0.8;
    }
    .el-breadcrumb :nth-child(3) :hover {
      // color: #FF6700 !important;
      cursor: pointer;
      color: #ff6700 !important;
      opacity: 0.8;
    }
    ::v-deep.el-breadcrumb__inner.is-link {
      color: #ff6801;
      font-weight: 400;
    }
  }
}
// 新闻主体区域
.box_fix {
  //  height: 1500px;
  width: 100%;
  margin-bottom: 6.25rem;
  //  background: forestgreen;

  .box_flex {
    height: 7.5rem;
    width: 100%;
    //  background: gold;
  }
  .box_content {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    //  height: 300px;

    .tip {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: center;
      width: 50%;
      margin-top: 3.125rem;
      //  background: chartreuse;

      .content {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 100%;
        font-size: 1.5rem;
        font-family: Alibaba PuHuiTi;
        font-weight: 500;
        color: #343434;
        line-height: 3.625rem;
      }
      .time {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 100%;
        font-size: 0.9375rem;
        font-family: Alibaba PuHuiTi;
        font-weight: 500;
        color: #aeadad;
        line-height: 1.875rem;
      }
      .line {
        width: 100%;
        height: 0.125rem;
        background: #bfbfbf;
        margin-top: 1.25rem;
      }
    }
    .content {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: center;
      width: 50%;
      font-size: 1rem;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #1a1a1a;
      line-height: 2.1875rem;
      text-align: left;
      margin-top: 3.125rem;

      .tip {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        margin: 0px;
      }
      .tip p {
        text-indent: 2em;
      }
    }
  }
}
</style>
