<template>
  <div class="content">
    <!-- 1 顶部导航 -->
    <div
      class="pcOnly"
      style="width:100%;height:4.375rem;background:black"
    ></div>
    <!-- 2  面包屑 -->
    <div class="bread" style="color:#ff6801">
      <div class="bread_content">
        <el-breadcrumb separator-class="null">
          <el-breadcrumb-item
            ><span @click="goToIndex">首页</span></el-breadcrumb-item
          >
          <div style="margin-right: 0.625rem">
            <i class="el-icon-arrow-right"></i>
          </div>
          <el-breadcrumb-item
            ><span @click="goToAbout">关于亿猫</span></el-breadcrumb-item
          >
          <div style="margin-right: 0.625rem">
            <i class="el-icon-arrow-right"></i>
          </div>
          <el-breadcrumb-item>新闻动态</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <!-- 3  新闻内容 -->
    <div class="box_fix">
      <!-- <div class="box_flex"></div> -->
      <div class="box_content">
        <!-- 标题 -->
        <div class="tip">
          <!-- 标题内容 -->
          <div class="content">
            科技创新CEO特训营在京开营 --亿猫科技创始人徐步兵先生入选
          </div>
          <!-- 标题时间 -->
          <div class="time">2021-04-21</div>
          <!-- 标题分割线 -->
          <div class="line"></div>
        </div>
        <!-- 内容 -->
        <div class="content">
          <!-- 段落1 -->
          <div class="tip">
            <p>
              乘风破浪会有时，披荆斩棘再读书！2021年4月8日-11日，科技部人才中心第六期科技创新CEO特训营在北京开营。
            </p>
          </div>
          <!-- 段落2 -->
          <div>
            <img
              src="../assets/news03img01.png"
              alt=""
              style="width:23.25rem;"
            />
          </div>
          <div class="smalltip">第六期科技创新CEO特训营北京开营</div>
          <!-- 段落3 -->
          <div class="tip">
            <p>
              经过推荐、资料审查、面试等环节，亿猫科技创始人、董事长徐步兵先生从全国300多名企业家中脱颖而出，成为六期特训营中的一员。
            </p>
          </div>
          <!-- 段落4 -->
          <div>
            <img src="../assets/news03img02.png" alt="" style="width:24rem;" />
          </div>
          <div class="smalltip">亿猫创始人徐步兵先生在特训营</div>
          <!-- 段落5 -->
          <div class="tip">
            <p>
              9日举行的开班仪式上，程家瑜副主任向入选同学颁发了入营通知书，并发表讲话，分析了当前的科技创业环境、科技部主办训练营的初衷，并对学员们的学习提出了要求。据其介绍，训练营是为深入贯彻创新驱动实质是人才驱动的理念，通过大量调研评估人才计划中创业人才入选者的成长历程和发展困境，综合利用国家科技人才专家数据库近十万人的高层次科技人力资源优势，优选国内外知名商学院教授、成功企业家、资深金融投资人和专业领域专家、政策专家等作为师资，推出的科技创新创业人才能力提升加速项目。从2014年起，每年从全国遴选50位左右科技创新型企业家进行集中培训，旨在培养中国科技创新创业企业领袖，孵化卓越创新型中国科技企业。
            </p>
          </div>
          <!-- 段落6 -->
          <div>
            <img src="../assets/news03img03.png" alt="" style="width:22rem;" />
          </div>
          <div class="smalltip">“大航海”沙盘推演</div>
          <!-- 段落7 -->
          <div class="tip">
            <p>
              据悉，能够入选特训营，主要基于入营者所在企业的创新性和前瞻性，特别是企业家个人的素养。亿猫科技创始人、CEO徐步兵先生带领团队专注于商超智能+场景数字化营销平台的研发与运营，共获得28项发明、实用新型专利，自研反欺诈识别AI技术、软硬件一体化的智能购物车+数据采集+精准营销的整体解决方案，帮助超市数智化升级，帮助品牌商数字营销及场景触达。徐步兵先生入选2020年-2023年南京市“创新型企业家培育计划”人才、2021年国家科技部人才中心CEO特训班6期人才。公司入选2019年阿里巴巴AI赛道明星班3期企业、南京市2020年瞪羚企业、2021年阿里云加速器创新时辰“星光奖”！公司与华润万家、杭州世纪联华等大型连锁超市及互联网巨头达成深度合作！不负韶光，展鸿鹄之志，力争5年内国内部署超过100万台，所部署门店智能购物车支付结算占比超过70%，成为线下领先的流量分发平台。亿猫科技的目标是让超市更智能，营销更精准！解放一代收银促销员，幸福一代消费者！
            </p>
          </div>
          <!-- 段落8 -->
          <div>
            <img
              src="../assets/news03img04.png"
              alt=""
              style="width:27.125rem;"
            />
          </div>
          <div class="smalltip">亿猫创始人徐步兵先生在介绍智能购物车</div>
          <!-- 段落9 -->
          <div class="tip">
            <p>
              科技部人才中心是科学技术部直属事业单位，以培养和服务科技人才为中心任务，实施国家创新人才推进计划等重大人才工程，推动体制机制和政策制度创新，开展面向全社会科技创新创业人才的培养、咨询等服务工作，是国家推进科技创新创业人才队伍建设的重要组织管理平台、调查研究平台、信息分析平台、支撑服务平台。从2014年底至今，科技部人才中心科技创新CEO训练营已经启动六期，结业五期，共入营学员281名，在培训期间，并已经有超过12家企业实现IPO上市，80多家企业成为了所在领域的独角兽和小巨人企业，成为全国创新能力强、活力足的新型科技企业。
            </p>
          </div>
        </div>
      </div>
      <!-- <div class="box_flex" style="background:#000"></div>
            <div class="box_flex"></div>
            <div class="box_flex" style="background:#000"></div> -->
    </div>
    <!--  4   脚步 -->
    <footers></footers>
  </div>
</template>
<script>
import footers from "../views/footer";
export default {
  components: {
    footers
  },
  data() {
    return {};
  },
  methods: {
    goToAbout() {
      console.log("22");
      this.$router.push("/about");
    },
    goToIndex() {
      console.log("332");
      this.$router.push("/indextab");
    }
  }
};
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  background: #fff;
  color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  margin: 0;
}
// 新闻导航栏导航栏样式
.black {
  position: fixed;
  background: #000;
  width: 100%;
  height: 4.375rem;
  z-index: 999;
  left: 0;
  right: 0;
  top: 0px;

  .el-menu-demo {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 1.125rem;
    width: 100%;
  }
  ::v-deep.el-icon-arrow-down:before {
    content: "";
  }
  .el-menu-item {
    font-size: 1.125rem;
    font-family: Source Han Sans CN;
    font-weight: 500;
    height: 4.375rem !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ::v-deep.el-menu--horizontal > .el-submenu .el-submenu__title:hover {
    background-color: rgba(255, 255, 255, 0) !important;
    // color: #ff6801 !important;
    border-bottom-color: #ff6801 !important;
    // border-bottom-color: #fff !important;
  }
  ::v-deep.el-menu--horizontal
    > .el-submenu
    .el-submenu__title
    > .el-icon-arrow-down:before {
    // content: "";
  }
  ::v-deep.el-menu--horizontal
    > .el-submenu.el-submenu.is-active
    > .el-submenu__title {
    border-bottom-color: #ff6801 !important;
    background-color: rgba(255, 255, 255, 0) !important;
  }
  ::v-deep.el-menu--horizontal > .el-submenu.el-submenu.is-active.is-opened {
    // border-bottom-color: #ff6801 !important;
    background-color: rgba(255, 255, 255, 0) !important;
  }
  ::v-deep.el-menu--horizontal
    > .el-submenu.el-submenu.is-opened
    .el-submenu__title {
    background-color: rgba(255, 255, 255, 0) !important;
    // border-bottom-color: #ff6801 !important;
  }
  // ::v-deep.el-menu--horizontal>.el-submenu.el-submenu.is-active{
  //     background-color: rgba(255,255,255,0) !important;
  // }
  ::v-deep.el-menu--horizontal > .el-submenu .el-submenu__title {
    margin-left: 0.875rem !important;
    height: 4.375rem !important;
    display: flex;
    justify-content: center;
    align-items: center;
    // margin-right: -10px;
    padding-right: 0px !important;
    // border-bottom-color: rgba(255,255,255,0.3) !important;
  }
  .el-menu--horizontal > .el-menu-item {
    // width:6.25rem !important;
    margin-left: 0.75rem !important;
    border-bottom-color: rgba(255, 255, 255, 0) !important;
  }
  ::v-deep.el-menu--horizontal > .el-submenu .el-submenu__title {
    font-size: 1.125rem;
    background-color: rgba(255, 255, 255, 0) !important;
    font-family: Source Han Sans CN;
    font-weight: 500;
  }
  // ::v-deep.el-menu--horizontal>.el-submenu .el-submenu__title.is-active{
  //   background: #ff6801;
  // }
  // ::v-deep.el-menu--horizontal>.el-submenu.is-active.is-opened>.el-submenu__title>.el-menu--horizontal{
  //     top: 60px !important;

  // }
  .el-menu {
    background-color: rgba(255, 255, 255, 0) !important;
    border-color: rgba(255, 255, 255, 0) !important;
    border-bottom-color: rgba(255, 255, 255, 0) !important;
  }
  .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
  .el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
  .el-menu--horizontal > .el-submenu .el-submenu__title:hover {
    background-color: rgba(255, 255, 255, 0) !important;
    border-color: rgba(255, 255, 255, 0) !important;
    border-bottom-color: #ff6801 !important;
  }
  .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus {
    background-color: rgba(255, 255, 255, 0) !important;
    border-color: rgba(255, 255, 255, 0) !important;
    border-bottom-color: #ff6801 !important;
  }
  .el-menu-item.is-active {
    border-color: #ff6801 !important;
    border-bottom-color: #ff6801 !important;
  }
  .el-submenu.is-active .el-submenu__title {
    border-bottom-color: #ff6801 !important;
    background-color: rgba(255, 255, 255, 0) !important;
  }
  .el-menu.el-menu--horizontal {
    border-bottom: 0.125rem !important;
    // background-color: rgba(255,255,255,0) !important;
  }
}
.black .box {
  width: 100%;
  height: 4.375rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;
}
.black .box .logo {
  width: 23%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  //  background: chocolate;
}
.black .box .logo :first-child {
  width: 7.625rem;
  height: 2.5625rem;
}
.black .box .logo img {
  width: 100%;
  //  height: 100%;
}
.navigationBar_box {
  width: 57%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  overflow: hidden;
}
.black .box .phone {
  display: flex;
  justify-content: flex-start;
  height: 100%;
  width: 20%;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  padding-left: 2.1875rem;
}
.phone_box {
  width: 100%;
  height: 4.375rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.phone_boxmini001 {
  // position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.1875rem;
  height: 1.5rem;
  margin: 0 0 0 0.9375rem;

  img {
    width: 100%;
    //  height: 100%;
  }
}
.phone_boxmini002 {
  width: 80%;
  height: 100%;
  font-size: 1.125rem;
  font-family: Source Han Sans CN;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-left: 0.3125rem;
  font-weight: 500;
  line-height: 1.625rem;
  overflow: hidden;
}
// 面包屑
.bread {
  background: #f7f7f7;
  // position: fixed;
  color: #000;
  width: 100%;
  height: 3.125rem;
  //  z-index: 999;
  left: 0;
  right: 0;
  top: 4.375rem;
  display: flex;
  align-items: center;
  justify-content: center;

  .bread_content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 100%;
    width: 100%;
    // background: chartreuse;

    .el-breadcrumb {
      font-size: 1.125rem;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #2b2b2b;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-left: 4.375rem;
      width: 40%;
    }
    // .el-breadcrumb__item{
    //     display: flex;
    //     flex-direction: row;
    //     flex-wrap: nowrap;
    //     // width: 100px;
    // }
    .el-breadcrumb :first-child :hover {
      color: #ff6700 !important;
      opacity: 0.8;
    }
    .el-breadcrumb :nth-child(3) :hover {
      // color: #FF6700 !important;
      cursor: pointer;
      color: #ff6700 !important;
      opacity: 0.8;
    }
    ::v-deep.el-breadcrumb__inner.is-link {
      color: #ff6801;
      font-weight: 400;
    }
  }
}
// 新闻主体区域
.box_fix {
  //  height: 1500px;
  width: 100%;
  margin-bottom: 6.25rem;
  //  background: forestgreen;

  .box_flex {
    height: 7.5rem;
    width: 100%;
    //  background: gold;
  }
  .box_content {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    //  height: 300px;

    .tip {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: center;
      width: 50%;
      margin-top: 3.125rem;
      //  background: chartreuse;

      .content {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 100%;
        font-size: 1.5rem;
        font-family: Alibaba PuHuiTi;
        font-weight: 500;
        color: #343434;
        line-height: 3.625rem;
      }
      .time {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 100%;
        font-size: 0.9375rem;
        font-family: Alibaba PuHuiTi;
        font-weight: 500;
        color: #aeadad;
        line-height: 1.875rem;
      }
      .line {
        width: 100%;
        height: 0.125rem;
        background: #bfbfbf;
        margin-top: 1.25rem;
      }
    }
    .content {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: center;
      width: 50%;
      font-size: 1rem;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #1a1a1a;
      line-height: 2.1875rem;
      text-align: left;
      margin-top: 3.125rem;

      .tip {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        align-items: center;
        margin-top: 0px;
      }
      .smalltip {
        font-size: 0.75rem;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #1a1a1a;
        line-height: 0.9375rem;
        opacity: 0.7;
      }
    }
  }
}
</style>
