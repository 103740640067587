<template>
  <div id="App">
    <!-- 采用element的Container 布局容器 -->
    <el-container class="web_container" direction="vertical">
      <!-- 头部放导航栏 -->
      <el-header>
        <!-- !-- 顶部导航 -->
        <!-- 这里的style是滑动改变顶部样式 -->
        <div
          v-if="this.tabImportent == false"
          class="tab_box_father"
          id="tab_hover"
          @mouseenter="enter"
          @mouseleave="leave"
          :style="headStyle"
          :class="{ colors: colors }"
          ref="bbox"
        >
          <!-- 重写导航栏 -->
          <!-- 先用一个大盒子包住 -->
          <div class="box">
            <!-- 然后分成三块 -->
            <!-- 第一块是黄色logo -->
            <div class="logo">
              <div class="pic">
                <img v-show="this.srcs !== 1" src="../assets/logoyellow.png" />
                <img v-show="this.srcs == 1" src="../assets/logowhite.png" />
              </div>
            </div>
            <!-- 第二块是导航栏 -->
            <div class="navigationBar_box">
              <el-menu
                class="el-menu-demo"
                :default-active="activeIndex"
                @select="selectmenu"
                mode="horizontal"
                router
                :active-text-color="change !== 1 ? '#fff' : '#000'"
                :text-color="change == 1 ? '#000' : '#fff'"
              >
                <el-menu-item index="indextab">首&nbsp;&nbsp;页</el-menu-item>
                <el-submenu index="2" :hide-timeout="num">
                  <template slot="title">解决方案</template>
                  <div
                    style="margin-top:-0.625rem;"
                    ref="badGuy"
                    @mouseenter="enterPop"
                    @mouseleave="leavePop"
                  >
                    <el-menu-item
                      index="paysolve"
                      style="color:#000;font-size:1rem;font-weight:400;height:3.5625rem; display:flex;justify-content:center;align-items:center;flex-direction:row;margin:0 auto"
                      >支付数字化解决方案</el-menu-item
                    >
                    <el-menu-item
                      index="solve"
                      style="color:#000;font-size:1rem;font-weight:400;height:3.5625rem; display:flex;justify-content:center;align-items:center;flex-direction:row;margin:0 auto;"
                      >营销数字化解决方案</el-menu-item
                    >
                  </div>
                </el-submenu>
                <el-menu-item index="service">服务支持</el-menu-item>
                <el-menu-item index="about">关于亿猫</el-menu-item>
                <el-menu-item index="downloadpage"
                  >下&nbsp;&nbsp;载</el-menu-item
                >
              </el-menu>
            </div>
            <!-- 第三块是右侧的电话图标和号码 -->
            <div class="phone" ref="imgBox">
              <div class="phone_box">
                <div class="phone_boxmini001" v-show="this.srcs == 1">
                  <img src="../assets/indexphonewhite.png" />
                </div>
                <div class="phone_boxmini001" v-show="this.srcs !== 1">
                  <img src="../assets/indexphoneblack.png" />
                </div>
                <!-- <div style="font-size:20px;"><i class="el-icon-phone-outline"></i></div> -->
                <div class="phone_boxmini002">
                  <div>025</div>
                  <div>-</div>
                  <div>66044128</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="this.tabImportent == true" class="mobileStyle">
          <!-- 左侧logo -->
          <div style="margin-left:5%">
            <div style=" width: 6.6875rem;margin-right:0.625rem;">
              <img src="../assets/logoyellow.png" style="width:100%" />
            </div>
          </div>
          <!-- 右侧导航栏 -->
          <div style="margin-right:5%">
            <div style="font-size:1.5625rem">
              <i
                @click="showMobileNav = !showMobileNav"
                class="iconfont icon-category"
                v-if="!showMobileNav"
                style="font-size:3rem;color:#868686"
              ></i>
              <i
                v-if="showMobileNav"
                @click="showMobileNav = !showMobileNav"
                class="iconfont icon-close"
                style="font-size:3rem;color:#868686"
              ></i>

              <el-menu
                :default-active="activeIndex"
                @select="handleSelectMobileNav"
                class="el-menu-vertical-demo mobile-menu"
                router
                v-show="showMobileNav"
                :active-text-color="'#ff6801'"
              >
                <el-menu-item index="indextab">首页</el-menu-item>
                <!-- <el-menu-item index="paysolve">支付数字化解决方案</el-menu-item> -->
                <!-- <el-menu-item index="solve">营销数字化解决方案</el-menu-item> -->
                <!-- <el-menu-item index="service">服务支持</el-menu-item> -->
                <el-menu-item index="about">关于亿猫</el-menu-item>
                <!-- <el-menu-item index="downloadpage">下载</el-menu-item> -->
              </el-menu>
            </div>
          </div>
        </div>
      </el-header>
      <!-- 内容主体区域 -->
      <el-main>
        <!-- 路由占位 -->
        <router-view> </router-view>
        <!-- 右侧悬浮按钮 -->
        <div v-show="this.smallBtn == true" class="Right_box pcOnly">
          <el-popover
            placement="left-end"
            width="300"
            trigger="hover"
            visible-arrow="false"
          >
            <!--弹出框popover  内容content-->
            <div style="width:100%">
              <div class="popoverContentBox">
                <!-- 第一层 -->
                <div class="item">
                  <div class="pic">
                    <img src="../assets/popoicon01.png" alt="" />
                  </div>
                  <div class="txt">
                    <div>售前咨询</div>
                    <div>025-66044128</div>
                  </div>
                </div>
                <!-- 第二层 -->
                <div class="item">
                  <div class="pic" style="width:1.8125rem;height:1.3125rem">
                    <img src="../assets/popoicon02.png" alt="" />
                  </div>
                  <div class="txt">
                    <div>公司邮箱</div>
                    <div>service@yimaogou.com</div>
                  </div>
                </div>
                <!-- 第三层 -->
                <div class="item02">
                  <div class="pic">
                    <img src="../assets/popoicon03.png" alt="" />
                  </div>
                  <div class="txt">
                    <div>公众号二维码</div>
                    <div>
                      <img src="../assets/QRcode.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 按钮 -->
            <div class="chat_box" slot="reference">
              <div class="box">
                <img src="../assets/backChat.png" />
              </div>
            </div>
          </el-popover>
          <div class="back_box" @click="backTestTop">
            <div class="box">
              <img src="../assets/backTopicon.png" />
            </div>
          </div>
        </div>
        <!-- 2222 -->
        <!-- <el-backtop style="bottom:50%">
    <div class="backTopBox">
      <div class="box"><img src="./assets/backTopicon.png" ></div>
    </div>
  </el-backtop> -->
      </el-main>
      <!-- 官网的脚部区域 -->
    </el-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tabImportent: "",
      isCollapse: true,
      showMobileNav: false,
      num: 0,
      screenWidth: document.body.clientWidth,
      spacl: "",
      ppp: "",
      activeIndex: "",
      // screenWidth: document.body.clientWidth,111
      // 轮播图图片
      pics: [
        { url: require("../assets/swiper02.jpg") },
        { url: require("../assets/swiper0001.png") },
        { url: require("../assets/swiper03.jpg") }
      ],
      //  页面导航栏样式
      headStyle: {
        background: "rgba(0, 0, 0, 0)",
        color: "rgba(255, 255, 255, 1)"
      },
      // defaultActivePath:'',
      // activerouter: "indextab",
      // 右侧介绍按钮
      // weer: '/indextab',
      smallBtn: false,
      srcs: 1,
      colors: false,
      change: 0
      // defaultHeight: {
      //   top: ""
      // }
    };
  },
  mounted() {
    if (navigator.userAgent.match(/(iPhone|iPod|Android|ios)/i)) {
      // console.log('移动端')
      this.tabImportent = true;
      const tabhover = document.getElementById("tab_hover");
      tabhover.style.position = "relative";
      // tabhover.className = "mob-nav-style";
    } else {
      // console.log('PC端')
      this.tabImportent = false;
      const bbox = this.$refs.bbox;
      const Bbox = bbox.getBoundingClientRect().width;
      // console.log("1234")
      // console.log(Bbox)
      if (Bbox < 950) {
        this.tabImportent = false;
        const tabhover = document.getElementById("tab_hover");
        // console.log(tabhover.style)
        tabhover.style.position = "relative";
        // tabhover.className = "mob-nav-style";
        // console.log("换样式")
      } else {
        this.tabImportent = false;
        // console.log("原来版本")
      }
    }
    window.addEventListener("scroll", this.handleScroll);
  },
  onload: {},
  methods: {
    handleSelectMobileNav() {
      this.showMobileNav = false;
    },
    selectmenu(index, indexPath) {
      this.activeIndex = index;
    },
    // 回到顶部按钮
    backTestTop() {
      var timer = setInterval(function() {
        const osTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        const ispeed = Math.floor(-osTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          osTop + ispeed;
        this.isTop = true;
        if (osTop === 0) {
          clearInterval(timer);
        }
      }, 30);
    },
    enter: function(e) {
      // var that = this;
      // console.log(e.path[0].id)
      this.change = 1;
      var tabhover = document.getElementById("tab_hover");
      tabhover.style.background = "rgba(255, 255, 255, 1)";
      tabhover.style.color = `rgba(0, 0, 0, 1)`;
      tabhover.className = "tabenter";
      this.srcs = 2;
      this.colors = true;
      console.log(11);
    },
    leave: function(e) {
      console.log(22);
      // console.log(e)
      if (this.spacl === true) {
        // console.log("9090")
      } else {
        // console.log("123")
        var tabhover = document.getElementById("tab_hover");
        tabhover.style.background = "rgba(0, 0, 0, 0)";
        tabhover.style.color = "#FFFFFF";
        this.change = 0;
        this.srcs = 1;
        this.colors = false;
      }
    },
    enterPop: function(e) {
      console.log(33);
      // console.log(e)
      this.change = 1;
      var tabhover = document.getElementById("tab_hover");
      // console.log(tabhover)
      tabhover.style.background = "rgba(255, 255, 255, 1)";
      tabhover.style.color = `rgba(0, 0, 0, 1)`;
      this.srcs = 2;
      this.colors = true;
    },
    leavePop: function(e) {
      console.log(44);
      // console.log(e)
      if (this.spacl === true) {
        // console.log("9090")
      } else {
        // console.log("123")
        var tabhover = document.getElementById("tab_hover");
        tabhover.style.background = "rgba(0, 0, 0, 0)";
        tabhover.style.color = "#FFFFFF";
        this.change = 0;
        this.srcs = 1;
        this.colors = false;
      }
    },
    // 页面移动导航栏改变颜色
    handleScroll() {
      var that = this;
      that.change = 1;
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // console.log(scrollTop);
      console.log();
      if (scrollTop) {
        that.headStyle.background = "rgba(255, 255, 255, 1)";
        that.headStyle.color = `rgba(0, 0, 0, 1)`;
        // that.headStylea.background = `rgba(32,104,255,0.8})`;
        // that.headStylea.color = `rgba(255, 255, 255,0.5})`;
        // var tabhover = document.getElementById('tab_hover')
        // console.log(tabhover)
        // tabhover.style.position = "sticky";
        if (scrollTop >= 200) {
          that.smallBtn = true;
          // console.log(1);
        } else {
          that.smallBtn = false;
        }
        that.srcs = 2;
        that.spacl = true;
        that.colors = true;
      } else if (scrollTop === 0 || that.spacl === true) {
        that.headStyle.background = "rgba(0, 0, 0, 0)";
        that.change = 0;
        that.smallBtn = false;
        that.spacl = false;
        that.headStyle.color = "#FFFFFF";
        // that.headStylea.background = `rgba(255, 255, 255, 1)`;
        // that.headStylea.color = `rgba(32,104,255, 1)`;
        that.srcs = 1;
        that.colors = false;
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  // 定义方法，获取高度减去头尾
  // getHeight () {
  //   this.defaultHeight.top = window.innerHeight + "px";
  // },
  handleselect: function(a, b) {
    this.reload();
    // 点击侧边栏重新载入页面
  }
};
</script>
<style lang="less" scoped>
html,
body {
  padding: 0px !important;
  width: 120rem !important;
  height: 100% !important;
  margin: 0px;
  min-width: 120rem;
  max-width: 100%;
  // font-size: 16px;
}
#App {
  // position: fixed;
  // display: flex;
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: 0px;
  position: absolute;
  top: 0;
  left: 0;
}
.el-container {
  flex-direction: column !important;
  width: 100%;
  height: auto;
  padding: 0px;
  margin: 0px;
  display: flex !important;
  justify-content: flex-start !important;
  flex-direction: column !important;
  align-content: center !important;
  position: relative !important;
}
::v-deep .el-carousel__container {
  height: 100% !important;
}
.el-container.web_container.is-vertical {
  height: 100% !important;
  padding: 0px !important;
  margin: 0px !important;
  width: 100% !important;
  flex-direction: column !important;
  display: flex !important;
  justify-content: flex-start !important;
  flex-direction: column !important;
  align-content: center !important;
}
.web_container {
  height: auto !important;
  padding: 0px !important;
  margin: 0px !important;
  width: 100% !important;
  position: relative !important;
}
/* 头部 */
.el-header {
  color: #333;
  text-align: center;
  // line-height: 3.75rem;
  display: flex;
  width: 100%;
  // height: 4.375rem !important;
  // height: 10vh;
  height: 4.375rem;
  // flex-direction: column;
  padding: 0px !important;
  position: relative;
}
.tab_box_father {
  position: fixed;
  width: 100%;
  //  height: 4.375rem;
  // height: 10vh;
  height: 4.375rem;
  z-index: 999;
  left: 0;
  right: 0;
  top: 0;
}
// .tab_box_father > :hover {
//   background:rgba(255, 255, 255, 1);
//   color :rgba(0, 0, 0, 1);
// }
// .tab_box_father > :hover .el-menu-item{
//   color: #333 !important;
// }
// .tab_box_father > :hover ::v-deep.el-menu--horizontal>.el-submenu .el-submenu__title{
//   color: #333 !important;
// }
.tab_box_father .box {
  width: 100%;
  //  height: 4.375rem;
  //  height: 10vh;
  height: 4.375rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;
  //  background: chartreuse;
}
//  .el-menu-demo  :hover{
//    color: #000;
//    background: #fff;
//   //  background: chartreuse;
//  }
.tab_box_father .box .logo {
  width: 23%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
  //  position: relative;
  //  background: chartreuse;
  //  background: chocolate;
}
.tab_box_father .box .logo .pic {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
  margin: 0 auto;
  //  margin: 0 auto;
  //  width: 7.625rem;
  // width: 35%;
  // height: 100%;
  width: 100%;
  height: 100%;
  //  height: 2.5625rem;
  // height: 50%;
}
.tab_box_father .box .logo img {
  width: 6.6875rem;
  //  position: absolute;
  //  top: 0%;
  //  left: 0%;
  //  height: 100%;
}
.navigationBar_box {
  width: 55%;
  //  background: chartreuse;
  //  height: 100%;
  //  background: cornflowerblue;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  position: relative;
  overflow: hidden;
}
/* 顶部导航 */
.el-menu-demo {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  background: #ff6801;
  // position: absolute;
  // right: 0px;
  // font-size: 10vh;
  font-size: 1rem;
  // width: 37.5rem;
  // height: 10vh;
  height: 4.375rem;
}
::v-deep.el-icon-arrow-down:before {
  content: "";
}
.el-menu-item {
  // font-size: 1.125rem;
  // font-size: 2vh;
  font-size: 1rem !important;
  font-family: Source Han Sans CN;
  font-weight: 500;
  // width: 6.875rem !important;
  height: 100%;
  // background: chartreuse;
  // background: forestgreen;
  // height:4.375rem !important;
  padding: 0 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep.el-menu--popup-bottom-start .el-submenu > .el-submenu__title {
  color: #333333 !important;
  font-size: 0.75rem !important;
  background: chartreuse !important;
}
::v-deep.el-menu--horizontal > .el-submenu .el-submenu__title:hover {
  background-color: rgba(255, 255, 255, 0) !important;
  // color: #ff6801 !important;
  // border-bottom-color: #ff6801 !important;
}
::v-deep.el-menu--horizontal
  > .el-submenu
  .el-submenu__title
  > .el-icon-arrow-down:before {
  // content: "\e6df";
  content: "";
  // width: calc(5 / 1080 *100vh);
  // // background: chartreuse;
  // margin: calc(1 / 1080 * 100vh);
}
::v-deep.el-menu--horizontal
  > .el-submenu.el-submenu.is-active
  > .el-submenu__title {
  border-bottom-color: #ff6801 !important;
  background-color: rgba(255, 255, 255, 0) !important;
}
::v-deep.el-menu--horizontal > .el-submenu.el-submenu.is-active.is-opened {
  // border-bottom-color: #ff6801 !important;
  background-color: rgba(255, 255, 255, 0) !important;
}
// ::v-deep.el-menu--horizontal>.el-submenu.el-submenu.is-active.is-opened .el-submenu__title{
//     border-bottom-color: #ff6801 !important;
//     background-color: rgba(255,255,255,0) !important;
// }
::v-deep.el-menu--horizontal
  > .el-submenu.el-submenu.is-opened
  .el-submenu__title {
  background-color: rgba(255, 255, 255, 0) !important;
  // border-bottom-color: #ff6801 !important;
}
// ::v-deep.el-menu--horizontal>.el-submenu.el-submenu.is-active{
//     background-color: rgba(255,255,255,0) !important;
// }
::v-deep.el-menu--horizontal > .el-submenu .el-submenu__title {
  margin-left: 0.75rem !important;
  // height: 4.375rem !important;
  height: 4.375rem !important;
  width: 6.875rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
  // background: forestgreen;
  // margin-right: -10px;
  padding: 0px !important;
  // border-bottom-color: rgba(255,255,255,0.3) !important;
}
.el-menu--horizontal > .el-menu-item {
  // width:6.25rem !important;
  margin-left: 0.75rem !important;
  // border-bottom-color: rgba(255,255,255,0) !important;55
}
::v-deep.el-menu--horizontal > .opopop {
  background: chartreuse !important;
  width: 500px !important;
}

::v-deep.el-menu--horizontal > .el-submenu .el-submenu__title {
  // font-size: 1.125rem;
  font-size: 1rem !important;
  background-color: rgba(255, 255, 255, 0) !important;
  font-family: Source Han Sans CN;
  font-weight: 500;
  width: 6.875rem !important;
  padding: 0 0 0 0.4375rem !important;
  // margin: 0 auto;
  // height:3.5625rem;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  // width: 90%;
  // background: forestgreen !important;
  // border-bottom-color: #ff6801 !important;
}
// ::v-deep.el-menu--horizontal>.el-submenu .el-submenu__title.is-active{
//   background: #ff6801;
// }
// ::v-deep.el-menu--horizontal>.el-submenu.is-active.is-opened>.el-submenu__title>.el-menu--horizontal{
//     top: 60px !important;

// }
.el-menu {
  background-color: rgba(255, 255, 255, 0) !important;
  // border-color: rgba(255,255,255,0) !important;33
  // border-bottom-color: rgba(255,255,255,0) !important;44
}
.el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
.el-menu--horizontal > .el-submenu .el-submenu__title:hover {
  background-color: rgba(255, 255, 255, 0) !important;
  // border-color: rgba(255,255,255,0) !important;11
  // border-bottom-color: #ff6801 !important;
}
.el-menu--horizontal > .el-menu-item:not(.is-disabled):focus {
  background-color: rgba(255, 255, 255, 0) !important;
  // border-color: rgba(255,255,255,0) !important;22
  border-bottom-color: #ff6801 !important;
}
.el-menu-item.is-active {
  border-color: #ff6801 !important;
  border-bottom-color: #ff6801 !important;
}
::v-deep.el-submenu.is-active .el-submenu__title {
  border-bottom-color: #ff6801 !important;
  background-color: rgba(255, 255, 255, 0) !important;
}
.el-menu.el-menu--horizontal {
  border-bottom: 0.125rem !important;
  // background-color: rgba(255,255,255,0) !important;
}
::v-deep.el-carousel__item {
  width: 1.25rem !important;
  height: 1.25rem !important;
}
.tab_box_father .box .phone {
  display: flex;
  justify-content: flex-start;
  height: 100%;
  width: 20%;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  // padding-left: 35px;
  padding-left: 2.1875rem;
}
.phone_box {
  width: 100%;
  // height: 4.375rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.phone_boxmini001 {
  // position: relative;
  // top: -0.625rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.375rem;
  // height: 1.5rem;
  margin: 0 0 0 0.9375rem;
  // background-image: url(../assets/indexphonewhite.png);
  // background-size: 100% ;
  // background-repeat: no-repeat;

  img {
    width: 100%;
    //  height: 100%;
  }
}
.phone_boxmini002 {
  width: 80%;
  height: 100%;
  // font-size: 1.125rem;
  font-size: 1rem;
  font-family: Source Han Sans CN;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-left: 0.3125rem;
  font-weight: 500;
  line-height: 1.625rem;
  // overflow: hidden;
}

/* 主体内容区域 */
.el-main {
  background-color: #e9eef3;
  color: #333;
  text-align: center;
  padding: 0px !important;
  // 这样内容就不会裁剪
  overflow: visible !important;
  // 可以加绝对定位
  width: 100%;
  padding: 0;
  position: absolute;
  top: 0;
}
/* 脚步 */
.el-footer {
  background-color: #8a8a8a;
  height: 44.375rem !important;
  color: #8a8a8a !important;
  width: 100%;
  text-align: center;
  align-items: center;
  padding: 0px !important;
  // position:fixed;
  // bottom: 0;
  // z-index: -99;
  // position: absolute;
  // bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.footer1 {
  background-color: #8a8a8a;
  height: 44.375rem !important;
  color: #8a8a8a !important;
  width: 100%;
  text-align: center;
  align-items: center;
  padding: 0px !important;
  // position:fixed;
  // bottom: 0;
  // z-index: -99;
  // position: absolute;
  // bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.footer_top {
  width: 93.75rem;
  height: 6.25rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-top: 3.125rem;
  border-bottom: 1.3px solid #c7c9d1;
}
.footer_top_icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 1.4375rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #dfe1e8;
}
.footer_center {
  width: 93.75rem;
  height: 15.625rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 0.0813rem solid #c7c9d1;
  padding: 0px 0 1.25rem 0;
}
.footer_center_left {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0;
}
.footer_center_left_title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-size: 1.875rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  line-height: 1.875rem;
}
.footer_t,
.footer_e,
.footer_a {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  color: #caccd0;
  font-size: 1.5625rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 2.375rem;
}
.footer_center_left_cont {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.footer_center_right img {
  width: 10.8125rem;
  height: 10.8125rem;
  margin-top: 2.5rem;
}
.footer_bottom {
  width: 93.75rem;
  height: 7.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 0 1.25rem 0;
}
.footer_bottom_left,
.footer_bottom_right {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-size: 1.4375rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #caccd0;
  line-height: 2.375rem;
}
.footer_bottom_left_one,
.footer_bottom_left_two {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
// 右侧悬浮大盒子
.Right_box {
  position: fixed;
  top: 35%;
  right: 1%;
  color: #ff6801;
  display: flex;
  height: 12.5rem;
  //  width: 3%;
  //  background: forestgreen;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  z-index: 8888;
}
.chat_box {
  background-color: #fff;
  color: #ff6801;
  width: 3.1875rem;
  height: 3.1875rem;
  border-radius: 0%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0.1875rem 0.25rem 0px rgba(102, 102, 102, 0.46);
  margin-bottom: 1.25rem;

  .box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.0625rem;
    height: 1.75rem;

    img {
      width: 100%;
      height: 100%;
    }
  }
}
// back_box右侧固定按钮
.back_box {
  background-color: #fff;
  color: #ff6801;
  width: 3.1875rem;
  height: 3.1875rem;
  border-radius: 0%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0.1875rem 0.25rem 0px rgba(102, 102, 102, 0.46);

  .box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 1.125rem;

    img {
      width: 100%;
      height: 100%;
    }
  }
}
// popover 内容样式
.popoverContentBox {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  .item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    width: 100%;
    height: 3.125rem;
    // background: chocolate;
    margin-left: 2.1875rem;
    margin-bottom: 1.1875rem;

    .pic {
      width: 1.8125rem;
      height: 1.8125rem;
      margin-right: 0.625rem;

      img {
        width: 100%;
        height: 100%;
      }
    }
    .txt {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-between;
      // background: chartreuse;
      height: 100%;

      :first-child {
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        font-size: 1.125rem;
        font-family: Alibaba PuHuiTi;
        font-weight: 500;
        color: #343434;
      }
      :last-child {
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        font-size: 0.875rem;
        font-family: Alibaba PuHuiTi;
        font-weight: 400;
        color: #989797;
      }
    }

    .txt :hover {
      color: chocolate;
      cursor: pointer;
    }
  }
  // item02
  .item02 {
    display: flex;
    justify-content: flex-start;
    // align-items: center;
    flex-direction: row;
    width: 100%;
    margin-left: 2.1875rem;

    .pic {
      width: 2.125rem;
      height: 1.75rem;
      margin-right: 0.625rem;
      margin-top: 0.9375rem;

      img {
        width: 100%;
        height: 100%;
      }
    }
    .txt {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-between;

      :first-child {
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        font-size: 1.125rem;
        font-family: Alibaba PuHuiTi;
        font-weight: 500;
        color: #343434;
      }
      :last-child {
        display: flex;
        width: 5.875rem;
        height: 5.875rem;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin-left: -0.3125rem;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .txt :hover {
      color: chocolate;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 767px) {
  .el-header {
    height: 50px !important;
  }
  .el-main {
    position: relative !important;
  }

  .mobileStyle {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 9999;
    background: #fff;

    .el-menu-vertical-demo:not(.el-menu--collapse) {
      width: 12.5rem;
      min-height: 5rem;
    }
    ::v-deep.el-menu {
      border: 1px solid #eee;
      top: 50px;
      > li {
        border-bottom: 1px solid #eee;
        font-size: 1.4rem !important;
      }
    }
    ::v-deep.el-submenu__title {
      border-right: 0px !important;
      height: 4.375rem !important;
      line-height: 4.375rem !important;
    }

    .mobile-menu {
      position: absolute;
      right: 0;
      width: 45vw !important;
      background: #fff !important;
    }
  }
}
</style>
<style>
@media screen and (max-width: 767px) {
  .mobOnly {
    display: block;
  }
  .pcOnly {
    display: none !important;
  }
}

@media screen and (min-width: 768px) {
  .mobOnly {
    display: none !important;
  }
  .pcOnly {
    display: block;
  }
}
</style>
