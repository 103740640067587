import Vue from 'vue'
import App from './App.vue'
import store from './store'
import './plugins/element.js'
import BaiduMap from 'vue-baidu-map'
import Video from 'video.js'
import 'video.js/dist/video-js.css'
import router from './router/router'
import 'babel-polyfill'
import '@/lib-flexible/flexible.js'
import '@/assets/iconfont/iconfont.css'

Vue.config.productionTip = false
Vue.prototype.$video = Video
Vue.use(BaiduMap, { ak: 'GpMGeFbkDBQU7Cr6mQzWU6urfwRVWfNO' })
// 跳转定位
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
