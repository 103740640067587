<template>
    <div class="paysolvebox">
<!-- 1  顶部banner -->
        <div class="banner">
            <img src="../assets/paysolvebanner.png" alt="" style="width:100%">
        <!-- <el-image style="width:100%" :src="require('../assets/paysolvebanner.png')" fit="scale-down"></el-image> -->
        </div>
<!-- 2  面包屑 -->
        <!-- <div class="breadbox">
            <div style="width:22px;height:31px;margin-left:100px"><img src="../assets/addressicon.png" style="width:100%;height:100%"></div>
            <div class="breadtxt">当前所在位置:</div> -->
            <!-- 面包 -->
            <!-- <div>
                <el-breadcrumb separator-class="null">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <div style="margin-right: 10px"><i class="el-icon-d-arrow-right"></i></div>
                <el-breadcrumb-item :to="{ path: '/paysolve'}">解决方案</el-breadcrumb-item>
                <div style="margin-right: 10px"><i class="el-icon-d-arrow-right"></i></div>
                <el-breadcrumb-item>支付数字化解决方案</el-breadcrumb-item>
                </el-breadcrumb>
           </div>
       </div> -->
<!-- 3  支付数字化内容 -->
       <div class="solvecontent">
            <!-- 分三块区域 -->
            <!-- 第一块按钮选择区域 -->
            <!-- 第二块内容区域会根据第一块切换内容 -->
            <!-- 第三块不变的区域 -->
            <!-- 第一块  按钮选择  区域 -->
            <div class="solvecontent_tip">
                <!-- 两个按钮 -->
                <!-- 第一个按钮 -->
                <div class="solvecontent_tip_box" @click="solveicon('id01')">
                    <div>
                        <img v-show="this.stateL == 1" src="../assets/solveiconchoose1black.png" style="width:3.9375rem;height:3.3125rem">
                        <img v-show="this.stateL == 0"  src="../assets/solveiconchoose1gray.png" style="width:3.9375rem;height:3.3125rem">
                    </div>
                    <div :class="{ txt : this.stateL == 0}">硬件系统</div>
                </div>
                <!-- 第二个按钮 -->
                <div class="solvecontent_tip_box"  @click="solveicon('id02')">
                    <div >
                        <img v-show="this.stateR == 1" src="../assets/solveiconchoose2black.png" style="width:3.1875rem;height:3.5625rem">
                        <img v-show="this.stateR == 0" src="../assets/solveiconchoose2gray.png" style="width:3.1875rem;height:3.5625rem">
                    </div>
                    <div :class="{ txt : this.stateR == 0}">软件系统</div>
                </div>
            </div>
            <!-- 第二块  内容区域  会根据第一块切换内容 -->
              <!-- 1硬件区域 -->
            <div class="solvecontent_boxmax" v-show="this.stateL == 1">
                <!-- 三个区块 -->
                <!-- 第一个区块s60购物车里面我给了5个盒子 -->
                <div class="solvecontent_boxmax_one">
                    <!-- 小标题 -->
                    <!-- 第一个盒子 -->
                    <div class="solvecontent_boxmax_one_tip">
                        <!-- 这个用于占位居中 -->
                        <div class="a"></div>
                        <!-- 这个是标题主体部分 -->
                        <div class="b">
                            <div>S60购物车</div>
                            <!-- 底部横线 -->
                            <div></div>
                        </div>
                        <!-- 这个是标题后缀 -->
                        <div class="c">(购物车容量60升)</div>
                    </div>
                    <!-- 小标题内容框子里面有4个 -->
                    <!-- 111111 -->
                    <div class="solvecontent_boxmax_one_content">
                        <!-- 左侧图片 -->
                        <div class="left">
                            <div style="width: 29.375rem; height: 32.75rem;">
                              <img src="../assets/s6001.png" style="width:100%;height:100%">
                            </div>
                        </div>
                        <!-- 右侧文字 -->
                        <div class="right">
                            <div style="margin-top:5rem">
                                <!-- 文字标题 -->
                            <div class="tip">反欺诈/识别</div>
                            <!-- 文字列表 -->
                            <ul>
                                <li>
                                    <div></div>
                                    <div>0.1秒识别购买行为，有效识别率达99.5% 以上</div>
                                </li>
                                <li>
                                    <div></div>
                                    <div>机器视觉+多传感器融合识别算法</div>
                                </li>
                            </ul>
                            </div>
                        </div>
                    </div>
                    <!-- 2222222222 -->
                    <div class="solvecontent_boxmax_one_content">
                        <!-- 左侧图片 -->
                        <div class="left">
                            <div style="width: 29.3125rem; height: 33.875rem;">
                              <img src="../assets/s6002.png" style="width:100%;">
                            </div>
                        </div>
                        <!-- 右侧文字 -->
                        <div class="right">
                            <div style="margin-top:5rem">
                                <!-- 文字标题 -->
                            <div class="tip">传感器融合</div>
                            <!-- 文字列表 -->
                            <ul>
                                <li>
                                    <div></div>
                                    <div>单一技术维度无法精准识别欺诈行为</div>
                                </li>
                                <li>
                                    <div></div>
                                    <div>搭载摄像头、陀螺仪、重力感应等多款传感器</div>
                                </li>
                            </ul>
                            </div>
                        </div>
                    </div>
                    <!-- 33333333333333333 -->
                    <div class="solvecontent_boxmax_one_content">
                        <!-- 左侧图片 -->
                        <div class="left" style="flex-direction: column;justify-content: flex-end;">
                            <div style="width: 22.5rem; height: 30.8125rem;">
                              <img src="../assets/s6003.png" style="width:100%;">
                            </div>
                        </div>
                        <!-- 右侧文字 -->
                        <div class="right">
                            <div style="margin-top:5rem">
                                <!-- 文字标题 -->
                            <div class="tip">深度学习</div>
                            <!-- 文字列表 -->
                            <ul>
                                <li>
                                    <div></div>
                                    <div>随时采集商品多维度数据</div>
                                </li>
                                <li>
                                    <div></div>
                                    <div>强大的机器学习能力，深度学习，自动训练商品特征</div>
                                </li>
                            </ul>
                            </div>
                        </div>
                    </div>
                    <!-- 44444444444444444 -->
                    <div class="solvecontent_boxmax_one_content">
                        <!-- 左侧图片 -->
                        <div class="left" style="flex-direction: column;justify-content: flex-end;">
                            <div style="width: 23.5625rem; height: 33.875rem;">
                              <img src="../assets/s6004.png" style="width:100%;">
                            </div>
                        </div>
                        <!-- 右侧文字 -->
                        <div class="right">
                            <div style="margin-top:5rem">
                                <!-- 文字标题 -->
                            <div class="tip">知识产权</div>
                            <!-- 文字列表 -->
                            <ul>
                                <li>
                                    <div></div>
                                    <div>申报28项发明及实用新型专利</div>
                                </li>
                                <li>
                                    <div></div>
                                    <div>申报5项软件著作权</div>
                                </li>
                            </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 第二个区块s90购物车 -->
                <div class="solvecontent_boxmax_two">
                    <!-- 第二区块标题 -->
                    <div class="solvecontent_boxmax_one_tip">
                        <!-- 这个用于占位居中 -->
                        <div class="a"></div>
                        <!-- 这个是标题主体部分 -->
                        <div class="b">
                            <div>S90购物车</div>
                            <!-- 底部横线 -->
                            <div></div>
                        </div>
                        <!-- 这个是标题后缀 -->
                        <div class="c">(购物车容量90升)</div>
                    </div>
                    <!-- 先包裹一个大盒子 -->
                    <div class="solves90">
                        <!-- 左右俩盒子 -->
                        <div class="solves90_left">
                            <div class="left">
                                <!-- <el-image style="width:100%" :src="require('../assets/s9001.png')" fit="scale-down"></el-image> -->
                                <img src="../assets/s9001.png" style="width:100%">
                            </div>
                            <div class="explain_ab" style="top:4.0625rem;right:15rem" >
                                <div class="explain_fl">
                                    <div class="txt">10.1寸全高清防水防尘终端</div>
                                    <div class="line"></div>
                                    <div class="point"></div>
                                </div>
                            </div>
                            <div class="explain_ab" style="top:18.75rem;right:12.3rem">
                                <div class="explain_fl">
                                    <div class="txt">双框设计，最高承重：100KG</div>
                                    <div class="line" style="width:12rem"></div>
                                    <div class="point"></div>
                                </div>
                            </div>
                            <div class="explain_ab" style="top:24.6875rem;right:12.3rem">
                                <div class="explain_fl">
                                    <div class="txt">磁吸式充电，即插即充，无限续航</div>
                                    <div class="line" style="width:10rem"></div>
                                    <div class="point"></div>
                                </div>
                            </div>
                        </div>
                        <div class="solves90_right">
                            <div class="right">
                                <!-- <el-image style="width:100%" :src="require('../assets/s9002.png')" fit="scale-down"></el-image> -->
                                <img src="../assets/s9002.png" style="width:100%">
                            </div>
                            <div class="explain_ab" style="top:4.0625rem;left:14.0625rem">
                                <div class="explain_fl">
                                    <div class="point"></div>
                                    <div class="line" style="width:13rem"></div>
                                    <div class="txt">后置全高清/大广角双摄像模组</div>
                                </div>
                            </div>
                            <div class="explain_ab" style="top:18.25rem;left:22.5rem">
                                <div class="explain_fl">
                                    <div class="point"></div>
                                    <div class="line" style="width:12rem"></div>
                                    <div class="txt">购物车容量：90L</div>
                                </div>
                            </div>
                            <div class="explain_ab" style="top:23.5625rem;left:23.75rem">
                                <div class="explain_fl">
                                    <div class="point"></div>
                                    <div class="line" style="width:4rem"></div>
                                    <div class="txt">多车套插设计，节省放置空间</div>
                                </div>
                            </div>
                            <div class="explain_ab" style="top:37.5rem;left:29.5rem">
                                <div class="explain_fl">
                                    <div class="point"></div>
                                    <div class="line" style="width:2rem"></div>
                                    <div class="txt">3片式高性能万向电梯轮</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 第三个区块智能闸机&吐袋机 -->
                <div class="solvecontent_boxmax_three">
                    <div class="solvecontent_boxmax_one_tip">
                        <!-- 这个用于占位居中 -->
                        <div class="a"></div>
                        <!-- 这个是标题主体部分 -->
                        <div class="bb">
                            <div>智能闸机&吐袋机</div>
                            <!-- 底部横线 -->
                            <div></div>
                        </div>
                        <!-- 这个是标题后缀 -->
                        <div class="c"></div>
                    </div>
                    <!-- 先包裹一个大盒子 -->
                    <div class="solveintell">
                        <!-- 左右俩盒子 -->
                        <div class="solveintell_left">
                            <div class="left">
                                <!-- <el-image style="width:100%" :src="require('../assets/Gate.png')" fit="scale-down"></el-image> -->
                                <img src="../assets/Gate.png" style="width:100%">
                            </div>
                            <div class="explain_ab" style="top:8.9375rem;right:15.9375rem">
                                <div class="explain_fl">
                                    <div class="txt">高清摄像头</div>
                                    <div class="line" style="width:14.375rem"></div>
                                    <div class="point"></div>
                                </div>
                            </div>
                            <div class="explain_ab" style="top:13.125rem;right:15.9375rem">
                                <div class="explain_fl">
                                    <div class="txt">电子显示屏</div>
                                    <div class="line" style="width:14.375rem"></div>
                                    <div class="point"></div>
                                </div>
                            </div>
                            <div class="explain_ab" style="top:20.1875rem;right:15.625rem">
                                <div class="explain_fl">
                                    <div class="txt">超市购物卡置入处</div>
                                    <div class="line" style="width:13.75rem"></div>
                                    <div class="point"></div>
                                </div>
                            </div>
                        </div>
                        <div class="solveintell_right">
                            <div class="right">
                                <!-- <el-image style="width:100%" :src="require('../assets/Bag.png')" fit="scale-down"></el-image> -->
                                <img src="../assets/Bag.png" style="width:100%">
                            </div>
                            <div class="explain_ab" style="top:19.375rem;left:18.875rem">
                                <div class="explain_fl">
                                    <div class="point"></div>
                                    <div class="line" style="width:14.375rem"></div>
                                    <div class="txt">吐袋机出口处</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
              <!-- 2软件区域 -->
            <div class="solvecontent_boxmax2" v-show="this.stateR == 1">
                <!-- 两个区域 -->
                <!-- 第一个区域  卖场购物系统-->
                <div class="solvecontent_boxmax2_one">
                    <!-- 小标题 -->
                    <div class="solvecontent_boxmax_one_tip">
                        <!-- 这个用于占位居中 -->
                        <div class="a"></div>
                        <!-- 这个是标题主体部分 -->
                        <div class="bb">
                            <div>卖场购物系统</div>
                            <!-- 底部横线 -->
                            <div></div>
                        </div>
                        <!-- 这个是标题后缀 -->
                        <div class="c"></div>
                    </div>
                    <!-- 内容区域 -->
                    <div class="solvecontent_boxmax2_one_content">
                        <!-- 两个盒子 -->
                        <!-- 第一个主要盒子内容 有标题 和图片包含定位 -->
                        <div class="box">
                            <!-- 小盒子model -->
                            <!-- 1 -->
                            <div class="modelA">
                                <!-- 六个主要盒子 -->
                                <!-- 每个盒子又分左右两个盒子 -->
                                <!-- 左边盒子复杂一点包含上下两个盒子 -->
                                <!-- 而上面盒子有用到了绝对定位 -->
                                <!-- 左边盒子 -->
                                <div class="left">
                                    <!-- 上面标题盒子 -->
                                    <div class="left_title">
                                        <!-- 用于绝对定位的背景盒子 -->
                                        <div class="yellowbackground"></div>
                                        <!-- 数字盒子 -->
                                        <div class="num">
                                            <img src="../assets/paynum001.png" alt="">
                                        </div>
                                        <!-- 标题盒子 -->
                                        <div class="tip">
                                            <!-- 中文标题 -->
                                            <div class="Chinese">刷脸登录</div>
                                            <!-- 英文标题 -->
                                            <div class="English">Brush face login</div>
                                        </div>
                                    </div>
                                    <!-- 图片盒子 -->
                                    <div class="left_pic">
                                        <img src="../assets/step01.png">
                                    </div>
                                </div>
                                <!-- 右边盒子是个过渡按钮亮光 左边两列的四个盒子都有 -->
                                <div class="right">
                                    <img src="../assets/rightpoint.png" alt="">
                                </div>
                            </div>
                            <!-- 2 -->
                            <div class="modelA">
                                <!-- 六个主要盒子 -->
                                <!-- 每个盒子又分左右两个盒子 -->
                                <!-- 左边盒子复杂一点包含上下两个盒子 -->
                                <!-- 而上面盒子有用到了绝对定位 -->
                                <!-- 左边盒子 -->
                                <div class="left">
                                    <!-- 上面标题盒子 -->
                                    <div class="left_title">
                                        <!-- 用于绝对定位的背景盒子 -->
                                        <div class="yellowbackground"></div>
                                        <!-- 数字盒子 -->
                                        <div class="num">
                                            <img src="../assets/paynum002.png" alt="">
                                        </div>
                                        <!-- 标题盒子 -->
                                        <div class="tip">
                                            <!-- 中文标题 -->
                                            <div class="Chinese">登录后领优惠券</div>
                                            <!-- 英文标题 -->
                                            <div class="English">Receive coupons after login</div>
                                        </div>
                                    </div>
                                    <!-- 图片盒子 -->
                                    <div class="left_pic02">
                                        <img src="../assets/step02.png">
                                    </div>
                                </div>
                                <!-- 右边盒子是个过渡按钮亮光 左边两列的四个盒子都有 -->
                                <div class="right">
                                    <img src="../assets/rightpoint.png" alt="">
                                </div>
                            </div>
                            <!-- 3 -->
                            <div class="modelA">
                                <!-- 六个主要盒子 -->
                                <!-- 每个盒子又分左右两个盒子 -->
                                <!-- 左边盒子复杂一点包含上下两个盒子 -->
                                <!-- 而上面盒子有用到了绝对定位 -->
                                <!-- 左边盒子 -->
                                <div class="left">
                                    <!-- 上面标题盒子 -->
                                    <div class="left_title">
                                        <!-- 用于绝对定位的背景盒子 -->
                                        <div class="yellowbackground"></div>
                                        <!-- 数字盒子 -->
                                        <div class="num">
                                            <img src="../assets/paynum003.png" alt="">
                                        </div>
                                        <!-- 标题盒子 -->
                                        <div class="tip">
                                            <!-- 中文标题 -->
                                            <div class="Chinese">扫条形码加购物车</div>
                                            <!-- 英文标题 -->
                                            <div class="English">Scan barcode and add shopping cart</div>
                                        </div>
                                    </div>
                                    <!-- 图片盒子 -->
                                    <div class="left_pic03">
                                        <img src="../assets/step03.png">
                                    </div>
                                </div>
                                <!-- 右边盒子是个过渡按钮亮光 左边两列的四个盒子都有 -->
                                <!-- <div class="right"></div> -->
                            </div>
                            <!-- 4 -->
                            <div class="modelA">
                                <!-- 六个主要盒子 -->
                                <!-- 每个盒子又分左右两个盒子 -->
                                <!-- 左边盒子复杂一点包含上下两个盒子 -->
                                <!-- 而上面盒子有用到了绝对定位 -->
                                <!-- 左边盒子 -->
                                <div class="left">
                                    <!-- 上面标题盒子 -->
                                    <div class="left_title">
                                        <!-- 用于绝对定位的背景盒子 -->
                                        <div class="yellowbackground"></div>
                                        <!-- 数字盒子 -->
                                        <div class="num">
                                            <img src="../assets/paynum004.png" alt="">
                                        </div>
                                        <!-- 标题盒子 -->
                                        <div class="tip">
                                            <!-- 中文标题 -->
                                            <div class="Chinese">选择可用优惠券</div>
                                            <!-- 英文标题 -->
                                            <div class="English">Select available coupons</div>
                                        </div>
                                    </div>
                                    <!-- 图片盒子 -->
                                    <div class="left_pic04">
                                        <img src="../assets/step04.png">
                                    </div>
                                </div>
                                <!-- 右边盒子是个过渡按钮亮光 左边两列的四个盒子都有 -->
                                <div class="right">
                                    <img src="../assets/rightpoint.png" alt="">
                                </div>
                            </div>
                            <!-- 5 -->
                            <div class="modelA">
                                <!-- 六个主要盒子 -->
                                <!-- 每个盒子又分左右两个盒子 -->
                                <!-- 左边盒子复杂一点包含上下两个盒子 -->
                                <!-- 而上面盒子有用到了绝对定位 -->
                                <!-- 左边盒子 -->
                                <div class="left">
                                    <!-- 上面标题盒子 -->
                                    <div class="left_title">
                                        <!-- 用于绝对定位的背景盒子 -->
                                        <div class="yellowbackground"></div>
                                        <!-- 数字盒子 -->
                                        <div class="num">
                                            <img src="../assets/paynum005.png" alt="">
                                        </div>
                                        <!-- 标题盒子 -->
                                        <div class="tip">
                                            <!-- 中文标题 -->
                                            <div class="Chinese">刷脸支付</div>
                                            <!-- 英文标题 -->
                                            <div class="English">Brush face payment</div>
                                        </div>
                                    </div>
                                    <!-- 图片盒子 -->
                                    <div class="left_pic05">
                                        <img src="../assets/step05.png">
                                    </div>
                                </div>
                                <!-- 右边盒子是个过渡按钮亮光 左边两列的四个盒子都有 -->
                                <div class="right">
                                    <img src="../assets/rightpoint.png" alt="">
                                </div>
                            </div>
                            <!-- 6 -->
                            <div class="modelA">
                                <!-- 六个主要盒子 -->
                                <!-- 每个盒子又分左右两个盒子 -->
                                <!-- 左边盒子复杂一点包含上下两个盒子 -->
                                <!-- 而上面盒子有用到了绝对定位 -->
                                <!-- 左边盒子 -->
                                <div class="left">
                                    <!-- 上面标题盒子 -->
                                    <div class="left_title">
                                        <!-- 用于绝对定位的背景盒子 -->
                                        <div class="yellowbackground"></div>
                                        <!-- 数字盒子 -->
                                        <div class="num">
                                            <img src="../assets/paynum006.png" alt="">
                                        </div>
                                        <!-- 标题盒子 -->
                                        <div class="tip">
                                            <!-- 中文标题 -->
                                            <div class="Chinese">走专属通道，无需排队</div>
                                            <!-- 英文标题 -->
                                            <div class="English">Take the exclusive channel</div>
                                        </div>
                                    </div>
                                    <!-- 图片盒子 -->
                                    <div class="left_pic06">
                                        <img src="../assets/step06.png">
                                    </div>
                                </div>
                                <!-- 右边盒子是个过渡按钮亮光 左边两列的四个盒子都有 -->
                                <div class="right"></div>
                            </div>
                        </div>
                        <!-- 第二个盒子 尾部红色字体标识 -->
                        <!-- <div class="identification">
                            *也可对接商家用户系统，消费者选择登录商家用户系统
                        </div> -->
                    </div>
                </div>
                <!-- 第二块区域  亿猫超量精准数字营销引擎 -->
                <div class="solvecontent_boxmax2_two">
                    <!-- 小标题 -->
                    <div class="solvecontent_boxmax_one_tip">
                        <!-- 这个用于占位居中 -->
                        <!-- <div class="a"></div> -->
                        <!-- 这个是标题主体部分 -->
                        <div class="bbb">
                            <div>鹰隼AI反欺诈引擎</div>
                            <!-- 底部横线 -->
                            <div></div>
                        </div>
                        <!-- 这个是标题后缀 -->
                        <!-- <div class="c"></div> -->
                    </div>
                    <!-- 内容 -->
                    <div class="solvecontent_boxmax2_two_content">
                        <!-- 四个盒子 -->
                        <div class="box">
                            <!-- 里面又分三个盒子 -->
                            <div class="w">
                                <div class="picbox001">
                                    <img src="../assets/AI01.png" >
                                </div>
                            </div>
                            <div class="txt">利用重力、图像</div>
                            <div class="txt_box">
                                    <div class="q">双重识别</div>
                                    <div class="q" >0.001g精度</div>
                                    <div class="q">防损防盗</div>
                                    <div class="q">双重保障</div>
                            </div>
                        </div>
                        <!-- 2 -->
                        <div class="box">
                            <!-- 里面又分三个盒子 -->
                            <div class="w">
                                <div class="picbox002">
                                    <img src="../assets/AI02.png" >
                                </div>
                            </div>
                            <div class="txt">高精度传感器</div>
                            <div class="txt_box">
                                    <div class="q">高精尖设备</div>
                                    <div class="q">全方位判断</div>
                                    <div class="q">亮绿灯放行</div>
                                    <div class="q">亮红灯核验</div>
                            </div>
                        </div>
                        <!-- 3 -->
                        <div class="box">
                            <!-- 里面又分三个盒子 -->
                            <div class="w">
                                <div class="picbox003">
                                    <img src="../assets/AI03.png" >
                                </div>
                            </div>
                            <div class="txt">人工算法</div>
                            <div class="txt_box">
                                    <div class="q">特征参数</div>
                                    <div class="q">模型算法</div>
                                    <div class="q">持续优化</div>
                                    <div class="q">降低误差</div>
                            </div>
                        </div>
                        <!-- 4 -->
                        <div class="box">
                            <!-- 里面又分三个盒子 -->
                            <div class="w">
                                <div class="picbox004">
                                    <img src="../assets/AI04.png" >
                                </div>
                            </div>
                            <div class="txt">深度学习</div>
                            <div class="txt_box">
                                    <div class="q">计算机视觉</div>
                                    <div class="q">样本数据</div>
                                    <div class="q">记忆型网络</div>
                                    <div class="q">生产模型</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 第三块不变的区域 -->
            <div class="solvevalue" ref="noChangeRef">
                <!-- 标题 -->
                <div class="solvevalue_tip">方案价值</div>
                <!-- 内容 -->
                <div class="solvevalue_content">
                    <!-- 三个小盒子 -->
                    <!-- 第一个 -->
                    <div class="solvevalue_contentbox">
                        <div class="B"></div>
                        <div :class="{pic:true,picAfter:tipShowChange}">
                            <img src="../assets/payvalue01.png" >
                        </div>
                        <div class="txt_absolute">
                               <div :class="{txt_flex:true,txtAfter:tipShowChange}">
                                   <div>节省商家人力成本</div>
                                   <div>取代人工收银台，直接降低卖场的运营成本</div>
                               </div>
                        </div>
                    </div>
                    <!-- 第二个 -->
                        <div class="solvevalue_contentbox">
                        <div class="B"></div>
                        <div :class="{pic02:true,picAfter:tipShowChange}">
                            <img src="../assets/payvalue02.png" >
                        </div>
                        <div class="txt_absolute">
                               <div :class="{txt_flex:true,txtAfter:tipShowChange}">
                                   <div>增加商家销售额</div>
                                   <div>千人千面的精准营销，带来新型流量转化模式</div>
                               </div>
                        </div>
                    </div>
                    <!-- 第三个 -->
                        <div class="solvevalue_contentbox">
                        <div class="B"></div>
                        <div :class="{pic03:true,picAfter:tipShowChange}">
                            <img src="../assets/payvalue03.png" >
                        </div>
                        <div class="txt_absolute">
                               <div :class="{txt_flex:true,txtAfter:tipShowChange}">
                                   <div>提供新颖的购物体验</div>
                                   <div>一键直达导购，边选边扫条形码，自助式结算，全程沉浸式购物体验</div>
                               </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
<!-- 4  脚步 -->
    <footers></footers>
    </div>
</template>
<script>
import footers from './footer'
export default {
  data () {
    return {
      hardSoft: 'id01',
      stateL: 1,
      stateR: 0,
      tipShowChange: false,
    }
  },
  components: {
    footers
  },
  mounted () {
    window.addEventListener("scroll", this.showIcon)
  },
  beforeDestroy () {
    window.removeEventListener("scroll", this.showIcon);
  },
  methods: {
    showIcon () {
      this.currentScroll = window.pageYOffset; // 表示当前滚动的位置
      // console.log(this.currentScroll);
      // console.log(this.$refs.noChangeRef.offsetTop);
      if (this.currentScroll >= this.$refs.noChangeRef.offsetTop - 550) { // 当前滚动位置到达testref的时候，显示div（100作为调整用）
        this.tipShowChange = true;
      }
    },
    solveicon (key) {
      this.hardSoft = key;
      if (this.hardSoft === 'id01') {
        this.stateL = 1;
        this.stateR = 0;
      } else {
        this.stateL = 0;
        this.stateR = 1;
      }
    }
  }
}
</script>
<style lang="less" scoped>
.paysolvebox{
    width: 100%;
    background: #fff;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    margin: 0;
}
.banner{
    width: 100%;
    // height: 48.125rem;
    // height: calc(100vh - 20vh);
    // height: calc(880 / 1080 * 100vh);
    display: block;
    margin: 0;
    padding: 0;
    // background-image: url(../assets/paysolvebanner.png);
    // background-size: 100%;
    // // background-size: cover;
    // background-repeat: no-repeat;
    // margin-bottom: 3.125rem;
    margin-bottom: 3.125rem;
}
.banner img{
    width: 100%;
    // height: 100%;
}
.breadbox{
    width: 100%;
    height:3.9375rem;
    background: #E5E5E5;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 5rem;
}
.breadtxt{
    font-size: 1.125rem;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #2B2B2B;
    margin-left: 1.25rem;
    margin-right: 0.625rem;
}
.el-breadcrumb {
    font-size: 1.125rem;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #2B2B2B;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.el-breadcrumb :first-child :hover {
    color: #FF6700 !important;
}
.el-breadcrumb :nth-child(3) :hover {
    color: #FF6700 !important;
}
.solvecontent {
    width: 100%;
    // height: 8000px;
    // background: chartreuse;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.solvecontent_tip{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 2.25rem;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #242424;
    line-height: 1.625rem;
    height: 9.375rem;
    background: #fff;
    width: 100%;
    margin-bottom: 2.5rem;
}
.solvecontent_tip_box{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    cursor: pointer;
    margin: 0 3.75rem 0 3.75rem;

}
.solvecontent_tip_box  :first-child{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4rem;
    height: 4rem;
}
.solvecontent_tip_box .txt{
    color:#BABABA
}
.solvecontent_boxmax ,.solvecontent_boxmax_one{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}
.solvecontent_boxmax_one_tip{
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 12.5rem;
    background: #fff;
}
//a b c bb 适用于标题居中 结合父盒子justify-content: center使用
.a, .c{
    width: 18.75rem;
    height: 4.375rem;
    display: flex;
    justify-content: flex-start;
    line-height: 3.75rem;
    font-size: 1.5rem;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #000000;
    opacity: 0.8;
}
.b{
    width: 11.25rem;
    font-size: 2.25rem;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #000000;
    height: 4.375rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.b :nth-child(2){
    width: 3.1875rem;
    height: 0.4375rem;
    background: #FF6700;
}
.bb{
    width: 25rem;
    font-size: 2.25rem;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #000000;
    height: 4.375rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.bb :nth-child(2){
    width: 3.1875rem;
    height: 0.4375rem;
    background: #FF6700;
}
//bbb
.bbb{
    width: 31.25rem;
    font-size: 2.25rem;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #000000;
    height: 4.375rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.bbb :nth-child(2){
    width: 3.1875rem;
    height: 0.4375rem;
    background: #FF6700;
}
.solvecontent_boxmax_one_content{
    width: 72.625rem;
    height: 35.25rem;
    background: #f5f5f5;
    //opacity: 0.45;
    border-radius: 3.125rem;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 6.25rem;
}
.solvecontent_boxmax_one_content .left{
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.solvecontent_boxmax_one_content .right{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.solvecontent_boxmax_one_content .right .tip{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 2.25rem;
    font-family: Source Han Sans CN;
    font-weight: 500;
    width: 80%;
    color: #020202;
    margin-left: 1.25rem;
    margin-bottom: 3.125rem;
}
.solvecontent_boxmax_one_content .right ul{
    width: 80%;
    list-style-type: none;
    padding: 0;
}
.solvecontent_boxmax_one_content .right ul li{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 1.3125rem;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #030303;
    line-height: 2.3125rem;
    opacity: 0.85;
    text-align:left;
    position: relative;
    padding-left: 1.25rem;
     margin-bottom: 1.25rem;
     width:27.375rem;
}
.solvecontent_boxmax_one_content .right ul li :first-child{
    width: 0.3125rem;
    height: 0.8125rem;
    background: #FF6700;
    position: absolute;
    left: 0;
    top:0.75rem
}
.solvecontent_boxmax_two{
    width: 100%;
}
.solves90{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 43.75rem;
    // background: chocolate;
    margin-bottom: 5rem;
}
.solves90_left{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    // background:chartreuse;
    position: relative;

    .explain_ab{
        position: absolute;
         z-index:100;
        //  width:100%;
        //  background:red;
    }

    .explain_fl{
        display:flex;
        flex-direction: row;
        align-items: center;
        width:100%;
        // background:#fff;

        .txt{
            font-size: 1.25rem;
            // font-size: 20px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #000000;
        }

        .line{
            background: linear-gradient(135deg, rgba(255, 241, 84, 0) ,#ff6801 );
            width: 10.5rem;
            height: 0.125rem;
        }

        .point{
            width: 0.5rem;
            height: 0.5rem;
            border-radius: 50%;
            background: #FF6700;
            box-shadow: 0px 0px 0.0625rem 0.0625rem #fff,0px 0px 0.25rem 0.25rem #FF6700;
            animation: myAnimation 1s infinite;
            -webkit-animation: myAnimation 1s infinite;
        }
        @keyframes myAnimation {
            0% {
                opacity: 0.1;
            }
            30% {
                opacity: 0.2;
            }
            50% {
                opacity: 0.5;
            }
            70% {
                opacity: 0.7;
            }
            100% {
                opacity: 0.8
            }
            }
            @-webkit-keyframes myAnimation {
            0% {
                opacity: 0.1;
            }
            30% {
                opacity: 0.2;
            }
            50% {
                opacity: 0.5;
            }
            70% {
                opacity: 0.7;
            }
            100% {
                opacity: 0.8
            }
            }
    }
}
.solves90_left .left{
    width: 22rem;
    // height: 39.375rem;
    margin-right: 1.375rem;
    // background: cornflowerblue;
    z-index: 100;
}
.solves90_left .left img{
    width: 100%;
    // height: 100%;
}
.solves90_right{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;

    .explain_ab{
        position: absolute;
         z-index:100;
        //  width:100%;
        //  background:red;
    }

    .explain_fl{
        display:flex;
        flex-direction: row;
        align-items: center;
        width:100%;
        // background:#fff;

        .txt{
            font-size: 1.25rem;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #000000;
        }

        .line{
            background: linear-gradient(135deg, #ff6801,rgba(255, 241, 84, 0)  );
            width: 8.6875rem;
            height: 2px;
        }

        .point{
            width: 0.5rem;
            height: 0.5rem;
            border-radius: 50%;
            background: #FF6700;
            box-shadow: 0px 0px 0.0625rem 0.0625rem #fff,0px 0px 0.25rem 0.25rem #FF6700;
            animation: myAnimation 1s infinite;
            -webkit-animation: myAnimation 1s infinite;
        }
        @keyframes myAnimation {
            0% {
                opacity: 0.1;
            }
            30% {
                opacity: 0.2;
            }
            50% {
                opacity: 0.5;
            }
            70% {
                opacity: 0.7;
            }
            100% {
                opacity: 0.8
            }
            }
            @-webkit-keyframes myAnimation {
            0% {
                opacity: 0.1;
            }
            30% {
                opacity: 0.2;
            }
            50% {
                opacity: 0.5;
            }
            70% {
                opacity: 0.7;
            }
            100% {
                opacity: 0.8
            }
            }
    }
}
.solves90_right .right{
    width: 30.6875rem;
    // height: 39.9375rem;
    margin-left: 1.375rem;
    z-index: 100;

}
.solves90_right .right img{
    width: 100%;
    // height: 100%;
}
// 一样
.solvecontent_boxmax_three{
    width: 100%;
}
.solveintell{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 50rem;
    // background: chocolate;
}
.solveintell_left{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    position: relative;

    .explain_ab{
        position: absolute;
         z-index:100;
        //  width:100%;
        //  background:red;
    }

    .explain_fl{
        display:flex;
        flex-direction: row;
        align-items: center;
        width:100%;
        // background:#fff;

        .txt{
            font-size: 1.25rem;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #000000;
        }

        .line{
            background: linear-gradient(135deg, rgba(255, 241, 84, 0),#ff6801  );
            width: 10.5rem;
            height: 0.125rem;
        }

        .point{
            width: 0.5rem;
            height: 0.5rem;
            border-radius: 50%;
            background: #FF6700;
            box-shadow: 0px 0px 0.0625rem 0.0625rem #fff,0px 0px 0.25rem 0.25rem #FF6700;
            animation: myAnimation 1s infinite;
            -webkit-animation: myAnimation 1s infinite;
        }
        @keyframes myAnimation {
            0% {
                opacity: 0.1;
            }
            30% {
                opacity: 0.2;
            }
            50% {
                opacity: 0.5;
            }
            70% {
                opacity: 0.7;
            }
            100% {
                opacity: 0.8
            }
            }
            @-webkit-keyframes myAnimation {
            0% {
                opacity: 0.1;
            }
            30% {
                opacity: 0.2;
            }
            50% {
                opacity: 0.5;
            }
            70% {
                opacity: 0.7;
            }
            100% {
                opacity: 0.8
            }
            }
    }
}
.solveintell_left .left{
    width: 17.4375rem;
    // height: 38.6875rem;
    margin-right: 2.25rem;
}
.solveintell_left .left img{
    width: 100%;
    // height: 100%;
}
.solveintell_right{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;

    .explain_ab{
        position: absolute;
         z-index:10;
        //  width:100%;
        //  background:red;
    }

    .explain_fl{
        display:flex;
        flex-direction: row;
        align-items: center;
        width:100%;
        // background:#fff;

        .txt{
            font-size: 1.25rem;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #000000;
        }

        .line{
            background: linear-gradient(135deg, #ff6801,rgba(255, 241, 84, 0)  );
            width: 10.5rem;
            height: 0.125rem;
        }

        .point{
            width: 0.5rem;
            height: 0.5rem;
            border-radius: 50%;
            background: #FF6700;
            box-shadow: 0px 0px 0.0625rem 0.0625rem #fff,0px 0px 0.25rem 0.25rem #FF6700;
            animation: myAnimation 1s infinite;
            -webkit-animation: myAnimation 1s infinite;
        }
        @keyframes myAnimation {
            0% {
                opacity: 0.1;
            }
            30% {
                opacity: 0.2;
            }
            50% {
                opacity: 0.5;
            }
            70% {
                opacity: 0.7;
            }
            100% {
                opacity: 0.8
            }
            }
            @-webkit-keyframes myAnimation {
            0% {
                opacity: 0.1;
            }
            30% {
                opacity: 0.2;
            }
            50% {
                opacity: 0.5;
            }
            70% {
                opacity: 0.7;
            }
            100% {
                opacity: 0.8
            }
            }
    }
}
.solveintell_right .right{
    width: 24.125rem;
    // height: 39.75rem;
    margin-left: 2.25rem;
    margin-top: 3.125rem;
}
.solveintell_right .right img{
    width: 100%;
    // height: 100%;
}
// 不变区域
.solvevalue{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    // height: 50rem;
    background: #fff;
}
.solvevalue_tip{
    width: 100%;
    height: 12.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.5rem;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #000000;
    line-height: 1.625rem;
    // background:chocolate;
}
.solvevalue_content{
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    align-items: center;
    width: 79%;
    margin: 1.25rem auto 12.5rem auto;
}
.solvevalue_contentbox{
    width:30%;
    height: 30.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.solvevalue_contentbox .B{
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.6) ;
    position:absolute;
    left: 0;
    top: 0;
    z-index: 10;
    }
    .solvevalue_contentbox .pic{
    width: 14.5rem;
    height: 15.625rem;
    overflow: hidden;
    transform: translateY(18.75rem);  //滚动前的位置
    opacity: 0;   //滚动前设置透明隐藏
    // z-index: 10;
    }
    .solvevalue_contentbox .picAfter{
    transform: translateY(0%)!important;  //滚动后的位置
    opacity: 1!important;  //滚动后显现
    transition: all .6s ease-out;
}
    .solvevalue_contentbox .pic img{
        width: 100%;
        // height: 100%;
    }
    .solvevalue_contentbox .pic02{
    width: 20.0625rem;
    height: 14.6875rem;
    overflow: hidden;
    transform: translateY(18.75rem);  //滚动前的位置
    opacity: 0;   //滚动前设置透明隐藏
    // z-index: 10;
    }
    .solvevalue_contentbox .pic02 img{
        width: 100%;
        // height: 100%;
    }
    .solvevalue_contentbox .pic03{
    width: 18.5625rem;
    height: 17.4375rem;
    overflow: hidden;
    transform: translateY(18.75rem);  //滚动前的位置
    opacity: 0;   //滚动前设置透明隐藏
    // z-index: 10;
    }
    .solvevalue_contentbox .pic03 img{
        width: 100%;
        // height: 100%;
    }
    .txt_absolute{
        position: absolute;
        z-index: 10;
        color: #fff;
        width: 100%;
        height: 100%;
        top:0;
        left:0;

        .txtAfter{
            transform: translateY(0%)!important;  //滚动后的位置
            opacity: 1!important;  //滚动后显现
            transition: all .8s ease-out;
        }
    }
    .txt_flex{
        width: 82%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        transform: translateY(-18.75rem);  //滚动前的位置
        opacity: 0;
    }
    .txt_flex :first-child{
        // height: 30px;
        height:50%;
        // background: #3A67CB;
        font-size: 2rem;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        align-items: center;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #FEFEFE;
        line-height: 2.3125rem;
        // margin-bottom: 30px;
    }
    .txt_flex :nth-child(2){
        font-size: 1.125rem;
        // background: #3A67CB;
        font-family: Source Han Sans CN;
        font-weight: 400;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 50%;
        color: #FFFFFF;
        line-height: 1.75rem;
        margin-top: 1.875rem;
    }
    // 可变第二区域
    .solvecontent_boxmax2{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
    .solvecontent_boxmax2_one ,.solvecontent_boxmax2_two{
        width: 100%;
    }
    .solvecontent_boxmax2_one_content{
        width: 85%;
        // height: 1000px;
        // background: #815e5e;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin: 0 auto;
    }
    // 支付解决方案 软件 第一区域
    .solvecontent_boxmax2_one_content .box{
        width:100%;
        display: flex;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        justify-content: space-around;
    }
    .solvecontent_boxmax2_one_content .identification{
        width: 100%;
        font-size: 1.125rem;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #FF0101;
        line-height: 1.625rem;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-right: 16.25rem;
        margin-top: -3.125rem;
            }
    .solvecontent_boxmax2_one_content .modelA{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width:30% ;
        // background: chocolate;
    }
    .solvecontent_boxmax2_one_content .left {
        // background: chartreuse;
        width: 25rem;
        height: 28.75rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        position: relative;
    }
    .solvecontent_boxmax2_one_content .right{
        // background: chocolate;
        height: 6.25rem;
        width: 6.25rem;

        img{
            position:relative;
            animation: move 1s infinite;
        }
        @keyframes move {
            from {left:0px}
            to {left:0.625rem}

        }
    }
    .solvecontent_boxmax2_one_content .left .left_title{
        width: 100%;
        height: 8.125rem;
        background: #fff;
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        align-items: center;
        position: relative;
        margin-bottom: 0.625rem;
    }
    .yellowbackground{
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 3.6875rem;
        width: 4.3125rem;
        height: 4.3125rem;
        background: #FF6700;
        opacity: 0.82;
    }
    .solvecontent_boxmax2_one_content .left .left_title .num{
        font-size: 6.1875rem;
        font-family: SourceHanSansCN-Bold;
        font-weight: bold;
        color: #3A67CB;
        // line-height: 129px;
        margin-left: 4.375rem;
        margin-top: -4.375rem;
        width: 2.8125rem;
        height: 4.5rem;
        z-index: 99;

        img{
            width: 100%;
            // height: 100%;
        }
    }
    .solvecontent_boxmax2_one_content .left .left_title .tip{
        display: flex;
        flex-direction: column;
        margin-left: 0.3125rem;
        z-index: 99;
    }
    .solvecontent_boxmax2_one_content .left .left_title .tip .Chinese{
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        align-items: center;
        font-size: 1.375rem;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #3F6BCB;
        line-height: 2rem;
    }
    .solvecontent_boxmax2_one_content .left .left_title .tip .English{
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        align-items: center;
        font-size: 0.9375rem;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #C8C8C8;
        line-height: 1.4375rem;
    }
    .solvecontent_boxmax2_one_content .left_pic {
        width: 23.8125rem;
        height: 18.4375rem;
    }
    .solvecontent_boxmax2_one_content .left_pic img{
        width: 100%;
        // height: 100%;
    }
    // 02
    .solvecontent_boxmax2_one_content .left_pic02 {
        width: 19.6875rem;
        height: 16.5rem;
    }
    .solvecontent_boxmax2_one_content .left_pic02 img{
        width: 100%;
        // height: 100%;
    }
    // 04
    .solvecontent_boxmax2_one_content .left_pic04 {
        width: 19.5rem;
        height: 16.375rem;
    }
    .solvecontent_boxmax2_one_content .left_pic04 img{
        width: 100%;
        // height: 100%;
    }
    // 05
    .solvecontent_boxmax2_one_content .left_pic05 {
        width: 19.375rem;
        height: 16.25rem;
    }
    .solvecontent_boxmax2_one_content .left_pic05 img{
        width: 100%;
        // height: 100%;
    }
    // 03
    .solvecontent_boxmax2_one_content .left_pic03 {
        width: 26.8125rem;
        height: 20rem;
        margin-top: -1.25rem;
        z-index: 99;
        position: relative;
    }
    .solvecontent_boxmax2_one_content .left_pic03 img{
        width: 100%;
        // height: 100%;
    }
    // 06
    .solvecontent_boxmax2_one_content .left_pic06 {
        width: 8.75rem;
        height: 19.5625rem;
        margin-top: -1.875rem;
        z-index: 99;
        position: relative;
    }
    .solvecontent_boxmax2_one_content .left_pic06 img{
        width: 100%;
        // height: 100%;
    }
    // 支付解决方案 软件 第二区域
    .solvecontent_boxmax2_two_content{
        width: 79%;
        margin: 2.5rem auto 0 auto;
        height: 30.625rem;
        // background: #815e5e;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-around;
        align-items: center;
    }
    .solvecontent_boxmax2_two_content .box{
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: flex-start;
        width: 24%;
        height: 27.125rem;
        background: #f5f5f7;
        }
        .solvecontent_boxmax2_two_content .box .w{
            width: 11.9375rem;
            height: 11.375rem;
            margin-top: 2.1875rem;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .picbox001{
            width: 11.875rem;
            height: 9rem;
        }
        .picbox001 img{
            width: 100%;
            // height: 100%;
        }
        .picbox002{
            width: 11.9375rem;
            height: 7.25rem;
        }
        .picbox002 img{
            width: 100%;
            // height: 100%;
        }
        .picbox003{
            width: 7.75rem;
            height: 11.375rem;
        }
        .picbox003 img{
            width: 100%;
            // height: 100%;
        }
        .picbox004{
            width: 9.9375rem;
            height: 7.5rem;
        }
        .picbox004 img{
            width: 100%;
            // height: 100%;
        }
        .solvecontent_boxmax2_two_content .box .txt{
            font-size: 1.5rem;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #000000;
            line-height: 1.625rem;
            margin-top: 1.25rem;
        }
        .txt_box{
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            align-items: center;
            flex-wrap: wrap;
            // background: chartreuse;
            width: 70%;
            align-content: space-around;
            font-size: 1.125rem;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #535252;
            line-height: 1.625rem;
            margin-top: 1.875rem;
        }
         .q{
            width: 50%;
            line-height: 1.625rem;
            display: flex;
            justify-content: center;
            align-items: center;
        }
</style>
